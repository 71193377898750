import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function CloseIcon(props: Props) {
  return (
    <Svg
      width={props.width ?? 12}
      height={props.height ?? 12}
      viewBox="0 0 12 12"
      fill="none"
      {...props}>
      <Path
        d="M11.322.982L1.38 11.111M11.322 11.11L1.38.983"
        stroke={props.color ?? '#fff'}
        strokeWidth={1.8}
      />
    </Svg>
  )
}

export default CloseIcon
