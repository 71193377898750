import Constants from 'expo-constants'
import { Platform } from 'react-native'

export const getTwitterIntent = ({ url, message }: { url: string; message: string }) => {
  return `${encodeURI(`https://twitter.com/intent/tweet?text=${message}`)}&url=${encodeURIComponent(url)}`
}

export const getWarpcastIntent = ({ url, message }: { url: string; message: string }) => {
  return Platform.OS === 'web'
    ? `${encodeURI(`https://warpcast.com/~/compose?text=${message}`)}&embeds[]=${encodeURIComponent(url)}`
    : encodeURI(`https://warpcast.com/~/compose?text=${message}&embeds[]=${url}`)
}

export const createDeeplinkURL = (path: string) => {
  const host = process.env.EXPO_PUBLIC_HOST_URI
  if (host) {
    return `${host}${path}`
  }

  return Constants.platform?.scheme ? `${Constants.platform?.scheme}://${path}` : path
}
