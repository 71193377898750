import { skipToken } from '@tanstack/react-query'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { trpc } from 'app/utils/api'
import { chain } from 'app/utils/chain'
import { formatNumberForPrice } from 'app/utils/common'
import { webSocketClient } from 'app/utils/viem-public-client'
import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'
import { erc20Abi, formatUnits } from 'viem'
import { useIsItemVisible } from '../use-is-item-visible'
import { AnimatedTransactionItems, MAX_MESSAGES } from './animated-transaction-items'

export const TransactionAnimation = ({
  address,
}: {
  address: `0x${string}`
}) => {
  const visible = useIsItemVisible()
  const stats = trpc.tokenContract.getTokenStats.useQuery(
    address && visible
      ? [
          {
            address: address.toLowerCase(),
            networkId: chain.id,
          },
        ]
      : skipToken
  )
  const token = stats.data?.results?.[0]?.token
  const [transactions, setTransactions] = useState<
    Array<{
      key: string
      price: string
    }>
  >([])

  useEffect(() => {
    let dispose: () => void = () => {}
    if (visible && token?.address.toLowerCase() === address.toLowerCase()) {
      dispose = webSocketClient.watchContractEvent({
        abi: erc20Abi,
        address,
        eventName: 'Transfer',
        poll: false,
        onLogs: throttle(async (logs) => {
          let index = 0
          for (const log of logs) {
            // Do not risk iterating too long
            if (index > MAX_MESSAGES) {
              break
            }
            if (log) {
              setTransactions((prev) => {
                if (typeof log.args.value === 'bigint' && log.args.to) {
                  const amount = formatNumberForPrice(
                    Number(formatUnits(log.args.value, token.decimals))
                  )
                  const key = log.transactionHash + log.logIndex
                  const price = `${amount} ${token.symbol}`
                  if (
                    prev.findIndex((item) => item.key === key) === -1 &&
                    prev.findIndex((item) => item.price === price) === -1
                  ) {
                    const newItem = { key, price }
                    return [newItem, ...prev.slice(0, MAX_MESSAGES - 1)]
                  }
                }
                return prev
              })
            }

            index++
          }
        }, 1000),
      })
    }

    return () => {
      dispose()
    }
  }, [visible, address, token?.address, token?.decimals, token?.symbol])

  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()

  if (isWeb3ActionsDisabled) return null

  return <AnimatedTransactionItems data={transactions} style={{ alignItems: 'flex-start' }} />
}
