import { Path, Svg } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const UpvoteIcon = (props: Props) => (
  <Svg width={props.width ?? 60} height={props.height ?? 60} viewBox="0 0 24 24" fill={props.color}>
    <Path d="M12.781 2.375c-.381-.475-1.181-.475-1.562 0l-8 10A1.001 1.001 0 0 0 4 14h4v7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7h4a1.001 1.001 0 0 0 .781-1.625l-8-10zM15 12h-1v8h-4v-8H6.081L12 4.601 17.919 12H15z" />
  </Svg>
)
