import { Button, View, cn } from '@my/ui'
import { useMemo } from 'react'
import { ActivityIndicator } from 'react-native'

export const SubmitButton = (
  props: React.ComponentProps<typeof Button> & {
    isSubmitting?: boolean
    className?: string
    extraClassName?: string
  }
) => {
  const { isSubmitting, className, extraClassName } = props

  const buttonClass = useMemo(
    () => cn('py-2.5 rounded-full opacity-100 active:opacity-60', className, extraClassName),
    [className, extraClassName]
  )
  return (
    <View
      className="justify-center"
      style={{
        opacity: isSubmitting ? 0.65 : 1,
      }}>
      <Button {...props} className={buttonClass} disabled={isSubmitting} />
      {Boolean(isSubmitting) && (
        <View className="absolute right-3">
          <ActivityIndicator color="white" size="small" />
        </View>
      )}
    </View>
  )
}
