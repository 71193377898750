import { BaseIcon, cn, LeanText, LeanView, Pressable } from '@my/ui'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { trpc } from 'app/utils/api'
import { chain } from 'app/utils/chain'
import { formatNumberForPrice, formatShortNumber } from 'app/utils/common'
import { Image } from 'expo-image'
import { Link } from 'expo-router'
import { memo, useMemo } from 'react'
import { useEventCallback } from '../../hooks/use-event-callback'
import { useWalletAction } from '../coinbase-smart-wallet/use-wallet-action'
import { PercentageChange } from '../common/percentage-change'

export type TokenItemType = {
  priceUSD?: string | null
  marketCap?: string | null
  token?: {
    address: string
    name?: string | null
    symbol?: string | null
    info?: {
      imageLargeUrl?: string | null
      imageSmallUrl?: string | null
    } | null
  } | null
  change24?: string | null
} | null

const TokenItemUI = memo((item: TokenItemType) => {
  const priceUsd = useMemo(() => {
    return formatNumberForPrice(Number(item?.priceUSD))
  }, [item?.priceUSD])

  const marketCap = useMemo(() => {
    return formatShortNumber(Number(item?.marketCap))
  }, [item?.marketCap])

  return (
    <LeanView className="flex-row items-center justify-between">
      <LeanView className="flex-row gap-4 items-center">
        <LeanView className="self-start">
          {item?.token?.info?.imageLargeUrl ? (
            <LeanView className="w-[42px] h-[42] bg-neutral-800 rounded-full">
              <Image
                recyclingKey={item.token.info.imageLargeUrl}
                source={{
                  uri: item.token.info.imageLargeUrl,
                  width: 84,
                  height: 84,
                }}
                style={{
                  width: 42,
                  height: 42,
                  borderRadius: 100,
                }}
                transition={200}
              />
            </LeanView>
          ) : (
            <LeanView className="w-[44px] h-[44px] rounded-full bg-neutral-800" />
          )}
          <LeanView className="absolute bottom-[-4px] right-[-4px]">
            <BaseIcon width={16} height={17} />
          </LeanView>
        </LeanView>
        <LeanView className="gap-1">
          <LeanText className="text-white font-semibold">{item?.token?.symbol}</LeanText>
          <LeanText className="text-grayTextColor">${marketCap} MKT CAP</LeanText>
        </LeanView>
      </LeanView>
      <LeanView className="gap-1 overflow-hidden text-right items-end">
        <LeanText className="text-white">${priceUsd}</LeanText>
        <PercentageChange value={item?.change24 ? Number(item?.change24) * 100 : null} />
      </LeanView>
    </LeanView>
  )
})

TokenItemUI.displayName = 'TokenItemUI'

export const TokenItem = memo(
  ({
    item,
    onSelectToken,
    pressableClassName,
  }: {
    item: TokenItemType
    onSelectToken?: (token: {
      address: string
      name: string
      symbol: string
      image: string
    }) => void
    pressableClassName?: string
  }) => {
    const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()
    const trpcUtils = trpc.useUtils()

    const handleSelectTokenToPin = useWalletAction(() => {
      if (onSelectToken && item?.token?.address && !isWeb3ActionsDisabled) {
        onSelectToken({
          address: item?.token?.address,
          name: item?.token?.name ?? '',
          symbol: item?.token?.symbol ?? '',
          image: item?.token?.info?.imageSmallUrl ?? '',
        })
      }
    })

    const prefetchTokenPage = useEventCallback(async () => {
      if (item?.token?.address) {
        await trpcUtils.tokenPage.token.prefetch({
          address: item.token.address,
          networkId: chain.id,
        })
      }
    })

    const className = useMemo(
      () => cn('p-4 hover:bg-grayTextColor/20 active:bg-grayTextColor/20', pressableClassName),
      [pressableClassName]
    )

    if (onSelectToken) {
      return (
        <Pressable onPress={handleSelectTokenToPin} className={className}>
          <TokenItemUI {...item} />
        </Pressable>
      )
    }

    return (
      <Link href={`/token/${item?.token?.address}`} asChild>
        <Pressable onPressIn={prefetchTokenPage} className={className}>
          <TokenItemUI {...item} />
        </Pressable>
      </Link>
    )
  }
)

TokenItem.displayName = 'TokenItem'
