import { LeanText, Pressable, SpeechBubbleIcon } from '@my/ui'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { formatShortNumber } from 'app/utils/common'
import { useRouter } from 'expo-router'
import { StyleSheet } from 'react-native'
import type { VideoFeedItemProps } from './types'

export const PostCommentButton = ({ postItem }: { postItem: VideoFeedItemProps['item'] }) => {
  const router = useRouter()
  const authStatus = useAuthenticationStatus()
  const redirectToSignIn = useRedirectToSignIn()
  const isLoggedOut = authStatus !== 'authenticated'

  const handlePress = useEventCallback(() => {
    if (isLoggedOut) {
      return redirectToSignIn()
    }
    router.navigate(`/comments?postId=${postItem.post.id}`)
  })

  return (
    <Pressable
      onPress={handlePress}
      hitSlop={10}
      className="items-center gap-1.5 justify-center"
      haptics>
      <SpeechBubbleIcon color={'#fff'} height={32} width={32} />
      <LeanText className="text-xs native:text-sm text-white" style={styles.shadow}>
        {formatShortNumber(postItem.post.commentCount ?? 0)}
      </LeanText>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  shadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.4)',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 2,
  },
})
