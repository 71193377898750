import { Platform } from 'react-native'

export const isWeb = Platform.OS === 'web'
export const isIOS = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'
export const isNative = isIOS || isAndroid

export function isAndroidWeb() {
  return (
    typeof navigator !== 'undefined' &&
    navigator.userAgent !== undefined &&
    /android/i.test(navigator.userAgent)
  )
}

export function isSmalliOSWeb() {
  return (
    typeof navigator !== 'undefined' &&
    navigator.userAgent !== undefined &&
    /iPhone|iPod/.test(navigator.userAgent)
  )
}

export function isLargeIOSWeb() {
  return (
    typeof navigator !== 'undefined' &&
    navigator.userAgent !== undefined &&
    /iPad/.test(navigator.userAgent)
  )
}

export function isIOSWeb() {
  return isSmalliOSWeb() || isLargeIOSWeb()
}

export function isSafari(): boolean {
  return (
    typeof navigator !== 'undefined' &&
    navigator.userAgent !== undefined &&
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent)
  )
}

export function isMobileWeb(): boolean {
  return Platform.OS === 'web' && (isAndroidWeb() || isIOSWeb())
}
