import { createPublicClient, fallback, http, webSocket } from 'viem'
import { chain } from './chain'

export const publicClient = createPublicClient({
  chain,
  transport: fallback([
    http(process.env.EXPO_PUBLIC_RPC_PROVIDER_URL),
    http(process.env.EXPO_PUBLIC_FALLBACK_RPC_PROVIDER_URL),
    http(),
  ]),
})

export const webSocketClient = createPublicClient({
  chain,
  transport: webSocket(process.env.EXPO_PUBLIC_RPC_PROVIDER_URL?.replace('https', 'wss')),
})
