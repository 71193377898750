import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  focused?: boolean
}

export function TradeTabIcon(props: Props) {
  return (
    <Svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 24 24" fill="none">
      {props.focused ? (
        <>
          <Path
            d="M23.36 23.03V12.204a1 1 0 00-1.543-.84l-5.606 3.619a1 1 0 01-1.056.018L9.54 11.639a1 1 0 00-1.018-.006L1.94 15.476a1 1 0 00-.496.864v6.69a1 1 0 001 1H22.36a1 1 0 001-1z"
            fill="#fff"
          />
          <Path
            d="M1.969 9.809l7.159-5.541 6.25 4.132 6.886-6.871"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M22.264 5.029v-3.5h-3.5"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <Path fill="#fff" d="M0.946289 16.9767H2.946289V23.9767H0.946289z" />
          <Path fill="#fff" d="M7.54688 13.1682H9.54688V23.9768H7.54688z" />
          <Path fill="#fff" d="M14.1479 16.2928H16.1479V23.97688H14.1479z" />
          <Path fill="#fff" d="M20.749 11.3651H22.749V23.9769H20.749z" />
          <Path
            d="M1.469 9.808l7.159-5.541 6.25 4.133 6.886-6.872"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M21.764 5.028v-3.5h-3.5"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </Svg>
  )
}
