import { EIP1193Provider, Wallets } from '@mobile-wallet-protocol/client'
import { chain } from 'app/utils/chain'
import * as Linking from 'expo-linking'

export const DOMAIN =
  process.env.EXPO_PUBLIC_APP_ENV === 'production' ? 'drakula.app' : 'testnet.drakula.app'

export const PREFIX_URL = `https://${DOMAIN}`

export const coinbaseEIP1193Provider = new EIP1193Provider({
  metadata: {
    appDeeplinkUrl: PREFIX_URL,
    appName: 'Drakula',
    appCustomScheme: Linking.createURL('/'),
    appChainIds: [chain.id],
    appLogoUrl: 'https://drakula-media.b-cdn.net/assets/og-image.png',
  },
  wallet: Wallets.CoinbaseSmartWallet,
})

export const COINBASE_RPC = `https://api.developer.coinbase.com/rpc/v1/${
  chain.id === 84532 ? 'base-sepolia' : 'base'
}/${process.env.EXPO_PUBLIC_ONCHAINKIT_API_KEY}`

export const PAYMASTER_URL = `${process.env.EXPO_PUBLIC_API_URL}/api/paymaster`
