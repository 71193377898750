import type { AppRouter } from '@my/api'
import { TRPCClientError } from '@trpc/client'

import { createTRPCReact } from '@trpc/react-query'

export const trpc = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
})

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError
}
