import type { LogAction } from '@my/utils'
import * as Sentry from '@sentry/react-native'
import { type RumActionType, datadog, getDDogRumActionType } from '../tracking/data-dog'

import { logger } from './index'

type LogInfoProps = {
  type: keyof typeof RumActionType
  message: string
  action: LogAction
  traceId?: string
  data: Record<string, unknown>
}

export const logInfo = ({ type, message, action, traceId, data }: LogInfoProps) => {
  logger.info({ action, traceId, ...data }, message)

  // We do not need to send RUM events or Sentry breadcrumbs in dev
  if (__DEV__) return

  Sentry.addBreadcrumb({
    category: action,
    level: 'info',
    message,
    timestamp: Date.now(),
  })

  datadog.rum.addAction(getDDogRumActionType(type), action, {
    message,
    level: 'info',
    ...data,
  })
}
