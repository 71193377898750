import { LeanView } from '@my/ui'
import { type MutableRefObject } from 'react'
import { Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { MuteButton } from '../mute-button.web'
import type { MuteRef } from '../types'

type VideoFeedHeaderProps = {
  muteRef: MutableRefObject<MuteRef>
}

export const VideoFeedHeader = ({ muteRef }: VideoFeedHeaderProps) => {
  const insets = useSafeAreaInsets()

  return (
    <LeanView
      pointerEvents="box-none"
      className="absolute left-0 w-full flex-[1] z-20 flex-row justify-between items-center h-16"
      style={{
        top: insets.top,
      }}>
      <LeanView className="flex-row lg:hidden" />

      <LeanView className="p-4 flex-row gap-4">
        {Platform.OS === 'web' ? <MuteButton muteRef={muteRef} /> : null}
      </LeanView>
    </LeanView>
  )
}
