import { usePrivy, useWallets } from '@privy-io/react-auth'
import * as Sentry from '@sentry/react-native'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { resetQueries } from 'app/provider/react-query/query-client'
import { storage } from 'app/utils/mmkv/storage'
import { datadog, mixpanel } from 'app/utils/tracking'
import { useRouter } from 'expo-router'
import { logError } from '../logging/logError'

export const usePrivySignOut = () => {
  const { logout } = usePrivy()
  const wallets = useWallets()
  const router = useRouter()

  return useEventCallback(async () => {
    try {
      await logout()
      for (const wallet of wallets.wallets) {
        wallet.disconnect()
      }
      storage.clearAll()
      mixpanel.reset()
      datadog.reset()
      Sentry.setUser(null)
      if (router.canGoBack()) {
        router.back()
      } else {
        router.replace('/')
      }
      // TODO: add push token unregister logic for web, once we have it
    } catch (error) {
      logError({
        type: 'TAP',
        action: 'LOGOUT',
        level: 'error',
        message: 'Failed to sign out',
        error,
      })
    } finally {
      resetQueries()
    }
  })
}
