import { skipToken } from '@tanstack/react-query'
import { useEmbeddedSmartWallet } from 'app/provider/embedded-smart-wallet-provider/context'
import { trpc } from 'app/utils/api'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'

export const useUserBalances = () => {
  const embeddedSmartWallet = useEmbeddedSmartWallet()
  const authStatus = useAuthenticationStatus()
  return trpc.portfolio.balances.useQuery(
    authStatus === 'authenticated' && embeddedSmartWallet.address ? undefined : skipToken,
    {
      refetchInterval:
        authStatus === 'authenticated' && embeddedSmartWallet.address ? 15000 : undefined, // Refetch every 15 seconds
    }
  )
}

export const useRefetchUserBalances = () => {
  const { refetch } = trpc.portfolio.balances.useQuery(undefined, {
    enabled: false, // Don't fetch on mount
  })
  return refetch
}
