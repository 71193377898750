import BottomSheet, {
  BottomSheetBackdrop,
  type BottomSheetBackgroundProps,
  type BottomSheetFooterProps,
  BottomSheetView,
  useBottomSheetSpringConfigs,
} from '@gorhom/bottom-sheet'
import { useIsFocused } from '@react-navigation/native'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useRouter } from 'expo-router'
import {
  type ComponentProps,
  type RefObject,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ActivityIndicator, Modal, Platform, useWindowDimensions } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import {
  KeyboardController,
  useReanimatedKeyboardAnimation,
} from 'react-native-keyboard-controller'
import { FadeIn, ReduceMotion, useAnimatedStyle } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { LeanText } from '../..'
import { CloseIcon } from '../../icons/close-icon'
import { AnimatedLeanView } from '../lean-view/lean-view'
import { Pressable } from '../pressable'

let globalSheetRefs: Array<{ dismiss: () => Promise<void> }> = []

export const dismissSheet = async () => {
  while (globalSheetRefs.length > 0) {
    await globalSheetRefs.pop()?.dismiss()
  }
  globalSheetRefs = []
}

export type BottomSheetGorhomRef = BottomSheet & {
  close: () => Promise<void>
  dismiss: () => Promise<void>
}

type Props = {
  children: React.ReactNode
  onClose?: () => void
  onOpen?: () => void
  loading?: boolean
  title?: string
  disableScrollLocking?: boolean
  headerLeft?: React.ReactNode
  headerTransparent?: boolean
  disableDrag?: boolean
  disableClose?: boolean
  hideHeader?: boolean
  snapPoints?: ComponentProps<typeof BottomSheet>['snapPoints']
  shouldAdjustForKeyboard?: boolean
  wrapView?: boolean
  footerComponent?: React.FC<BottomSheetFooterProps>
  backdropOpacity?: number
  backgroundStyle?: ComponentProps<typeof BottomSheet>['backgroundStyle']
}

const useOnClose = () => {
  const router = useRouter()
  return useEventCallback(() => {
    if (router.canGoBack()) {
      router.back()
    } else {
      router.replace('/')
    }
  })
}

export const BottomSheetGorhom = memo(
  forwardRef<BottomSheet, Props>((props, ref) => {
    const { width } = useWindowDimensions()

    if (width > 767) {
      return <BottomSheetModalDesktop {...props} ref={ref} />
    }

    return <BottomSheetGorhomDrawer {...props} ref={ref} />
  })
)

export const BottomSheetModalDesktop = memo(
  forwardRef<unknown, Props>((props, ref) => {
    const {
      onClose,
      children,
      loading,
      title,
      onOpen,
      hideHeader,
      disableClose,
      headerLeft,
      footerComponent,
    } = props
    const defaultOnClose = useOnClose()
    const [isVisible, setIsVisible] = useState(true)
    const closeModalPromiseResolve = useRef<() => void>()

    const dismiss = useEventCallback(async () => {
      setIsVisible(false)
    })
    const internalRef = useRef<BottomSheet>(null)

    useImperativeHandle(
      ref,
      () => ({
        dismiss,
        close: dismiss,
      }),
      [dismiss]
    )

    const onCloseEvent = useEventCallback(async () => {
      return new Promise<void>((resolve) => {
        closeModalPromiseResolve.current = resolve
        onClose ? onClose() : defaultOnClose()
      })
    })

    useEffect(() => {
      if (internalRef.current?.close) {
        const handleDismiss = {
          dismiss,
        }
        globalSheetRefs.push(handleDismiss)
        return () => {
          globalSheetRefs = globalSheetRefs.filter((fn) => fn !== handleDismiss)
        }
      }
    }, [dismiss])

    return (
      <Modal
        visible={isVisible}
        hardwareAccelerated
        transparent
        onShow={onOpen}
        onDismiss={onCloseEvent}
        onRequestClose={onCloseEvent}>
        {!disableClose ? (
          <Pressable className="absolute top-0 left-0 right-0 bottom-0" onPress={onCloseEvent} />
        ) : null}
        <AnimatedLeanView
          entering={FadeIn.duration(100)}
          pointerEvents="box-none"
          className="flex-grow justify-center items-center flex-1 bg-neutral-800/90">
          <AnimatedLeanView className="bg-appDarkBg rounded-xl max-w-2xl mx-auto max-h-[calc(100lvh-10rem)] overflow-auto">
            {!hideHeader ? (
              <SheetNavigationHeader
                sheetRef={ref as unknown as RefObject<BottomSheet>}
                showSpinner={loading}
                title={title}
                headerLeft={headerLeft}
              />
            ) : null}
            {children}
            {footerComponent ? footerComponent({} as never) : null}
          </AnimatedLeanView>
        </AnimatedLeanView>
      </Modal>
    )
  })
)

const AutoHeightViewOrFragment = (props: { children?: React.ReactNode; useFragment?: boolean }) => {
  const insets = useSafeAreaInsets()

  if (props.useFragment) return <>{props.children}</>

  return (
    <BottomSheetView style={{ paddingBottom: insets.bottom }}>{props.children}</BottomSheetView>
  )
}

const PlatformModal = (props: ComponentProps<typeof Modal>) => {
  return Platform.OS === 'web' ? <Modal transparent>{props.children}</Modal> : <>{props.children}</>
}

export const BottomSheetGorhomDrawer = memo(
  forwardRef<BottomSheet, Props>((props, ref) => {
    const {
      onClose,
      children,
      loading,
      title,
      onOpen,
      disableDrag,
      hideHeader,
      disableClose,
      headerLeft,
      headerTransparent = false,
      snapPoints,
      footerComponent,
      shouldAdjustForKeyboard = true,
      wrapView = true,
      backdropOpacity = 0.8,
      backgroundStyle = {
        backgroundColor: '#111',
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
      },
    } = props
    const isFocused = useIsFocused()
    const defaultOnClose = useOnClose()
    const internalRef = useRef<BottomSheet>(null)
    const closeModalPromiseResolve = useRef<() => void>()
    const dismiss = useEventCallback(() => {
      return new Promise<void>((resolve) => {
        closeModalPromiseResolve.current = resolve
        internalRef.current?.forceClose()
      })
    })

    useImperativeHandle(
      ref,
      // @ts-expect-error TODO: its fine, null is ok
      () => ({
        ...internalRef.current,
        dismiss,
      }),
      [dismiss]
    )

    const animationConfigs = useBottomSheetSpringConfigs({
      damping: 100,
      overshootClamping: true,
      restDisplacementThreshold: 0.1,
      restSpeedThreshold: 2,
      stiffness: 200,
      duration: 180,
      reduceMotion: ReduceMotion.Never,
    })

    const renderBackdrop = useCallback(
      (props: BottomSheetBackgroundProps) => (
        <BottomSheetBackdrop
          {...props}
          style={{ backgroundColor: '#333' }}
          pressBehavior={disableClose ? 'none' : undefined}
          disappearsOnIndex={-1}
          appearsOnIndex={0}
          opacity={backdropOpacity}
        />
      ),
      [disableClose, backdropOpacity]
    )

    const { height } = useReanimatedKeyboardAnimation()

    // this moves the bottom sheet up when the keyboard is open
    const viewStyle = useAnimatedStyle(() => {
      return {
        transform: [{ translateY: shouldAdjustForKeyboard && isFocused ? height.value : 0 }],
      }
    })

    return (
      <PlatformModal>
        <AnimatedLeanView className="flex-grow" style={viewStyle}>
          <GestureHandlerRootView className="flex-grow">
            <BottomSheet
              backdropComponent={renderBackdrop}
              enableOverDrag
              backgroundStyle={backgroundStyle}
              ref={internalRef}
              onChange={(index) => {
                if (index === 0) {
                  onOpen?.()
                  const handleDismiss = {
                    dismiss,
                  }
                  globalSheetRefs.push(handleDismiss)
                }
              }}
              onAnimate={(_from, to) => {
                if (to === -1) {
                  KeyboardController.dismiss()
                }
              }}
              handleComponent={null}
              enablePanDownToClose={!disableDrag}
              enableDynamicSizing={snapPoints === undefined}
              overrideReduceMotion={ReduceMotion.Never}
              animationConfigs={animationConfigs}
              footerComponent={footerComponent}
              snapPoints={snapPoints}
              keyboardBehavior="interactive"
              onClose={() => {
                onClose ? onClose() : defaultOnClose()
                const index = globalSheetRefs.findIndex((fn) => fn.dismiss === dismiss)
                if (index !== -1) {
                  globalSheetRefs.splice(index, 1)
                }
                closeModalPromiseResolve.current?.()
                closeModalPromiseResolve.current = undefined
              }}>
              <AutoHeightViewOrFragment useFragment={!wrapView}>
                {!hideHeader ? (
                  <SheetNavigationHeader
                    sheetRef={ref as unknown as RefObject<BottomSheet>}
                    showSpinner={loading}
                    title={title}
                    headerLeft={headerLeft}
                    transparent={headerTransparent}
                  />
                ) : null}
                {children}
              </AutoHeightViewOrFragment>
            </BottomSheet>
          </GestureHandlerRootView>
        </AnimatedLeanView>
      </PlatformModal>
    )
  })
)

BottomSheetGorhom.displayName = 'BottomSheetGorhom'

type HeaderProps = {
  sheetRef: React.RefObject<BottomSheet>
  title?: string
  showSpinner?: boolean
  headerLeft?: React.ReactNode
  transparent?: boolean
}

const SheetNavigationHeader = ({
  sheetRef,
  title,
  showSpinner,
  headerLeft,
  transparent,
}: HeaderProps) => {
  const handleClose = () => {
    sheetRef.current?.close()
  }

  if (transparent) {
    return (
      <AnimatedLeanView className="absolute top-0 left-0 right-0 z-50" pointerEvents="box-none">
        <Pressable
          onPress={handleClose}
          style={{
            borderCurve: 'continuous',
          }}
          className="absolute right-5 top-5 p-2 rounded-full bg-surfaceDarkShade2 opacity-100 transition-all active:opacity-50"
          hitSlop={10}>
          <CloseIcon width={14} height={14} />
        </Pressable>
      </AnimatedLeanView>
    )
  }

  return (
    <AnimatedLeanView
      className="flex-row items-center justify-between h-16 px-6 z-10"
      pointerEvents="box-none">
      {headerLeft}
      {showSpinner ? (
        <AnimatedLeanView className="absolute right-6 top-6 z-10 rounded-full bg-surfaceDarkShade2">
          <ActivityIndicator size="small" color="white" />
        </AnimatedLeanView>
      ) : (
        <Pressable
          onPress={handleClose}
          className="absolute right-5 top-5 p-2 z-10 rounded-full bg-surfaceDarkShade2 opacity-100 transition-all active:opacity-50"
          hitSlop={10}>
          <CloseIcon width={14} height={14} />
        </Pressable>
      )}
      {title ? (
        <LeanText className="text-white font-semibold text-lg text-center mx-auto">
          {title}
        </LeanText>
      ) : null}
    </AnimatedLeanView>
  )
}

SheetNavigationHeader.displayName = 'SheetNavigationHeader'
