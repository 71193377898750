import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  focused?: boolean
}

export function HomeTabIcon(props: Props) {
  if (props.focused) {
    return (
      <Svg width={props.width ?? 25} height={props.height ?? 25} viewBox="0 0 27 25" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8857 0.778185C14.1529 0.186568 13.1066 0.186569 12.3738 0.778185L1.54032 9.52439C1.07015 9.90397 0.796875 10.4758 0.796875 11.0801V22.8572C0.796875 23.9615 1.69204 24.8567 2.79628 24.8567H8.87028C9.97452 24.8567 10.8697 23.9615 10.8697 22.8572V17.2232C10.8697 16.1189 11.7649 15.2238 12.8691 15.2238H14.2498C15.354 15.2238 16.2492 16.1189 16.2492 17.2232V22.8572C16.2492 23.9615 17.1444 24.8567 18.2486 24.8567H24.4632C25.5675 24.8567 26.4626 23.9615 26.4626 22.8572V11.0801C26.4626 10.4758 26.1894 9.90397 25.7192 9.52439L14.8857 0.778185Z"
          fill="white"
        />
      </Svg>
    )
  }

  return (
    <Svg width={props.width ?? 25} height={props.height ?? 25} viewBox="0 0 28 27" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1375 2.39068C14.6794 2.02081 14.0252 2.02081 13.5671 2.39068L2.73042 11.1395C2.43647 11.3768 2.26562 11.7343 2.26562 12.1121V23.8927C2.26562 24.5831 2.82527 25.1427 3.51563 25.1427H9.59131C10.2817 25.1427 10.8413 24.5831 10.8413 23.8927V18.2564C10.8413 16.7376 12.0725 15.5064 13.5913 15.5064H14.9724C16.4912 15.5064 17.7224 16.7376 17.7224 18.2564V23.8927C17.7224 24.5831 18.2821 25.1427 18.9724 25.1427H25.189C25.8793 25.1427 26.439 24.5831 26.439 23.8927V12.1121C26.439 11.7343 26.2681 11.3768 25.9742 11.1395L15.1375 2.39068ZM12.6248 1.22357C13.6327 0.409853 15.0719 0.409854 16.0798 1.22357L26.9164 9.97235C27.5631 10.4944 27.939 11.281 27.939 12.1121V23.8927C27.939 25.4115 26.7077 26.6427 25.189 26.6427H18.9724C17.4536 26.6427 16.2224 25.4115 16.2224 23.8927V18.2564C16.2224 17.5661 15.6628 17.0064 14.9724 17.0064H13.5913C12.901 17.0064 12.3413 17.5661 12.3413 18.2564V23.8927C12.3413 25.4115 11.1101 26.6427 9.59131 26.6427H3.51563C1.99684 26.6427 0.765625 25.4115 0.765625 23.8927V12.1121C0.765625 11.281 1.1415 10.4944 1.78816 9.97235L12.6248 1.22357Z"
        fill="#D3D3D3"
      />
    </Svg>
  )
}
