import { Button, type ButtonProps } from '@my/ui'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { useMe } from '../profile/hooks/use-me'
import { useFollow } from './hooks/use-follow'
import type { User } from './types'

export const FollowButton = ({
  user,
  variant,
  classNameText,
  className,
}: {
  user: User
  variant?: ButtonProps['variant']
  classNameText?: ButtonProps['classNameText']
  className?: ButtonProps['className']
}) => {
  const me = useMe()
  const follow = useFollow({ user })

  const redirectToSignIn = useRedirectToSignIn()
  const authStatus = useAuthenticationStatus()
  const isLoggedOut = authStatus !== 'authenticated'

  const handleFollow = useEventCallback(() => {
    if (isLoggedOut) {
      return redirectToSignIn()
    }
    follow.mutate({ userId: user.id })
  })

  if (me.data?.id === user.id) {
    return null
  }

  return (
    <Button
      onPress={handleFollow}
      variant={variant}
      className={className}
      classNameText={classNameText}>
      Follow
    </Button>
  )
}
