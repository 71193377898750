import type { GetSwapQuoteResponse } from '@coinbase/onchainkit/api'
// @ts-expect-error
import { getSwapQuote } from '@coinbase/onchainkit/esm/api'
import type { SwapQuote } from '@coinbase/onchainkit/swap'
import { skipToken, useQuery } from '@tanstack/react-query'
import { wowAbi } from 'app/features/wow/abi'
import { useEmbeddedSmartWallet } from 'app/provider/embedded-smart-wallet-provider/context'
import { publicClient } from 'app/utils/viem-public-client'
import { formatUnits, type Address } from 'viem'
import { ethToken, usdcToken } from '../constants'
import { MarketType, useTokenMarketState } from './use-token-market-state'

type Params = {
  amount: bigint
  isWowToken?: boolean
  token: {
    address: Address
    decimals: number
  }
}

export const useUsdQuote = (params?: Params) => {
  const embeddedSmartWallet = useEmbeddedSmartWallet()
  const wowTokenMarketType = useTokenMarketState(
    params ? { address: params.token.address, isWowToken: params.isWowToken } : undefined
  )

  return useQuery({
    queryKey: ['usd-quote', params, wowTokenMarketType.data],
    queryFn:
      params && embeddedSmartWallet.address && wowTokenMarketType.data
        ? async () => {
            // If wow token and bonding curve market, get quote from contract
            if (params.isWowToken) {
              if (wowTokenMarketType.data === MarketType.BONDING_CURVE) {
                const ethAmount = (await publicClient.readContract({
                  address: params.token.address,
                  abi: wowAbi,
                  functionName: 'getTokenSellQuote',
                  args: [params.amount],
                })) as bigint

                const quoteReq = {
                  from: ethToken,
                  to: usdcToken,
                  amount: formatUnits(ethAmount, ethToken.decimals),
                  useAggregator: false,
                }

                const quote = (await getSwapQuote(quoteReq)) as GetSwapQuoteResponse

                if ('error' in quote) {
                  throw new Error(quote.code)
                }

                return quote
              }
            }

            // USDC -> USDC
            if (params.token.address.toLowerCase() === usdcToken.address.toLowerCase()) {
              return {
                from: usdcToken,
                to: params.token,
                fromAmount: params.amount.toString(),
                toAmount: params.amount.toString(),
                amountReference: 'from',
                priceImpact: '1',
                slippage: '0',
                hasHighPriceImpact: false,
              } as SwapQuote
            }

            const quoteReq = {
              from: params.token,
              to: usdcToken,
              amount: formatUnits(params.amount, params.token.decimals),
              useAggregator: false,
            }

            const quote = (await getSwapQuote(quoteReq)) as GetSwapQuoteResponse

            if ('error' in quote) {
              throw new Error(quote.code)
            }

            return quote
          }
        : skipToken,
  })
}
