import { cn, Pressable, Text } from '@my/ui'
import { useMemo } from 'react'

type Props = {
  suggestion: {
    label: string
    selected?: boolean
    onPress: () => void
  }
}

export const SuggestionButton = ({ suggestion }: Props) => {
  const suggestionButtonClass = useMemo(() => {
    return cn(
      'px-4 py-2 bg-neutral-800 rounded-full border-[2px]',
      suggestion.selected ? 'border-neutral-500' : 'border-transparent'
    )
  }, [suggestion.selected])

  return (
    <Pressable
      scale
      key={suggestion.label}
      className={suggestionButtonClass}
      onPress={suggestion.onPress}>
      <Text className="text-grayTextColor text-base">{suggestion.label}</Text>
    </Pressable>
  )
}
