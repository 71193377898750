import {
  BottomSheetGorhom,
  ImageSequencer,
  LeanText,
  LeanView,
  type BottomSheetGorhomRef,
} from '@my/ui'
import { useRouter } from 'expo-router'
import { useMemo, useRef } from 'react'
import { useSharedValue } from 'react-native-reanimated'
import { useEventCallback } from '../../hooks/use-event-callback'
import type { VideoFeedItemData } from '../video-feed/types'
import { TokenGraph } from './token-graph'
import { TokenPrice } from './token-price'

const VIDEOCOIN_CAROUSEL_ITEM_IMAGE_HEIGHT = 72 // aspect ratio 9/16
const VIDEOCOIN_CAROUSEL_ITEM_IMAGE_WIDTH = 40.5 // aspect ratio 9/16

export const TokenDetailsModal = ({
  item,
}: {
  item: VideoFeedItemData
}) => {
  const modalRef = useRef<BottomSheetGorhomRef>(null)
  const router = useRouter()
  const visibleItems = useSharedValue<string[]>([item.post.id])

  const onTradeModalClose = useEventCallback(() => {
    router.setParams({ expand: undefined })
  })

  const imageUrls = useMemo(() => {
    return [
      `${item.media.baseUrl}/thumbnail.jpg?class=imagesequence`,
      `${item.media.baseUrl}/thumbnail_2.jpg?class=imagesequence`,
      `${item.media.baseUrl}/thumbnail_3.jpg?class=imagesequence`,
      `${item.media.baseUrl}/thumbnail_4.jpg?class=imagesequence`,
      `${item.media.baseUrl}/thumbnail_5.jpg?class=imagesequence`,
    ]
  }, [item.media.baseUrl])

  if (!item.post.tokenDetails) return null

  return (
    <LeanView className="absolute top-0 left-0 right-0 bottom-0">
      <BottomSheetGorhom
        ref={modalRef}
        onClose={onTradeModalClose}
        snapPoints={['85%']}
        backdropOpacity={0}
        headerTransparent
        backgroundStyle={{ backgroundColor: '#111', opacity: 0.95 }}>
        <LeanView>
          <LeanView className="p-6 flex-row items-center gap-3">
            <LeanView className="rounded-md overflow-hidden bg-neutral-700">
              <ImageSequencer
                itemId={item.post.id}
                visibleItems={visibleItems}
                imageUrls={imageUrls}
                style={{
                  width: VIDEOCOIN_CAROUSEL_ITEM_IMAGE_WIDTH,
                  height: VIDEOCOIN_CAROUSEL_ITEM_IMAGE_HEIGHT,
                  backgroundColor: '#404040',
                }}
              />
            </LeanView>
            <LeanView>
              <LeanText className="text-2xl text-white font-semibold">
                {item.post.tokenDetails.name}
              </LeanText>
              <LeanText className="text-grayTextColor">{item.post.tokenDetails.symbol}</LeanText>
            </LeanView>
          </LeanView>
        </LeanView>
        {item.post.tokenDetails.address ? (
          <LeanView className="pb-12">
            <TokenPrice />
            <TokenGraph
              address={item.post.tokenDetails.address}
              currentPrice={
                Number.isNaN(Number(item.post.tokenDetails.priceUSD))
                  ? 0
                  : Number(item.post.tokenDetails.priceUSD)
              }
            />
          </LeanView>
        ) : null}
      </BottomSheetGorhom>
    </LeanView>
  )
}
