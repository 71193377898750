import { type LogAction, isError } from '@my/utils'
import * as Sentry from '@sentry/react-native'
import type { SeverityLevel } from '@sentry/types'
import { serializeError } from 'serialize-error'
import { datadog } from '../tracking'
import { type RumActionType, getDDogRumActionType } from '../tracking/data-dog'
import { logger } from './index'

type Primitive = string | number | boolean

export type LogErrorProps = {
  type: keyof typeof RumActionType
  message: string
  // label for the event that is necessary for data dog
  action: LogAction
  error?: { message: string } | string | unknown
  traceId?: string
  // searchable tags to be used in Sentry (strings, numbers, booleans, etc. objects / arrays not allowed)
  indexedTags?: Record<string, Primitive>
  // any additional data to attach to the event that we don't necessarily to to search on to help debugginh
  unindexedExtra?: Record<string, unknown>
  // how serious the error is ('fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug')
  level: SeverityLevel
}

/*
 * SeverityLevel props:
 * error: for bugs
 * warn: interesting happened and there is a javascript error, but we handled it and its not a bug (think a validation error)
 * fatal: the page / app is broken, or the user couldn't do the thing they wanted to do.
 * info: info logging about interesting things user did like starting actions or completing actions successfully
 */
function getLoggerFromLevel(level: SeverityLevel) {
  switch (level) {
    case 'debug':
      return logger.debug
    case 'error':
    case 'fatal':
      return logger.error
    case 'info':
    case 'log':
      return logger.info
    case 'warning':
      return logger.warn
  }
}

export function logError({
  type,
  error,
  message,
  action,
  traceId,
  indexedTags = {},
  level,
  unindexedExtra = {},
}: LogErrorProps) {
  let sentryEventId: string | undefined = undefined

  let errMessage: string

  if (typeof error === 'string') {
    errMessage = error
  } else if (isError(error)) {
    errMessage = error.message
  } else {
    errMessage = message
  }

  if (error) {
    sentryEventId = Sentry.captureException(
      isError(error) ? error : new Error(JSON.stringify(error)),
      {
        tags: {
          ...indexedTags,
          action,
          traceId,
        },
        extra: { ...unindexedExtra, message },
        level,
      }
    )
  }

  const loggerFn = getLoggerFromLevel(level)

  loggerFn(
    {
      ...indexedTags,
      ...unindexedExtra,
      error: serializeError(error),
      action,
      message,
      level,
      traceId,
      sentryEventId,
    },
    errMessage
  )

  // We do not need to send RUM events in dev
  if (__DEV__) return

  datadog.rum.addAction(getDDogRumActionType(type), action, {
    ...indexedTags,
    ...unindexedExtra,
    error: serializeError(error),
    level,
    message,
    traceId,
    sentryEventId,
  })
}
