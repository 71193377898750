import { useMemo } from 'react'
import { ethToken } from '../constants'
import { useUsdQuote } from './use-usd-quote'
import { useUserEthBalance } from './use-user-eth-balance'

export const useEthBalanceInUsd = () => {
  const ethBalance = useUserEthBalance()

  const ethBalanceQuoteInUsd = useUsdQuote(
    typeof ethBalance.data !== 'undefined' && ethBalance.data?.balance > 0n
      ? {
          amount: ethBalance.data.balance,
          token: {
            address: ethToken.address,
            decimals: ethToken.decimals,
          },
        }
      : undefined
  )

  const ethBalanceInUsd = useMemo(
    () =>
      typeof ethBalanceQuoteInUsd.data?.toAmount !== 'undefined'
        ? BigInt(ethBalanceQuoteInUsd.data.toAmount)
        : ethBalance.data?.balance === 0n
          ? 0n
          : undefined,
    [ethBalanceQuoteInUsd.data?.toAmount, ethBalance.data?.balance]
  )

  return ethBalanceInUsd
}
