import Svg, { Circle, Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
}

export function BaseIcon(props: Props) {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 20 21" fill="none">
      <Circle
        cx={10.1403}
        cy={10.6598}
        r={9.11396}
        fill="#2151F5"
        stroke="#111"
        strokeWidth={1.16608}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.13 17.055a6.396 6.396 0 10-6.372-6.958h8.395v1.126H3.758a6.397 6.397 0 006.371 5.832z"
        fill="#fff"
      />
    </Svg>
  )
}
