import { Pressable, Text, View } from '@my/ui'
import { useFilePicker } from 'app/features/file-picker'
import { Image } from 'expo-image'
import { Platform } from 'react-native'

type TextFieldProps = {
  disabled?: boolean
  errorMessage?: string
  label?: string
  onChange: (file: File | string) => void
  value?: string | File | null
  placeholder?: React.ReactNode
  containerClassName?: string
  renderAfter?: React.ReactNode
  bunnyImageTransformClass?: string
}

export const ImageField = (props: TextFieldProps) => {
  const {
    disabled,
    errorMessage,
    label,
    onChange,
    value,
    placeholder,
    containerClassName,
    renderAfter,
    bunnyImageTransformClass,
  } = props
  const pickFile = useFilePicker()

  return (
    <View className={containerClassName}>
      {label ? <Text className="text-gray-300 mb-2">{label}</Text> : null}
      <Pressable
        className="w-[120px] h-[120px] border-1 border-gray-700 rounded-full overflow-hidden m-2 opacity-100 active:opacity-60"
        disabled={disabled}
        onPress={async () => {
          const file = await pickFile({
            mediaTypes: 'images',
            option: Platform.select({
              // aspect option only support android.
              ios: { allowsEditing: true },
              android: { allowsEditing: true },
              default: {},
            }),
          })
          onChange(file.file)
        }}>
        {value ? (
          <Image
            transition={150}
            source={{ uri: getLocalFileURI(value, bunnyImageTransformClass) }}
            style={{ height: 120, width: 120, backgroundColor: '#333' }}
            alt=""
          />
        ) : placeholder ? (
          placeholder
        ) : null}
        {label ? <Text className="text-gray-300 mb-2">{label}</Text> : null}
      </Pressable>
      {renderAfter ? renderAfter : null}
      {errorMessage ? <Text className="mt-2 text-red-500">{errorMessage}</Text> : null}
    </View>
  )
}

const getLocalFileURI = (file?: string | File, transformationClass?: string) => {
  if (!file) return undefined

  if (typeof file === 'string') return `${file}?class=${transformationClass || ''}`

  if (Platform.OS === 'web') return (URL || webkitURL)?.createObjectURL(file)

  return undefined
}
