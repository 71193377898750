import Ionicons from '@expo/vector-icons/Ionicons'
import { Pressable } from '../components/pressable'

type IconProps = {
  onPress: () => void
  size?: number
}

export const InfoIcon = ({ onPress, size = 14 }: IconProps) => {
  return (
    <Pressable
      onPress={(event) => {
        event.stopPropagation()
        onPress?.()
      }}
      hitSlop={10}
      style={{ width: size, height: size }}>
      <Ionicons name="information-circle" size={size} color="#8F8F8F" />
    </Pressable>
  )
}
