import { observable } from '@legendapp/state'
import { type ImageSourcePropType } from 'react-native'

export const channelSelectedState = observable({
  selectedChannel: null as null | {
    channelId: string
    imageSrc?: ImageSourcePropType
    name?: string
  },
})
