import {
  ItemKeyContext,
  ViewabilityItemsContext,
} from 'app/utils/with-viewability-infinite-scroll-list'
import { useContext, useState } from 'react'
import { runOnJS, useAnimatedReaction } from 'react-native-reanimated'

export const useIsItemInVisibilityWindow = () => {
  const context = useContext(ViewabilityItemsContext)
  const id = useContext(ItemKeyContext)
  const [itemInWindow, setItemInWindow] = useState(false)
  const isItemInList = typeof id !== 'undefined'

  useAnimatedReaction(
    () => context.value,
    (ctx) => {
      if (isItemInList) {
        if (ctx.includes(id)) {
          runOnJS(setItemInWindow)(true)
        } else {
          runOnJS(setItemInWindow)(false)
        }
      } else {
        runOnJS(setItemInWindow)(false)
      }
    },
    [id, isItemInList, context]
  )

  return itemInWindow
}
