// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useImperativeHandle, useRef } from 'react'
import {
  Animated,
  type LayoutChangeEvent,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native'

import {
  type NavigationState,
  type SceneRendererProps,
  TabBar,
  type TabViewProps,
} from 'react-native-tab-view'

import { useBatchedState } from 'app/hooks/batching/use-batched-state'
import { WEB_HEADER_HEIGHT } from 'app/utils/common'
import { HeaderTabContext } from './context'
import { useSceneInfo } from './hooks'
import type { CollapsibleHeaderProps, Route } from './types'

export {
  TabFlatList,
  TabScrollView,
  TabSectionList,
  TabScrollViewProps,
  TabFlatListProps,
  TabSectionListProps,
} from './scrollable-view'

// biome-ignore lint/complexity/noBannedTypes: dunno
export type HeaderTabViewRef = {}
export type HeaderTabViewProps<T extends Route> = Partial<TabViewProps<T>> &
  Pick<TabViewProps<T>, 'onIndexChange' | 'navigationState' | 'renderScene'> &
  CollapsibleHeaderProps<T>

export function createCollapsibleTabsComponent() {
  return React.forwardRef(CollapsibleHeaderTabView)
}

function CollapsibleHeaderTabView<T extends Route>(
  {
    renderTabBar: renderTabBarProp,
    renderScrollHeader,
    initTabbarHeight = 44,
    minHeaderHeight = 0,
    navigationState,
    emptyBodyComponent,
    renderScene,
    renderSceneHeader: renderSceneHeaderProp,
    ...restProps
  }: HeaderTabViewProps<T>,
  ref?: React.Ref<HeaderTabViewRef>
) {
  const shareAnimatedValue = { value: 0 }
  const headerTrans = { value: 0 }
  const curIndexValue = { value: 0 }
  const isSlidingHeader = { value: false }
  const isStartRefreshing = { value: false }

  const position = useRef(new Animated.Value(navigationState.index))
  const [selectedRouteIndex, setSelectedRouteIndex] = useBatchedState(navigationState.index)
  // layout
  const [tabbarHeight, setTabbarHeight] = useBatchedState(initTabbarHeight)
  const { updateSceneInfo } = useSceneInfo(curIndexValue)
  const dimension = useWindowDimensions()

  const containeRef = useRef(null)
  useImperativeHandle(ref, () => ({}), [])
  const tabbarOnLayout = useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }: LayoutChangeEvent) => {
      if (Math.abs(tabbarHeight - height) < 1) return
      setTabbarHeight(height)
    },
    [setTabbarHeight, tabbarHeight]
  )
  const renderTabBar = useCallback(
    (
      tabbarProps: SceneRendererProps & {
        navigationState: NavigationState<T>
      }
    ) => {
      return renderTabBarProp ? renderTabBarProp(tabbarProps) : <TabBar {...tabbarProps} />
    },
    [renderTabBarProp]
  )

  const renderTabBarContainer = (children: React.ReactElement) => {
    return (
      <View
        style={[
          styles.tabbarStyle,
          {
            position: 'sticky',
            top: dimension.width >= 1024 ? WEB_HEADER_HEIGHT.desktop : WEB_HEADER_HEIGHT.mobile,
          },
        ]}>
        <View onLayout={tabbarOnLayout}>{children}</View>
      </View>
    )
  }
  const renderSceneHeader = (
    children: React.ReactElement,
    props: SceneRendererProps & { route: T }
  ) => {
    return (
      <View style={styles.full}>
        {renderSceneHeaderProp?.(props.route)}
        {children}
      </View>
    )
  }

  const renderTabView = () => {
    return (
      <View>
        {renderTabBarContainer(
          renderTabBar({
            navigationState,
            position: position.current,
            jumpTo: (key) => {
              const index = navigationState.routes.findIndex((route) => route.key === key)
              Animated.timing(position.current, {
                toValue: index,
                duration: 200,
                useNativeDriver: true,
              }).start()
              setSelectedRouteIndex(index)
              restProps.onIndexChange?.(index)
            },
          })
        )}
        {renderSceneHeader(renderScene({ route: navigationState.routes[selectedRouteIndex] }), {
          route: navigationState.routes[selectedRouteIndex],
        })}
      </View>
    )
  }

  return (
    <HeaderTabContext.Provider
      value={{
        shareAnimatedValue,
        headerTrans,
        tabbarHeight,
        expectHeight: 0,
        headerHeight: 0,
        refreshHeight: 0,
        overflowPull: 0,
        pullExtendedCoefficient: 0,
        refHasChanged: () => false,
        curIndexValue,
        minHeaderHeight,
        updateSceneInfo,
        isSlidingHeader,
        isStartRefreshing,
        scrollStickyHeaderHeight: 0,
        scrollViewPaddingTop: 0,
      }}>
      <View ref={containeRef} style={styles.full}>
        {renderScrollHeader?.()}
        {navigationState.routes.length === 0 && emptyBodyComponent ? (
          <View style={{ marginTop: tabbarHeight }}>{emptyBodyComponent}</View>
        ) : (
          renderTabView()
        )}
      </View>
    </HeaderTabContext.Provider>
  )
}

const styles = StyleSheet.create({
  full: {
    flex: 1,
  },
  tabbarStyle: {
    zIndex: 1,
  },
})
