import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const RocketIcon = (props: Props) => {
  return (
    <Svg
      width={props.width ?? 21}
      height={props.height ?? 23}
      viewBox="0 0 21 23"
      fill={props.color}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3051 8.75486C15.8107 6.69435 15.3238 4.32765 14.8314 3.72359L13.1083 6.70806C13.938 7.1874 14.2221 8.24846 13.743 9.0783C13.2639 9.90814 12.2029 10.1926 11.373 9.71377L8.97847 13.8612L14.2652 16.9135C14.6193 16.3001 14.5867 16.1794 14.378 15.4052L14.378 15.4052C14.2887 15.0738 14.167 14.6227 14.0296 13.9621C13.7456 12.5962 14.0607 11.9126 14.4919 10.9772C14.7564 10.4032 15.0647 9.73437 15.3051 8.75486ZM11.3727 9.7136L8.97805 13.8612L3.69137 10.809C4.0455 10.1956 4.16634 10.1634 4.94118 9.95709C5.27281 9.86878 5.72426 9.74857 6.36509 9.53723C7.69004 9.10028 8.1245 8.48558 8.71897 7.6445C9.08379 7.12835 9.50886 6.52694 10.237 5.82904C11.7686 4.36093 14.0591 3.60376 14.8284 3.72815L13.108 6.70788C13.108 6.70784 13.1079 6.7078 13.1078 6.70776C12.2778 6.22856 11.2165 6.51294 10.7373 7.34295C10.2581 8.17295 10.5425 9.23428 11.3725 9.71348C11.3726 9.71352 11.3726 9.71356 11.3727 9.7136Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86773 15.6687C9.52238 16.2513 8.54197 17.5546 7.38305 18.107C6.29086 18.6276 5.9713 18.9511 5.85739 19.1182L6.07839 18.73L5.85374 19.1146C5.94147 18.9316 6.061 18.4922 5.96598 17.2892C5.86489 16.0093 6.50341 14.5086 6.83531 13.9182L8.32751 14.7797L8.32765 14.7795L9.86773 15.6687ZM5.85374 19.1146L5.78713 19.2287C5.79077 19.2225 5.79526 19.2156 5.80043 19.2077C5.81433 19.1864 5.83316 19.1576 5.85374 19.1146ZM5.85739 19.1182L5.793 19.2313C5.79646 19.2251 5.80012 19.2179 5.80432 19.2096C5.81568 19.1872 5.83102 19.1569 5.85739 19.1182Z"
        fill="white"
      />
    </Svg>
  )
}
