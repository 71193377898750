import { useEventCallback } from 'app/hooks/use-event-callback'
import * as React from 'react'
import { createContext, forwardRef, useCallback } from 'react'
import { type SharedValue, useSharedValue } from 'react-native-reanimated'

import { mixpanel } from './tracking'
import { events } from './tracking/events'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type ViewabilityItemsContextType = any[]

export const ViewabilityItemsContext = createContext<SharedValue<ViewabilityItemsContextType>>({
  value: [],
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  addListener: (_listenerID: number, _listener: (value: any) => void): void => {},
  removeListener: (_listenerID: number): void => {},
  //@ts-expect-error FIX
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  modify: (_modifier: (value: any) => any): void => {},
})

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const ItemKeyContext = createContext<any | undefined>(undefined)

const viewabilityConfig = {
  minimumViewTime: 120,
  itemVisiblePercentThreshold: 70,
}

export function withViewabilityInfiniteScrollList<T>(Component: T): T {
  const ViewabilityInfiniteScrollList = forwardRef(function ViewabilityInfiniteScrollList(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    props: any,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    ref: any
  ) {
    const visibleItems = useSharedValue<ViewabilityItemsContextType>([])
    const currentIndex = React.useRef(0)
    const { renderItem: _renderItem } = props

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const onViewableItemsChanged = useEventCallback(({ viewableItems }: any) => {
      let viewableItem = viewableItems[0]
      if (viewableItems.length > 4) {
        viewableItem = viewableItems[Math.floor(viewableItems.length) / 2]
      }

      if (props.data && viewableItem) {
        const visibleIndex = Number(viewableItem.index)

        const prevIndex = visibleIndex > 0 ? visibleIndex - 1 : undefined
        const nextIndex = visibleIndex < props.data.length ? visibleIndex + 1 : undefined

        const newWindow = [prevIndex, visibleIndex, nextIndex]
        visibleItems.value = newWindow

        if (currentIndex.current !== visibleIndex) {
          mixpanel.track(events.FEED_SWIPE)
          currentIndex.current = visibleIndex
        }
      }
      props.onViewableItemsChanged?.({ viewableItems })
    })

    const renderItem = useCallback(
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (params: any) => {
        return (
          <ItemKeyContext.Provider value={params.index}>
            {_renderItem?.(params)}
          </ItemKeyContext.Provider>
        )
      },

      [_renderItem]
    )

    return (
      <ViewabilityItemsContext.Provider value={visibleItems}>
        {/* @ts-expect-error FIX */}
        <Component
          {...props}
          ref={ref}
          viewabilityConfig={viewabilityConfig}
          onViewableItemsChanged={onViewableItemsChanged}
          renderItem={renderItem}
        />
      </ViewabilityItemsContext.Provider>
    )
  })

  return ViewabilityInfiniteScrollList as unknown as T
}
