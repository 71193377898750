import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function CoinbaseWalletIcon(props: Props) {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none">
      <Path
        d="M2.667 16c0 7.363 5.97 13.333 13.333 13.333S29.333 23.363 29.333 16 23.363 2.666 16 2.666 2.667 8.636 2.667 16zm9.926-4.296h6.814c.493 0 .89.396.89.888v6.815a.887.887 0 01-.89.89h-6.814a.887.887 0 01-.89-.89v-6.815c0-.492.397-.889.89-.889z"
        fill={props.color}
      />
    </Svg>
  )
}
