import { Avatar, Pressable, Text, View } from '@my/ui'
import { Link } from 'expo-router'
import { memo } from 'react'
import { FollowButton } from './follow-button'
import { FollowingButton } from './following-button'
import type { FollowersItem, FollowingItem } from './types'

export const FollowListItem = memo(
  ({
    user,
  }: {
    user: FollowingItem | FollowersItem
  }) => {
    return (
      <View className="flex-1 flex-row gap-3 py-2 px-4 bg-appDarkBg">
        <Link push asChild href={`/user/${user.username}`}>
          <Pressable>
            <Avatar source={user.profileImage} size={37} />
          </Pressable>
        </Link>
        <View className="flex-1 flex-row justify-between items-center">
          <Link push asChild href={`/user/${user.username}`}>
            <Pressable>
              <Text className="text-white font-semibold" numberOfLines={1}>
                @{user.username}
              </Text>
            </Pressable>
          </Link>
          {!user.isFollowedByMe ? (
            <FollowButton
              user={user}
              variant="white"
              className="w-28 rounded-lg"
              classNameText="native:text-sm"
            />
          ) : (
            <FollowingButton
              user={user}
              variant="gray"
              className="w-28 rounded-lg"
              classNameText="native:text-sm"
            />
          )}
        </View>
      </View>
    )
  }
)
