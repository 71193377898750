import { Text, View } from '@my/ui'
import { formatNumberForPrice } from 'app/utils/common'

export const PriceChange = ({
  absoluteChange,
  percentageChange,
}: {
  absoluteChange: number | null
  percentageChange: number | null
}) => {
  if (!absoluteChange || !percentageChange) {
    return <Text className="text-grayTextColor">-.-- (-.--%)</Text>
  }

  const isPositive = percentageChange > 0
  const isNearlyZero = percentageChange > -0.01 && percentageChange < 0.01

  const formattedPercentageChange = isNearlyZero ? '0.00%' : `${percentageChange.toFixed(2)}%`

  const textColor = isPositive ? 'text-secondary' : 'text-primary'
  const caretTranslateY = isPositive ? 6 : -6

  return (
    <View className="flex-row items-center gap-1">
      <View
        className={`w-3 h-3 overflow-hidden items-center justify-center ${isPositive ? 'bottom-1' : 'top-1'}`}>
        <View
          className={`w-2 h-2 ${isPositive ? 'bg-secondary' : 'bg-primary'}`}
          style={{
            transform: [{ translateY: caretTranslateY }, { rotate: '45deg' }],
            borderRadius: 2,
          }}
        />
      </View>
      <Text className={textColor} numberOfLines={1}>
        {formatNumberForPrice(absoluteChange)} ({formattedPercentageChange})
      </Text>
    </View>
  )
}
