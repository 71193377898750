import { Pressable, Text, type ViewStyle, cn } from '@my/ui'
import { fixedForwardRef } from '@my/utils'
import { useEventCallback } from 'app/hooks/use-event-callback'
import * as Haptics from 'expo-haptics'
import { useMemo } from 'react'
import { type GestureResponderEvent, Platform } from 'react-native'

const variantStyles = {
  default: 'bg-[#232323] rounded-lg border-2 border-[#232323]',
  primary: 'bg-primary rounded-lg border-2 border-primary',
  secondary: 'bg-secondary rounded-lg border-2 border-secondary',
  red: 'bg-[#F1354E] rounded-lg border-2 border-[#F1354E]',
  outline: 'border-[#242424] border-2 rounded-lg',
  outlineFilled: 'rounded-lg bg-white border-2 border-white',
  white: 'bg-white rounded-full border-2 border-white',
  dark: 'bg-[#222222] rounded-full border-2 border-[#222222]',
  gray: 'bg-borderShade1 rounded-lg border-2 border-borderShade1',
}

const variantStylesText = {
  default: 'text-white',
  primary: 'text-white',
  secondary: 'text-white',
  red: 'text-black',
  outline: 'text-white',
  outlineFilled: 'text-black',
  white: 'text-black',
  dark: 'text-white',
  gray: 'text-white',
}

export type ButtonProps = {
  icon?: React.ReactElement | null
  iconAfter?: React.ReactElement | null
  onPress?: () => void
  onPressIn?: (event: GestureResponderEvent) => void
  children?: React.ReactNode
  variant?: keyof typeof variantStyles
  style?: ViewStyle
  disabled?: boolean
  className?: string
  classNameText?: string
  scale?: boolean
  haptics?: boolean
  opacity?: boolean
}

export const Button = fixedForwardRef<typeof Pressable, ButtonProps>((props, ref) => {
  const {
    variant,
    children,
    icon,
    style,
    onPress,
    disabled,
    iconAfter,
    className,
    classNameText,
    onPressIn,
    scale = true,
    haptics = true,
    opacity = false,
  } = props

  const defaultStyles = useMemo(
    () =>
      cn(
        variantStyles[variant ?? 'default'],
        'px-2 py-1.5 flex-row gap-1.5 items-center justify-center transition-all duration-200',
        scale ? 'scale-100 active:scale-95' : '',
        opacity ? 'opacity-100 active:opacity-60' : '',
        className
      ),
    [variant, className, scale, opacity]
  )

  const defaultStylesText = useMemo(
    () =>
      cn(
        variantStylesText[variant ?? 'default'],
        'text-xs native:text-base font-bold',
        classNameText
      ),
    [variant, classNameText]
  )

  const onPressInCallback = useEventCallback((event: GestureResponderEvent) => {
    if (onPressIn) {
      onPressIn(event)
    }
    if (haptics && Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium).catch(() => {})
    }
  })

  return (
    <Pressable
      // @ts-expect-error TODO: fix this
      ref={ref as LegacyRef<typeof Pressable>}
      hitSlop={10}
      className={defaultStyles}
      style={style}
      onPress={onPress}
      disabled={disabled}
      onPressIn={onPressInCallback}>
      {icon}
      {typeof children === 'string' ? (
        <Text className={defaultStylesText}>{children}</Text>
      ) : (
        children
      )}
      {iconAfter}
    </Pressable>
  )
})
