import { Button, Text, View } from '@my/ui'
import { useBlockUser } from './hooks/use-block-user'

export const BlockedUserScene = (props: {
  username: string
  userId: string
}) => {
  const { username, userId } = props
  const { unblockUser } = useBlockUser({
    username: username,
  })

  return (
    <View>
      <Text className="text-white text-xl font-medium">@{username} is blocked</Text>
      <View className="mt-4">
        <Button
          variant="outlineFilled"
          onPress={() => {
            unblockUser.mutate({
              userId,
            })
          }}>
          <Text className="text-black font-semibold">Unblock @{username}</Text>
        </Button>
      </View>
    </View>
  )
}
