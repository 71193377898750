import { useLocalSearchParams, usePathname } from 'expo-router'
import {
  Fragment,
  type MutableRefObject,
  forwardRef,
  isValidElement,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'

import type { FlashListProps, ViewToken } from '@shopify/flash-list'
import { type Virtualizer, useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual'
import { useStateSync } from 'app/hooks/batching/use-batched-state'
import { useEventCallback } from 'app/hooks/use-event-callback'
import debounce from 'lodash/debounce'
import { type LayoutChangeEvent, View } from 'react-native'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const measurementsCache: any = {}

const DEFAULT_VIEWABILITY_THRESHOLD_PERCENTAGE = 80
export const CellContainer = Fragment
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const renderComponent = (Component: any) => {
  if (!Component) return null
  if (isValidElement(Component)) return Component
  return <Component />
}
function InfiniteScrollListImpl<Item>(
  props: FlashListProps<Item> & {
    useWindowScroll?: boolean
    preserveScrollPosition?: boolean
    overscan?: number
    containerTw?: string
    scrollPositionKey: string
  },
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ref: any
) {
  const {
    data,
    renderItem,
    extraData,
    onViewableItemsChanged,
    pagingEnabled,
    viewabilityConfig,
    ItemSeparatorComponent,
    estimatedItemSize,
    ListHeaderComponent,
    ListFooterComponent,
    ListEmptyComponent,
    onEndReached,
    initialScrollIndex,
    numColumns = 1,
    overscan,
    style,
    useWindowScroll = true,
    inverted,
    preserveScrollPosition = false,
    containerTw = '',
    scrollPositionKey,
  } = props
  let count = data?.length ?? 0
  if (numColumns) {
    count = Math.ceil(count / numColumns)
  }

  const HeaderComponent = useMemo(() => renderComponent(ListHeaderComponent), [ListHeaderComponent])
  const FooterComponent = useMemo(() => renderComponent(ListFooterComponent), [ListFooterComponent])
  const EmptyComponent = useMemo(() => renderComponent(ListEmptyComponent), [ListEmptyComponent])

  const viewableItems = useRef<ViewToken[]>([])
  const parentRef = useRef<HTMLDivElement | null>(null)
  const scrollMarginOffseRef = useRef<HTMLDivElement>(null)
  const positionWasRestored = useRef<boolean>(false)
  const pathname = usePathname()
  const params = useLocalSearchParams()

  const parentOffsetRef = useRef(0)
  const key = useMemo(() => {
    let temp = `myapp-scroll-restoration-${pathname}-window-scroll-${useWindowScroll}`
    if (params) {
      temp = temp + JSON.stringify(params)
    }
    if (scrollPositionKey) {
      temp = temp + scrollPositionKey
    }
    return temp
  }, [pathname, params, useWindowScroll, scrollPositionKey])

  useLayoutEffect(() => {
    if (useWindowScroll) {
      parentOffsetRef.current = scrollMarginOffseRef.current?.getBoundingClientRect()?.top ?? 0
    } else {
      parentOffsetRef.current = scrollMarginOffseRef.current?.offsetTop ?? 0
    }
  }, [useWindowScroll])
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let rowVirtualizer: Virtualizer<any, any>
  const config = {
    count,
    estimateSize: () => estimatedItemSize ?? 0,
    scrollMargin: parentOffsetRef.current,
    overscan: overscan ?? 2,
    initialOffset: (() => {
      if (initialScrollIndex && estimatedItemSize) {
        return initialScrollIndex * estimatedItemSize
      }
      if (preserveScrollPosition && !positionWasRestored.current) {
        positionWasRestored.current = true
        const pos = sessionStorage.getItem(key)

        if (pos) {
          const parsedPos = Number(pos)
          return parsedPos
        }
        return 0
      }
    })(),
    initialMeasurementsCache: measurementsCache[key],
  }

  if (useWindowScroll) {
    // biome-ignore lint/correctness/useHookAtTopLevel: It's hacky, but needed for the current implementation
    rowVirtualizer = useWindowVirtualizer(config)
  } else {
    // biome-ignore lint/correctness/useHookAtTopLevel: It's hacky, but needed for the current implementation
    rowVirtualizer = useVirtualizer({
      ...config,
      getScrollElement: () => parentRef.current,
    })
  }

  const renderedItems = rowVirtualizer.getVirtualItems()

  const saveScrollPosition = useEventCallback(() => {
    try {
      sessionStorage.setItem(key, rowVirtualizer.scrollOffset?.toString() ?? '0')
    } catch {
      // ignore, could likely be a QuotaExceededError
    }
    measurementsCache[key] = rowVirtualizer.measurementsCache
  })

  const saveWhenIdle = useEventCallback(
    debounce(
      () => {
        if (positionWasRestored.current) {
          if ('requestIdleCallback' in window) {
            window.requestIdleCallback(saveScrollPosition)
          } else {
            saveScrollPosition()
          }
        }
      },
      100,
      { leading: false }
    )
  )

  const transformStyle = inverted ? { transform: 'scaleY(-1)' } : {}

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault()
      const currentTarget = e.currentTarget as HTMLElement

      if (currentTarget) {
        currentTarget.scrollTop -= e.deltaY
      }
    }
    if (inverted) {
      parentRef.current?.addEventListener('wheel', handleScroll, {
        passive: false,
      })
    }

    return () => {
      if (inverted) {
        parentRef.current?.removeEventListener('wheel', handleScroll)
      }
    }
  }, [inverted])

  useEffect(() => {
    if (preserveScrollPosition) {
      if (useWindowScroll) {
        window.addEventListener('scroll', saveWhenIdle, {
          passive: true,
        })
      } else {
        rowVirtualizer.scrollElement?.addEventListener('scroll', saveWhenIdle, {
          passive: true,
        })
      }
    }
    return () => {
      if (preserveScrollPosition) {
        if (useWindowScroll) {
          window.removeEventListener('scroll', saveWhenIdle, {
            // @ts-expect-error - Use the same options - https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#matching_event_listeners_for_removal
            passive: true,
          })
        } else {
          rowVirtualizer.scrollElement?.removeEventListener('scroll', saveWhenIdle, {
            passive: true,
          })
        }
      }
    }
  }, [rowVirtualizer.scrollElement, saveWhenIdle, preserveScrollPosition, useWindowScroll])

  useLayoutEffect(() => {
    // In very rare cases, the scroll position is not restored correctly by initialOffset of react-virtual.
    // So we need to manually restore it here.
    // Only setTimeout works reliably
    setTimeout(() => {
      if (preserveScrollPosition) {
        const pos = sessionStorage.getItem(key)
        if (pos) {
          const parsedPos = Number(pos)
          if (parsedPos > 0 && rowVirtualizer.scrollElement) {
            rowVirtualizer.scrollElement.scrollTo(0, parsedPos)
          }
        }
      }
    }, 16)
  }, [key, preserveScrollPosition, rowVirtualizer.scrollElement])

  return (
    <Fragment>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          ...transformStyle,
        }}>
        <div
          ref={(v) => {
            parentRef.current = v
            if (ref) {
              ref.current = v
            }
          }}
          className={containerTw}
          style={
            !useWindowScroll
              ? {
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  scrollbarGutter: 'stable',
                  scrollbarWidth: 'thin',
                  contain: 'strict',
                  flexGrow: 1,
                  ...(style as object),
                  scrollSnapType: pagingEnabled ? 'y mandatory' : undefined,
                }
              : {}
          }>
          <div style={transformStyle}>{HeaderComponent}</div>
          <div
            ref={scrollMarginOffseRef}
            style={{
              height:
                rowVirtualizer.getTotalSize() === 0
                  ? undefined
                  : rowVirtualizer.getTotalSize() - (useWindowScroll ? 0 : parentOffsetRef.current),
              width: '100%',
              position: 'relative',
              flex: 1,
            }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                minHeight: rowVirtualizer.getTotalSize() === 0 ? '100%' : undefined,
                transform: `translateY(${
                  renderedItems[0]
                    ? renderedItems[0].start - rowVirtualizer.options.scrollMargin
                    : 0
                }px)`,
              }}>
              {data?.length === 0 && EmptyComponent ? (
                <div
                  style={{
                    height: '100%',
                    position: 'absolute',
                    inset: 0,
                    ...transformStyle,
                  }}>
                  {EmptyComponent}
                </div>
              ) : null}
              {renderedItems.map((virtualItem) => {
                const index = virtualItem.index
                const chuckItem = data?.slice(index * numColumns, index * numColumns + numColumns)
                return (
                  <div
                    key={virtualItem.key}
                    data-index={index}
                    ref={rowVirtualizer.measureElement}
                    style={{
                      width: '100%',
                      ...transformStyle,
                      scrollSnapAlign: pagingEnabled ? 'start' : undefined,
                      scrollSnapStop: pagingEnabled ? 'always' : undefined,
                    }}>
                    {typeof data?.[index] !== 'undefined' ? (
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}>
                        {chuckItem?.map((item, i) => {
                          const realIndex = index * numColumns + i

                          return (
                            <ViewabilityTracker
                              key={realIndex}
                              index={realIndex}
                              totalItems={data.length}
                              itemVisiblePercentThreshold={
                                viewabilityConfig?.itemVisiblePercentThreshold ??
                                DEFAULT_VIEWABILITY_THRESHOLD_PERCENTAGE
                              }
                              onEndReached={onEndReached}
                              item={data[realIndex]}
                              viewableItems={viewableItems}
                              onViewableItemsChanged={onViewableItemsChanged}>
                              {renderItem?.({
                                index: realIndex,
                                item,
                                extraData,
                                target: 'Cell',
                              }) ?? null}
                              {realIndex < data.length - 1 &&
                                renderComponent(ItemSeparatorComponent)}
                            </ViewabilityTracker>
                          )
                        })}
                        {chuckItem &&
                          chuckItem?.length < numColumns &&
                          new Array(numColumns - chuckItem?.length).fill(0).map((_, itemIndex) => (
                            <div
                              key={`${
                                index * numColumns + itemIndex + (numColumns - chuckItem?.length)
                              }`}
                              style={{
                                width: '100%',
                              }}
                            />
                          ))}
                      </div>
                    ) : null}
                  </div>
                )
              })}
              {!useWindowScroll && FooterComponent ? (
                <div style={transformStyle}>{FooterComponent}</div>
              ) : null}
            </div>
          </div>

          {useWindowScroll && FooterComponent}
        </div>
      </div>
    </Fragment>
  )
}

const ViewabilityTracker = ({
  index,
  item,
  children,
  onViewableItemsChanged,
  viewableItems,
  itemVisiblePercentThreshold,
  onEndReached,
  totalItems,
  ...rest
}: {
  index: number
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  item: any
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  children: any
  totalItems: number
  onEndReached?: null | (() => void)
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onViewableItemsChanged: FlashListProps<any>['onViewableItemsChanged']
  viewableItems: MutableRefObject<ViewToken[]>
  itemVisiblePercentThreshold: number
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  // Viewability callbacks
  useEffect(() => {
    let observer: IntersectionObserver
    if (onViewableItemsChanged) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            if (viewableItems.current.findIndex((v) => v.index === index) === -1)
              viewableItems.current.push({
                item,
                index,
                isViewable: true,
                key: index.toString(),
                timestamp: new Date().valueOf(),
              })
          } else {
            viewableItems.current = viewableItems.current.filter((v) => v.index !== index)
          }

          viewableItems.current = viewableItems.current.sort(
            (a, b) =>
              //@ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              a.index - b.index
          )

          onViewableItemsChanged?.({
            viewableItems: viewableItems.current,

            // TODO: implement changed
            changed: [],
          })
        },

        {
          // will trigger intersection callback when item is 70% visible
          threshold: itemVisiblePercentThreshold / 100,
        }
      )

      if (ref.current) observer.observe(ref.current)
    }

    return () => {
      observer?.disconnect()
      viewableItems.current = viewableItems.current.filter((v) => v.index !== index)
    }
  }, [onViewableItemsChanged, viewableItems, index, item, itemVisiblePercentThreshold])

  // End reached callback
  useEffect(() => {
    let observer: IntersectionObserver
    if (onEndReached) {
      observer = new IntersectionObserver(
        ([entry]) => {
          // Fetch next as soon as 1 pixel of the last item is visible
          if (entry?.isIntersecting && index === totalItems - 1) {
            onEndReached?.()
          }
        },
        {
          threshold: 0,
        }
      )
      if (ref.current) observer.observe(ref.current)
    }

    return () => {
      observer?.disconnect()
    }
  }, [onEndReached, index, totalItems])

  return (
    <div style={{ width: '100%' }} ref={ref} {...rest}>
      {children}
    </div>
  )
}

function withUnmountListWhenParentIsDisplayNone<T>(Component: T) {
  const UnmountListWhenParentIsDisplayNone = forwardRef(function UnmountListWhenParentIsDisplayNone(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    props: any,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    ref: any
  ) {
    const [mount, setMount] = useStateSync(true)
    const handleOnLayout = useEventCallback((e: LayoutChangeEvent) => {
      if (e.nativeEvent.layout.height > 0) {
        setMount(true)
      } else {
        setMount(false)
      }
    })

    return (
      <Fragment>
        {/* @ts-expect-error - Time consuming fix, doesn't matter. Right fix is in expo router. I will try some ideas later. */}
        {mount ? <Component {...props} ref={ref} /> : null}
        <View
          onLayout={handleOnLayout}
          style={{ height: 10, position: 'absolute', zIndex: -99999 }}
          pointerEvents="none"
        />
      </Fragment>
    )
  })

  return UnmountListWhenParentIsDisplayNone as T
}

const InfiniteScrollList = withUnmountListWhenParentIsDisplayNone(
  memo(forwardRef(InfiniteScrollListImpl))
)

export { InfiniteScrollList }
