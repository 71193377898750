import Ionicons from '@expo/vector-icons/Ionicons'
import { LeanView, Text, theme } from '@my/ui'

export const PercentageChange = ({ value }: { value: number | null }) => {
  if (!value) {
    return <Text className="text-grayTextColor">-.--%</Text>
  }
  const isPositive = value > 0
  const isNearlyZero = value > -0.01 && value < 0.01

  if (isNearlyZero) {
    return (
      <Text className="text-grayTextColor" numberOfLines={1}>
        0.00%
      </Text>
    )
  }

  if (isPositive) {
    return (
      <LeanView className="flex-row items-center gap-1">
        <Ionicons name="caret-up" color={theme.colors.secondary} size={12} />
        <Text className="text-secondary" numberOfLines={1}>
          {value.toFixed(2)}%
        </Text>
      </LeanView>
    )
  }

  return (
    <LeanView className="flex-row items-center gap-1">
      <Ionicons name="caret-down" color={theme.colors.primary} size={12} />
      <Text className="text-primary" numberOfLines={1}>
        {value.toFixed(2)}%
      </Text>
    </LeanView>
  )
}
