import { View } from '@my/ui'
import { PROFILE_PIC_FALLBACK_URL } from '@my/utils'
import { useEventCallback } from 'app/hooks/use-event-callback'
import * as Haptics from 'expo-haptics'
import { Image } from 'expo-image'
import { type Href, Link } from 'expo-router'
import { memo } from 'react'
import { Platform, StyleSheet } from 'react-native'

type AvatarProps = {
  size?: number
  source?: string | null
  href?: LinkProps['href']
  asChild?: boolean
  border?: boolean
  label?: string
}

type LinkProps = {
  href?: Href
  asChild?: boolean
  label?: string
}

const LinkComponent: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  href,
  asChild,
  label,
}) => {
  const onPressIn = useEventCallback(() => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium).catch(() => {})
    }
  })

  return href ? (
    <Link
      href={href}
      asChild={Platform.OS === 'web' && asChild}
      aria-label={label}
      onPressIn={onPressIn}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

export const Avatar = memo(
  ({ size = 24, source, href, asChild = false, border, label }: AvatarProps) => {
    const imageSource = source ?? PROFILE_PIC_FALLBACK_URL

    return (
      <LinkComponent href={href} asChild={asChild} label={label ?? 'Go to profile'}>
        <View
          style={{ width: size, height: size, borderWidth: border ? 2 : 0 }}
          className="rounded-full overflow-hidden bg-zinc-700 border-white/50">
          <Image
            recyclingKey={imageSource}
            source={{
              uri: `${imageSource}?class=${size > 99 ? 'avatarprofile' : 'avatar'}`,
              width: size * 2,
              height: size * 2,
            }}
            style={styles.image}
            contentFit="cover"
            alt=""
            transition={100}
            priority="high"
          />
        </View>
      </LinkComponent>
    )
  }
)

Avatar.displayName = 'Avatar'

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 9999,
    overflow: 'hidden',
  },
})
