import Ionicons from '@expo/vector-icons/Ionicons'
import type { RouterOutputs } from '@my/api'
import { Avatar, CrownIcon, LeanText, LeanView, Pressable, theme } from '@my/ui'
import { trpc } from 'app/utils/api'
import { Image } from 'expo-image'
import { Link } from 'expo-router'
import { useImperativeHandle, useMemo } from 'react'
import { Text } from 'react-native'

type TrendingCreator = RouterOutputs['creatorToken']['trendingV2']['data'][number]

export const TrendingCreators = ({
  refreshRef,
}: { refreshRef: React.RefObject<() => Promise<unknown> | null> }) => {
  const [data, query] = trpc.creatorToken.trendingV2.useSuspenseInfiniteQuery(
    { pageSize: 5, interval: 'all_time' },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  )

  const creators = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data?.pages])

  useImperativeHandle(refreshRef, () => query.refetch)

  return (
    <LeanView className="px-4 gap-6">
      <LeanView className="bg-surfaceDarkShade1 rounded-2xl px-3 py-5">
        <Link href="/trending-creators" asChild>
          <Pressable className="flex-row items-center gap-2 px-2 pb-4">
            <LeanView className="w-5 h-5 items-center justify-center">
              <CrownIcon width={17} height={15} />
            </LeanView>
            <Text className="text-white text-2xl font-semibold text-center">Trending creators</Text>
            <Ionicons name="chevron-forward" color={theme.colors.grayTextColor} size={18} />
          </Pressable>
        </Link>
        <LeanView>
          {creators.map((creator, index) => (
            <TrendingCreator key={index} index={index} item={creator} />
          ))}
        </LeanView>
      </LeanView>
    </LeanView>
  )
}

export const TrendingCreator = ({ item, index }: { item: TrendingCreator; index: number }) => {
  return (
    <LeanView className="flex-1 flex-row items-center w-full py-2">
      <LeanView className="w-12 h-full items-center justify-center">
        <Text className="text-grayTextColor4 text-xs font-bold">{index + 1}</Text>
      </LeanView>
      <LeanView className="flex-row items-center gap-2 flex-1">
        <Avatar
          source={item?.userDetails?.profileImage}
          href={`/user/${item.userDetails?.username}`}
          size={34}
          asChild
        />
        {item.userDetails?.farcaster ? (
          <Link href={`/user/${item.userDetails?.username}`} className="flex">
            <LeanView className="flex-row gap-1 items-center rounded-full max-w-[100%]">
              <Image
                source={require('@my/ui/src/icons/farcaster-purple-white.svg')}
                style={{ width: 14, height: 14, borderRadius: 9999 }}
                alt={`${item.userDetails?.username} Farcaster`}
              />
              <LeanText className="text-white min-h-5 font-semibold" numberOfLines={1}>
                @{item.userDetails.farcaster}
              </LeanText>
            </LeanView>
          </Link>
        ) : (
          <Link
            href={`/user/${item.userDetails?.username}`}
            className="text-white font-semibold max-w-[100%]"
            numberOfLines={1}>
            @{item.userDetails?.username}
          </Link>
        )}
      </LeanView>
    </LeanView>
  )
}
