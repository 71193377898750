import { createContext, useContext } from 'react'
import type { Account, Address, Chain, WalletClient } from 'viem'
import type { WalletActionsEip5792 } from 'viem/experimental'

export type WalletClientType = WalletClient & WalletActionsEip5792<Chain, Account>

export const CoinbaseSmartWalletProviderContext = createContext({
  address: undefined as Address | undefined,
  handleConnect: (() => {}) as () => Promise<`0x${string}` | undefined>,
  handleDisconnect: () => {},
  isConnected: false,
})

export const useCoinbaseSmartWallet = () => {
  return useContext(CoinbaseSmartWalletProviderContext)
}
