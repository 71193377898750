import { type ForwardedRef, forwardRef } from 'react'
import { ScrollView, type ScrollViewProps } from 'react-native'

import Animated from 'react-native-reanimated'

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView)

export const LazyScrollView = forwardRef(function LazyScrollView(
  props: ScrollViewProps,
  ref: ForwardedRef<ScrollView>
): JSX.Element {
  return <AnimatedScrollView scrollEventThrottle={8} {...props} ref={ref} />
})
