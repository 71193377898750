import { trpc } from 'app/utils/api'
import { logError } from '../../../utils/logging/logError'
import type { User } from '../types'

export const useFollow = ({
  user,
}: {
  user: User
}) => {
  return trpc.follow.follow.useMutation({
    onMutate: () => ({
      optimisticData: {
        id: user.id,
        username: user.username,
        isFollowedByMe: true,
        ...(typeof user.followersCount === 'number' && { followersCount: user.followersCount + 1 }),
      },
      rollbackData: {
        id: user.id,
        username: user.username,
        isFollowedByMe: false,
        ...(typeof user.followersCount === 'number' && { followersCount: user.followersCount }),
      },
    }),
    onError: (error, params) => {
      logError({
        action: 'FOLLOW',
        message: 'Failed to follow user',
        error,
        type: 'TAP',
        level: 'error',
        indexedTags: {
          userId: params.userId,
        },
      })
    },
  })
}
