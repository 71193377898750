import Svg, { type SvgProps, Path } from 'react-native-svg'

export const VideoFilmIcon = ({ color = '#fff', ...props }: SvgProps & { color?: string }) => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M2.89 1.818c-.592 0-1.072.48-1.072 1.073v1.654h2.727V1.818zM2.89 0A2.89 2.89 0 0 0 0 2.89v14.22A2.89 2.89 0 0 0 2.89 20h14.22A2.89 2.89 0 0 0 20 17.11V2.89A2.89 2.89 0 0 0 17.11 0zm3.474 1.818v7.273h7.272V1.818zm9.09 0v2.727h2.728V2.891c0-.593-.48-1.073-1.073-1.073zm2.728 4.546h-2.727V9.09h2.727zm0 4.545h-2.727v2.727h2.727zm0 4.545h-2.727v2.728h1.654c.592 0 1.073-.48 1.073-1.073zm-4.546 2.728v-7.273H6.364v7.273zm-9.09 0v-2.727H1.817v1.654c0 .592.48 1.073 1.073 1.073zm-2.728-4.546h2.727V10.91H1.818zm0-4.545h2.727V6.364H1.818z"
      clipRule="evenodd"
    />
  </Svg>
)
