import { Avatar, CollapsibleText, LeanText, LeanView } from '@my/ui'
import { Link, useLocalSearchParams } from 'expo-router'
import { memo, useRef } from 'react'
import { Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { TokenDetailsModal } from '../token/token-details-modal'
import { EngagementButtons } from './engagement-buttons'
import { FeedItemGradient } from './feed-item-gradient'
import { TransactionAnimation } from './transaction-animation/transaction-animation'
import type { MuteRef, TogglePauseRef, VideoFeedItemProps, VideoVisibilityRef } from './types'
import { VideoFeedTokenDetails } from './video-feed-token-details'
import { VideoFeedHeader } from './video-feed-video/video-feed-header'
import { FeedVideo } from './video-feed-video/video-feed-video'
import { VideoGestureContainer } from './video-gesture-container'

export const MobileVideoFeedItem = memo((props: VideoFeedItemProps) => {
  const searchParams = useLocalSearchParams<{ expand: 'trade' }>()
  const insets = useSafeAreaInsets()
  const postItem = props.item
  const author = postItem.user

  const media = postItem.media
  const mediaUrl =
    media?.mp4_720 ?? media?.mp4_480 ?? media?.mp4_360 ?? media?.mp4_240 ?? media?.originalUrl

  const muteRef = useRef<MuteRef>(null)
  const togglePauseRef = useRef<TogglePauseRef>(null)
  const videoVisibilityRef = useRef<VideoVisibilityRef>(null)

  if (!media || !mediaUrl) return null

  return (
    <>
      <LeanView
        className="web:!touch-manipulation"
        // @ts-expect-error calc is valid css on web, types are borked
        style={Platform.select({
          web: {
            maxHeight: `100svh`,
          },
          default: {
            width: props.availableScreenWidth,
            height: props.availableScreenHeight,
          },
        })}
      >
        <VideoFeedHeader muteRef={muteRef} />

        <VideoGestureContainer post={postItem} togglePauseRef={togglePauseRef}>
          <FeedVideo
            uri={mediaUrl}
            availableScreenWidth={props.availableScreenWidth}
            availableScreenHeight={props.availableScreenHeight}
            videoWidth={media.width ?? props.availableScreenWidth}
            videoHeight={media.height ?? props.availableScreenHeight}
            postId={props.item.post.id}
            muteRef={muteRef}
            togglePauseRef={togglePauseRef}
            videoVisibilityRef={videoVisibilityRef}
          />
        </VideoGestureContainer>

        <LeanView
          className="absolute bottom-0 left-0 w-full"
          pointerEvents="box-none"
          style={{
            paddingBottom: Math.max(insets.bottom, 16),
          }}>
          <FeedItemGradient />
          <LeanView className="p-4 pb-8 flex-row gap-4 items-end" pointerEvents="box-none">
            <LeanView className="gap-2 flex-[6] justify-end" pointerEvents="box-none">
              {props.item.post.tokenDetails ? (
                <LeanView pointerEvents="box-none" className="gap-3 flex-1 justify-end">
                  <TransactionAnimation
                    key={props.item.post.tokenDetails.address}
                    address={props.item.post.tokenDetails.address as `0x${string}`}
                  />
                  <VideoFeedTokenDetails
                    postId={props.item.post.id}
                    username={author.username}
                    token={props.item.post.tokenDetails}
                  />
                </LeanView>
              ) : (
                <Link href={`/user/${author.username}`}>
                  <LeanText
                    className="text-white font-bold max-w-[120px] text-ellipsis"
                    numberOfLines={1}>
                    {props.item.user.name ? props.item.user.name : props.item.user.username}
                  </LeanText>
                </Link>
              )}
              <CollapsibleText initialNumberOfLines={2} className="text-white">
                {postItem.post.description}
              </CollapsibleText>
            </LeanView>
            <LeanView className="flex-[2] gap-6 items-end" pointerEvents="box-none">
              <Avatar
                source={author?.profileImage}
                size={44}
                href={author?.username ? `/user/${author.username}` : undefined}
                border
              />
              <EngagementButtons item={postItem} />
            </LeanView>
          </LeanView>
        </LeanView>
      </LeanView>
      {searchParams.expand === 'trade' && videoVisibilityRef.current?.isItemTrulyVisible() ? (
        <TokenDetailsModal item={props.item} />
      ) : null}
    </>
  )
})

MobileVideoFeedItem.displayName = 'MobileVideoFeedItem'
