import { InfiniteScrollList, Text, View } from '@my/ui'
import { withViewabilityInfiniteScrollList } from 'app/utils/with-viewability-infinite-scroll-list'
import { useCallback } from 'react'
import { ActivityIndicator, useWindowDimensions } from 'react-native'
import { DesktopVideoFeedItem } from './desktop-video-feed-item'
import type { VideoFeedItemProps, VideoFeedListProps } from './types'

const ViewabilityInfiniteScrollList = withViewabilityInfiniteScrollList(InfiniteScrollList)

export const DesktopVideoFeedList = (props: VideoFeedListProps) => {
  const dimensions = useWindowDimensions()
  const videoHeight = dimensions.height - 120
  const videoWidth = (9 / 16) * videoHeight

  const renderItem = useCallback(
    ({ item }: { item: VideoFeedItemProps['item'] }) => {
      return (
        <DesktopVideoFeedItem
          item={item}
          availableScreenHeight={videoHeight}
          availableScreenWidth={videoWidth}
        />
      )
    },
    [videoHeight, videoWidth]
  )
  const EmptyComponent = useCallback(() => {
    return (
      <View className="flex-[1] items-center justify-center" style={{ height: videoHeight }}>
        <Text className="text-lg text-white">No more posts</Text>
      </View>
    )
  }, [videoHeight])

  const ListFooterComponent = useCallback(() => {
    return props.loadingMore ? (
      <View className="flex-row items-center justify-center h-12 p-4">
        <ActivityIndicator size="small" color="red" />
      </View>
    ) : (
      <View className="h-12 p-4" />
    )
  }, [props.loadingMore])

  if (props.loading) {
    return (
      <View
        className="animate-pulse bg-borderShade1 rounded-2xl mx-auto"
        style={{ width: videoWidth, height: videoHeight }}
      />
    )
  }

  return (
    <ViewabilityInfiniteScrollList
      useWindowScroll={false}
      initialScrollIndex={props.initialScrollIndex}
      data={props.data}
      preserveScrollPosition
      onEndReached={props.onEndReached}
      onEndReachedThreshold={0.3}
      estimatedItemSize={videoHeight}
      renderItem={renderItem}
      ItemSeparatorComponent={() => <View className="h-6" />}
      ListEmptyComponent={EmptyComponent}
      ListFooterComponent={ListFooterComponent}
      onViewableItemsChanged={props.onViewableItemsChanged}
      overscan={1}
      pagingEnabled
    />
  )
}
