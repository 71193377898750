import { fixedForwardRef } from '@my/utils'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { Image } from 'expo-image'
import { VideoView, useVideoPlayer } from 'expo-video'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Dimensions, type LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useDerivedValue,
  runOnJS,
  withTiming,
} from 'react-native-reanimated'
import { Button } from '../button'
import type { ThumbnailSliderProps } from './types'
import { clamp, formatTime } from './utils'

// Create a fixed Expo Image component with a View wrapper to fix a bug
const FixExpoImage = fixedForwardRef<View>((props, ref) => (
  <View ref={ref} {...props}>
    <Image {...props} style={{ width: '100%', height: '100%' }} />
  </View>
))

// Create an animated version of the fixed Expo Image component
const AnimatedImage = Animated.createAnimatedComponent(FixExpoImage) as unknown as typeof Image

const gap = 0.5
const SCRUBBER_PADDING = 20

export const ThumbnailSlider = ({
  numThumbnails = 12,
  source,
  onSave,
  initialTime = 0,
  duration,
}: ThumbnailSliderProps) => {
  const player = useVideoPlayer(source, (instance) => {
    instance.muted = true
    instance.volume = 0
  })

  const previewPlayer = useVideoPlayer(source, (instance) => {
    instance.muted = true
    instance.volume = 0
  })

  useEffect(() => {
    if (player && previewPlayer) {
      requestAnimationFrame(() => {
        player.currentTime = initialTime > 0 ? initialTime : initialTime + 0.00001
        previewPlayer.currentTime = initialTime > 0 ? initialTime : initialTime + 0.00001
      })
    }
  }, [initialTime, player, previewPlayer])

  const [thumbnails, setThumbnails] = useState(Array(numThumbnails).fill(null))
  const [currentTime, setCurrentTime] = useState(0)

  const videoRef = useRef<HTMLVideoElement>(null)

  const timelineCanvasRef = useRef<HTMLCanvasElement>(null)
  const thumbnailsContainerRef = useRef<View>(null)

  const translationX = useSharedValue(0)
  const prevTranslationX = useSharedValue(0)
  const containerWidth = useSharedValue(0)
  const scrubberWidth = useSharedValue(0)

  const thumbnailWidth = useDerivedValue(
    () => (containerWidth.value - (numThumbnails - 1) * gap - 2 * SCRUBBER_PADDING) / numThumbnails,
    [containerWidth, numThumbnails]
  )
  const thumbnailHeight = useDerivedValue(() => (thumbnailWidth.value * 16) / 9, [thumbnailWidth])

  // Capture thumbnails from the video
  const captureThumbnails = useCallback(
    async (url: string, initialTime = 0) => {
      if (!videoRef.current) return

      const video = videoRef.current
      video.src = url

      setCurrentTime(initialTime)

      const interval = duration / (numThumbnails - 1)
      const thumbnailUrls = Array(numThumbnails).fill(null)

      for (let i = 0; i < numThumbnails; i++) {
        await captureFrameAtTime(i * interval, thumbnailUrls, i)
      }

      setThumbnails(thumbnailUrls)
    },
    [numThumbnails, duration]
  )

  const drawVideoFrameToCanvas = useEventCallback(
    (video: HTMLVideoElement, canvas: HTMLCanvasElement) => {
      // Set canvas dimensions to match video dimensions exactly
      canvas.width = video.videoWidth / 4
      canvas.height = video.videoHeight / 4

      const context = canvas.getContext('2d')
      if (!context) return

      // Draw the entire video frame onto the canvas without any scaling
      context.drawImage(video, 0, 0, video.videoWidth / 4, video.videoHeight / 4)
    }
  )

  // Capture a frame at a specific time
  const captureFrameAtTime = useEventCallback(
    async (time: number, thumbnailUrls: string[], index: number) => {
      if (!videoRef.current) return

      const video = videoRef.current
      video.currentTime = clamp(time, 0.00000001, duration)

      await new Promise((resolve, reject) => {
        video.onseeked = () => {
          const canvas = timelineCanvasRef.current
          if (!canvas) return

          const context = canvas.getContext('2d')
          if (!context) return

          context.clearRect(0, 0, canvas.width, canvas.height)

          drawVideoFrameToCanvas(video, canvas)

          const thumbnailUrl = canvas.toDataURL('image/jpeg')
          if (!thumbnailUrl) {
            reject(new Error('Failed to create thumbnail blob'))
          }

          thumbnailUrls[index] = thumbnailUrl
          setThumbnails([...thumbnailUrls])
          resolve('ok')

          /*
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error('Failed to create thumbnail blob'))
                return
              }
              const thumbnailUrl = URL.createObjectURL(blob)
              thumbnailUrls[index] = thumbnailUrl
              setThumbnails([...thumbnailUrls])
              resolve('ok')
            },
            'image/jpeg',
            0.7
          )*/
        }
      })
    }
  )

  // Update the selected thumbnail
  const updateSelectedThumbnail = useEventCallback(() => {
    onSave(player.currentTime)
  })

  // Gesture handlers
  const panGesture = Gesture.Pan()
    .minDistance(1)
    .onStart(() => {
      prevTranslationX.value = translationX.value
    })
    .activeCursor('grabbing')
    .onUpdate((event) => {
      if (!videoRef.current) return

      const maxTranslateX = containerWidth.value - scrubberWidth.value - 2 * SCRUBBER_PADDING
      translationX.value = clamp(prevTranslationX.value + event.translationX, 0, maxTranslateX)

      const newTime = (translationX.value / maxTranslateX) * duration

      player.currentTime = clamp(newTime, 0.0001, duration)
      previewPlayer.currentTime = player.currentTime

      runOnJS(setCurrentTime)(newTime)
    })

  const tapGesture = Gesture.Tap().onEnd((event) => {
    if (!thumbnailsContainerRef.current) return

    thumbnailsContainerRef.current.measure((_x, _y, _width, _height, pageX) => {
      if (!videoRef.current) return

      const maxTranslateX = containerWidth.value - scrubberWidth.value - 2 * SCRUBBER_PADDING
      const tapX = event.absoluteX - pageX - SCRUBBER_PADDING
      const newTranslationX = clamp(tapX - scrubberWidth.value / 2, 0, maxTranslateX)
      const newTime = (newTranslationX / maxTranslateX) * duration

      player.currentTime = clamp(newTime, 0.0001, duration)
      previewPlayer.currentTime = player.currentTime

      translationX.value = withTiming(newTranslationX, { duration: 200 })
      runOnJS(setCurrentTime)(newTime)
    })
  })

  const setInitialTime = useEventCallback(() => {
    // Set the initial time and translation x based on the initial time
    const maxTranslateX = containerWidth.value - scrubberWidth.value
    const newTranslationX = (initialTime / duration) * maxTranslateX
    const clampedTranslationX = clamp(newTranslationX, 0, maxTranslateX)

    translationX.value = clampedTranslationX
  })

  // Handle layout changes
  const handleLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout
    containerWidth.value = width
  }

  const handleScrubberLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout
    scrubberWidth.value = width

    requestAnimationFrame(setInitialTime)
  }

  // Animated styles
  const animatedStyle = useAnimatedStyle(() => {
    const maxTranslateX = containerWidth.value - scrubberWidth.value - 2 * SCRUBBER_PADDING
    return {
      transform: [{ translateX: clamp(translationX.value, 0, maxTranslateX) + SCRUBBER_PADDING }],
    }
  }, [containerWidth, scrubberWidth])

  const animatedThumbnailStyle = useAnimatedStyle(
    () => ({
      width: thumbnailWidth.value,
      height: thumbnailHeight.value,
    }),
    [thumbnailWidth, thumbnailHeight]
  )

  const animatedScrubberStyle = useAnimatedStyle(
    () => ({
      top: -(thumbnailWidth.value - (thumbnailWidth.value * 1.4) / 2),
      width: thumbnailWidth.value * 1.6,
      height: thumbnailHeight.value * 1.4,
    }),
    [thumbnailWidth, thumbnailHeight]
  )

  // Load video and capture thumbnails on source change
  useEffect(() => {
    if (!source) return

    captureThumbnails(source, initialTime)
  }, [source, captureThumbnails, initialTime])

  return (
    <>
      <View style={styles.previewContainer}>
        <VideoView
          player={player}
          style={styles.previewImage}
          contentFit="contain"
          allowsVideoFrameAnalysis={false}
          allowsFullscreen={false}
          nativeControls={false}
        />
      </View>
      <View onLayout={handleLayout} style={styles.mainContainer}>
        {source && (
          <View style={styles.container} ref={thumbnailsContainerRef}>
            <GestureDetector gesture={tapGesture}>
              <View style={styles.thumbnailsContainer}>
                {thumbnails.map((thumbnail, index) => (
                  <AnimatedImage
                    key={index}
                    source={{ uri: thumbnail }}
                    style={[styles.thumbnail, animatedThumbnailStyle]}
                    contentFit="cover"
                    transition={150}
                  />
                ))}
              </View>
            </GestureDetector>
            <GestureDetector gesture={panGesture}>
              <Animated.View
                style={[styles.scrubber, animatedStyle]}
                onLayout={handleScrubberLayout}>
                <Animated.View style={[animatedScrubberStyle, styles.scrubberHandle]}>
                  <VideoView
                    player={previewPlayer}
                    style={styles.videoPreview}
                    contentFit="cover"
                    nativeControls={false}
                    allowsFullscreen={false}
                    allowsVideoFrameAnalysis={false}
                  />
                </Animated.View>
                <View style={styles.preview}>
                  <Text style={styles.previewText}>{formatTime(currentTime)}</Text>
                </View>
              </Animated.View>
            </GestureDetector>
          </View>
        )}
        <video ref={videoRef} style={styles.hiddenVideo} muted playsInline preload="auto" />
        <canvas ref={timelineCanvasRef} width="100" height="177" style={styles.hiddenCanvas} />
      </View>
      <View className="mt-20 px-6">
        <Button
          onPress={updateSelectedThumbnail}
          variant="white"
          className="opacity-100 active:opacity-50">
          <Text className="text-black font-bold text-lg">Save</Text>
        </Button>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  previewContainer: {
    textAlign: 'center',
    marginHorizontal: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    backgroundColor: 'transparent',
    height: Dimensions.get('window').height * (9 / 16) - 80,
  },
  previewImage: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  mainContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    marginTop: 40,
    userSelect: 'none',
    paddingHorizontal: SCRUBBER_PADDING,
  },
  thumbnailsContainer: {
    flexDirection: 'row',
    opacity: 0.6,
    borderRadius: 8,
    overflow: 'hidden',
    gap: gap,
    userSelect: 'none',
  },
  thumbnail: {
    backgroundColor: 'lightgrey',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  scrubber: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  scrubberHandle: {
    backgroundColor: 'black',
    borderRadius: 12,
    overflow: 'hidden',
    borderWidth: 4,
    borderColor: 'red',
  },
  videoPreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  preview: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewText: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: 'rgba(0,0,0,.8)',
    borderRadius: 6,
    color: 'white',
    fontSize: 10,
  },
  hiddenVideo: {
    display: 'none',
  },
  hiddenCanvas: {
    display: 'none',
    aspectRatio: 9 / 16,
    objectFit: 'contain',
  },
})
