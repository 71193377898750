import { useCallback } from 'react'
import type { TogglePauseRef } from './types'

type DesktopVideoContainerProps = {
  togglePauseRef: React.MutableRefObject<TogglePauseRef>
} & React.PropsWithChildren

export const DesktopVideoContainer: React.FC<DesktopVideoContainerProps> = ({
  togglePauseRef,
  children,
}) => {
  const togglePauseVideo = useCallback(() => {
    togglePauseRef.current?.togglePause()
  }, [togglePauseRef.current])

  return (
    <div className="absolute inset-0" onClick={togglePauseVideo} onKeyDown={togglePauseVideo}>
      {children}
    </div>
  )
}
