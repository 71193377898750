import { LinearGradient } from 'expo-linear-gradient'
import { memo } from 'react'
import { Animated, Easing, type EasingFunction, StyleSheet } from 'react-native'

type ColorStops = {
  [location: number]: {
    color: string
    easing?: EasingFunction
  }
}

type GradientParams = {
  colorStops: ColorStops
  extraColorStopsPerTransition?: number
  easing?: EasingFunction
}

const easeInOut = Easing.bezier(0.42, 0, 0.58, 1)

function easeGradient({
  colorStops,
  easing = easeInOut,
  extraColorStopsPerTransition = 12,
}: GradientParams) {
  const colors: string[] = []
  const locations: number[] = []

  const initialLocations = Object.keys(colorStops)
    .map((key) => Number(key))
    .sort()

  const totalColorStops = initialLocations.length

  for (let currentStopIndex = 0; currentStopIndex < totalColorStops - 1; currentStopIndex++) {
    const startLocation = initialLocations[currentStopIndex]
    const endLocation = initialLocations[currentStopIndex + 1]
    if (startLocation === undefined || endLocation === undefined) return

    const startColor = colorStops[startLocation]?.color
    const endColor = colorStops[endLocation]?.color
    const currentEasing = colorStops[startLocation]?.easing ?? easing

    if (!startColor || !endColor) return

    const colorScale = createInterpolation({
      inputRange: [0, 1],
      outputRange: [startColor, endColor],
      easing: currentEasing,
    })

    const currentTransitionLength = endLocation - startLocation
    const stepSize = 1 / (extraColorStopsPerTransition + 1)

    for (let stepIndex = 0; stepIndex <= extraColorStopsPerTransition + 1; stepIndex++) {
      const progress = stepIndex * stepSize
      const color = colorScale(progress)
      colors.push(color)
      locations.push(startLocation + currentTransitionLength * progress)
    }
  }

  return { colors, locations }
}

// @ts-expect-error
const AnimatedInterpolation = Animated.Interpolation

type ColorInterpolateFunction = (input: number) => string

function createInterpolation(config: Animated.InterpolationConfigType): ColorInterpolateFunction {
  if (AnimatedInterpolation.__createInterpolation) {
    return AnimatedInterpolation.__createInterpolation(config)
  }

  return (input) => {
    const interpolation = new AnimatedInterpolation({ __getValue: () => input }, config)

    return interpolation.__getValue()
  }
}

const gradient = easeGradient({
  colorStops: {
    0: {
      color: 'transparent',
    },
    1: {
      color: 'rgba(0, 0, 0, 0.55)',
    },
  },
})

function isAtLeastTwo<T>(arr: T[]): arr is [T, T, ...T[]] {
  return arr.length >= 2
}

export const FeedItemGradient = memo(
  () => {
    if (gradient && isAtLeastTwo(gradient.colors) && isAtLeastTwo(gradient.locations)) {
      return (
        <LinearGradient
          pointerEvents="none"
          colors={gradient.colors}
          locations={gradient.locations}
          style={StyleSheet.absoluteFill}
        />
      )
    }
    return null
  },
  () => true
)

FeedItemGradient.displayName = 'FeedItemGradient'
