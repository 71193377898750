import { trpc } from 'app/utils/api'
import { logError } from 'app/utils/logging/logError'

export const useLikePost = ({ likeCount }: { likeCount: number }) => {
  const likePost = trpc.postLike.create.useMutation({
    onMutate: (params) => {
      return {
        optimisticData: {
          post: {
            id: params.postId,
          },
          postLikeCount: likeCount + 1,
          hasLiked: true,
        },
        rollbackData: {
          post: {
            id: params.postId,
          },
          postLikeCount: likeCount,
          hasLiked: false,
        },
      }
    },
    onError: (error, params) => {
      logError({
        level: 'error',
        action: 'POST_LIKE',
        type: 'TAP',
        message: `Failed to like post ${params.postId}`,
        error,
      })
    },
  })

  const unlikePost = trpc.postLike.delete.useMutation({
    onMutate: (params) => {
      return {
        optimisticData: {
          post: {
            id: params.postId,
          },
          postLikeCount: likeCount - 1,
          hasLiked: false,
        },
        rollbackData: {
          post: {
            id: params.postId,
          },
          postLikeCount: likeCount,
          hasLiked: true,
        },
      }
    },
    onError: (error, params) => {
      logError({
        level: 'error',
        action: 'POST_UNLIKE',
        type: 'TAP',
        message: `Failed to unlike post ${params.postId}`,
        error,
      })
    },
  })

  return { likePost, unlikePost }
}
