import { Button, type ButtonProps } from '@my/ui'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useMe } from '../profile/hooks/use-me'
import { useUnfollow } from './hooks/use-unfollow'
import type { User } from './types'

export const FollowingButton = ({
  user,
  variant,
  classNameText,
  className,
}: {
  user: User
  variant?: ButtonProps['variant']
  classNameText?: ButtonProps['classNameText']
  className?: ButtonProps['className']
}) => {
  const me = useMe()
  const unfollow = useUnfollow({ user })

  const handleUnfollow = useEventCallback(() => {
    unfollow.mutate({ userId: user.id })
  })

  if (me.data?.id === user.id) {
    return null
  }

  return (
    <Button
      onPress={handleUnfollow}
      variant={variant}
      className={className}
      classNameText={classNameText}>
      Following
    </Button>
  )
}
