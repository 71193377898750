import { pickFile } from 'app/features/file-picker'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import * as ImagePicker from 'expo-image-picker'
import { Alert, Platform } from 'react-native'
import * as tus from 'tus-js-client'
import { logError } from '../logging/logError'
import { logInfo } from '../logging/logInfo'
import TusFileReader from './classes/filereader'
import { type VideoPath, videoUploadState$ } from './store/video-upload-state'
import type { SignUploadPayload } from './types'

const traceId = videoUploadState$.traceId.get()

export const takeVideo = async () => {
  videoUploadState$.selectActionStarted.set(true)
  const { status } = await ImagePicker.requestCameraPermissionsAsync()
  let selectedVideo: VideoPath = null

  if (status !== 'granted') {
    Alert.alert('No permissions granted')
    logError({
      level: 'warning',
      action: 'VIDEO_UPLOAD',
      type: 'TAP',
      message: 'No camera permissions granted',
      indexedTags: {
        traceId,
      },
    })
  } else {
    const video = await ImagePicker.launchCameraAsync({
      mediaTypes: 'videos',
      videoMaxDuration: 600, // 10 minutes
      allowsEditing: true,
      selectionLimit: 1,
    })

    if (!video.canceled && video.assets[0]) {
      selectedVideo = video.assets[0]
      videoUploadState$.setSelectedVideo(selectedVideo)
    }
    videoUploadState$.selectActionStarted.set(false)

    logInfo({
      action: 'VIDEO_UPLOAD',
      type: 'TAP',
      message: 'Recorded video with camera',
      data: {
        traceId,
        video: selectedVideo,
      },
    })

    return !!selectedVideo
  }
}

export const pickVideo = async () => {
  videoUploadState$.selectActionStarted.set(true)
  let selectedVideo: VideoPath = null

  if (Platform.OS === 'web') {
    const videoFile = await pickFile({
      mediaTypes: 'videos',
    })
    if (videoFile.file instanceof File) {
      selectedVideo = videoFile.file
    }
    videoUploadState$.setSelectedVideo(selectedVideo)
    logInfo({
      action: 'VIDEO_UPLOAD',
      type: 'TAP',
      message: 'Picked video with browser file picker',
      data: {
        traceId,
        video: selectedVideo,
      },
    })
  } else {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status !== 'granted') {
      Alert.alert('No permissions granted')
      logError({
        level: 'warning',
        action: 'VIDEO_UPLOAD',
        type: 'TAP',
        message: 'No media library permissions granted',
        indexedTags: {
          traceId,
        },
      })
    } else {
      const video = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: 'videos',
        preferredAssetRepresentationMode:
          ImagePicker.UIImagePickerPreferredAssetRepresentationMode.Current,
        allowsEditing: true,
        videoMaxDuration: 600, // 10 minutes
        selectionLimit: 1,
      })

      if (!video.canceled && video.assets[0]) {
        selectedVideo = video.assets[0]
        videoUploadState$.setSelectedVideo(selectedVideo)
      }

      logInfo({
        action: 'VIDEO_UPLOAD',
        type: 'TAP',
        message: 'Picked video with image picker',
        traceId,
        data: {
          video: selectedVideo,
        },
      })
    }
  }
  videoUploadState$.selectActionStarted.set(false)

  return !!selectedVideo
}

export const chooseVideo = async () => {
  videoUploadState$.selectActionStarted.set(true)
  let selectedVideo: VideoPath = null

  const video = await DocumentPicker.getDocumentAsync({
    type: ['video/mp4', 'video/quicktime', 'video/mov'],
  })

  if (!video.canceled && video.assets[0]) {
    selectedVideo = video.assets[0]
    videoUploadState$.setSelectedVideo(selectedVideo)
  }
  videoUploadState$.selectActionStarted.set(false)

  logInfo({
    action: 'VIDEO_UPLOAD',
    type: 'TAP',
    message: 'Picked video with document picker',
    traceId,
    data: {
      video: selectedVideo,
    },
  })

  return !!selectedVideo
}

export const signUpload = async (payload: SignUploadPayload) => {
  return new Promise<void>((resolve, reject) => {
    if (videoUploadState$.videoPath.get() === null) {
      return false
    }

    const unloadListener = (event: BeforeUnloadEvent) => {
      event.returnValue = `Are you sure you want to leave?`
    }

    const cleanUp = () => {
      videoUploadState$.set((s) => ({
        ...s,
        uploadProgress: 0,
        isUploading: false,
        thumbnailTimestamp: 0,
      }))
      globalThis?.gc?.()
      if (Platform.OS === 'web' && typeof window !== 'undefined') {
        window.removeEventListener('beforeunload', unloadListener)
      }
      removeSharedVideoFiles()
    }

    videoUploadState$.isUploading.set(true)

    try {
      const fileObject: VideoPath = videoUploadState$.videoPath.get()

      logInfo({
        action: 'VIDEO_UPLOAD',
        type: 'CUSTOM',
        message: 'Upload started',
        traceId,
        data: {
          video: fileObject,
        },
      })

      // @ts-expect-error - size is not defined in type
      const size = fileObject.size ?? fileObject.fileSize ?? 0

      const options: tus.UploadOptions = {
        endpoint: 'https://video.bunnycdn.com/tusupload',
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 5 * 1024 * 1024,
        uploadLengthDeferred: !size || size === 0,
        headers: {
          AuthorizationSignature: payload.data.signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: String(payload.data.expirationTime), // Expiration time as in the signature,
          VideoId: payload.data.guid, // The guid of a previously created video object through the Create Video API call
          LibraryId: payload.data.libraryId,
        },
        ...(Platform.OS !== 'web'
          ? {
              fileReader: new TusFileReader(),
              uploadSize: size,
            }
          : {
              parallelUploads: 5,
            }),
        onError: (error) => {
          logError({
            level: 'error',
            message: 'Bunny TUS video upload failed',
            action: 'VIDEO_UPLOAD',
            type: 'CUSTOM',
            error,
            indexedTags: {
              traceId,
            },
          })
          reject('Upload failed. Please retry.')
          cleanUp()
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          // console.log(bytesUploaded, bytesTotal, `${percentage}%`)

          videoUploadState$.uploadProgress.set(Number(percentage))
          payload?.options?.onProgress(Number(percentage))
          globalThis?.gc?.()
        },
        onSuccess: async () => {
          logInfo({
            action: 'VIDEO_UPLOAD',
            type: 'CUSTOM',
            message: 'Uploaded successfully',
            data: {
              traceId,
              video: fileObject,
            },
          })
          resolve()
          cleanUp()
        },
        onShouldRetry: (err) => {
          logInfo({
            message: 'Video upload should retry',
            action: 'VIDEO_UPLOAD',
            type: 'CUSTOM',
            data: {
              traceId,
            },
          })

          const status = (err as tus.DetailedError).originalResponse
            ? (err as tus.DetailedError)?.originalResponse?.getStatus()
            : 0
          // If the status is a 403 or 404, we dont want to retry
          if (status === 403 || status === 404) {
            logError({
              level: 'error',
              message: `Video upload retry skipped due to ${status} error`,
              action: 'VIDEO_UPLOAD',
              type: 'CUSTOM',
              error: err,
              indexedTags: {
                traceId,
              },
            })
            return false
          }

          // For any other status code, tus-js-client should retry.
          return true
        },
      }

      videoUploadState$.uploadInstance.set(new tus.Upload(fileObject as unknown as File, options))

      videoUploadState$.uploadInstance
        .get()
        .findPreviousUploads()
        .then((previousUploads) => {
          if (previousUploads[0]) {
            videoUploadState$.uploadInstance.get().resumeFromPreviousUpload(previousUploads[0])
          }
          videoUploadState$.uploadInstance.get().start()
          if (Platform.OS === 'web' && typeof window !== 'undefined') {
            window.addEventListener('beforeunload', unloadListener)
          }
        })
    } catch (e) {
      logError({
        level: 'error',
        message: 'Something went wrong during video upload',
        action: 'VIDEO_UPLOAD',
        type: 'CUSTOM',
        error: e,
        indexedTags: {
          traceId,
        },
      })
      reject('Something went wrong')
      cleanUp()
    }
  })
}

export const abortUpload = () => {
  Alert.alert('Abort upload?', 'Are you sure you want to abort the upload?', [
    {
      text: 'Cancel',
    },
    {
      text: 'Abort',
      style: 'destructive',
      onPress: async () => {
        logInfo({
          action: 'VIDEO_UPLOAD',
          type: 'TAP',
          message: 'Video upload aborted',
          data: {
            traceId,
          },
        })
        videoUploadState$.uploadInstance.get().abort()
        videoUploadState$.set((s) => ({
          ...s,
          uploadProgress: 0,
          isUploading: false,
          thumbnailTimestamp: 0,
        }))
        globalThis?.gc?.()
        Alert.alert('Upload aborted')
      },
    },
  ])
}

export const getFileFormData = async (file: string | File): Promise<Blob | undefined> => {
  const fileMetaData = await getFileMeta(file)

  if (!fileMetaData) return

  if (typeof file === 'string') {
    // Web Camera -  Data URI
    if (file?.startsWith('data')) {
      //@ts-expect-error - uri is not defined in type
      const newFile = dataURLtoFile(file, 'unknown')

      return newFile
    }
    // Native - File path string

    return {
      //@ts-expect-error - uri is not defined in type
      uri: file,
      name: fileMetaData.name,
      type: fileMetaData.type,
    }
  }

  // Web File Picker - File Object
  return file as Blob
}
export const supportedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp']
export const supportedVideoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm']

export const getFileMeta = async (file?: File | string) => {
  if (!file) {
    return
  }

  if (typeof file === 'string') {
    // Web Camera -  Data URI
    if (file.startsWith('data')) {
      const fileExtension = file.substring(file.indexOf(':') + 1, file.indexOf(';'))

      const contentWithoutMime = file.split(',')[1]
      if (contentWithoutMime) {
        const sizeInBytes = window.atob(contentWithoutMime).length

        return {
          name: 'unknown',
          type: fileExtension,
          size: sizeInBytes,
        }
      }
    }

    // Native - File path
    else {
      const fileName = file.split('/').pop()
      const fileExtension = fileName?.split('.').pop()
      const fileInfo = await FileSystem.getInfoAsync(file)

      if (fileExtension && supportedImageExtensions.includes(fileExtension)) {
        return {
          name: fileName,
          type: `image/${fileExtension}`,
          // @ts-expect-error - size is not defined in type
          size: fileInfo.size,
        }
      }
      if (fileExtension && supportedVideoExtensions.includes(fileExtension)) {
        return {
          name: fileName,
          type: `video/${fileExtension}`,
          // @ts-expect-error - size is not defined in type
          size: fileInfo.size,
        }
      }
    }
  }

  // Web File Picker - File Object
  else {
    return {
      name: file.name,
      type: file.type,
      size: file.size,
    }
  }
}

// clean up file received from iOS share extension
export const removeSharedVideoFiles = async () => {
  console.info(
    'Removing shared video files code is disabled, as expo-share-extension is not updated yet. Please update the code once expo-share-extension is updated.'
  )
  /* TODO: comment back in once expo-share-extension is updated
  if (Platform.OS === 'ios') {
    try {
      const cleanUpBefore = dayjs().subtract(1, 'hour').toDate()
      await clearAppGroupContainer({ cleanUpBefore })
    } catch (error) {
      logError({
        level: 'error',
        action: 'VIDEO_UPLOAD',
        type: 'CUSTOM',
        message: 'Failed to clear app group container',
        error,
        indexedTags: {
          traceId,
        },
      })
    }
  }
    */
}
