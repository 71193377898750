import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const CrownIcon = (props: Props) => {
  return (
    <Svg width={props.width ?? 13} height={props.height ?? 11} viewBox="0 0 13 11" fill="none">
      <Path
        d="m10.3249 10.8202h-7.82829c-.26042 0-.58607-.2111-.66604-.5132l-1.539521-7.11938c-.2196778-.93985.22445-1.22419.942451-.71304l2.41812 1.72989c.40302.27901.86184.13641 1.03545-.31621l1.09125-2.90795c.34722-.9300456.92385-.9300456 1.27106 0l1.09126 2.90795c.17361.45262.63243.59522 1.02925.31621l2.26931-1.61828c.7877-.58721 1.303-.30287 1.0355.78124l-1.4919 6.93957c-.0622.3466-.3975.5132-.6579.5132z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  )
}
