import Svg, { Circle, Path } from 'react-native-svg'
import { theme } from '..'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function BloodIcon(props: Props) {
  return (
    <Svg width={props.width ?? 17} height={props.height ?? 23} viewBox="0 0 17 23" fill="none">
      <Circle
        cx={7.91501}
        cy={7.91501}
        r={7.91501}
        transform="matrix(-1 0 0 1 16.592 6.6)"
        fill={props.color ?? theme.colors.primary}
      />
      <Path
        d="M15.213 10.1L8.563.695 2.186 10.1h13.026z"
        fill={props.color ?? theme.colors.primary}
      />
    </Svg>
  )
}
