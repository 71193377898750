import { useEffect } from 'react'
import {
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  type SharedValue,
} from 'react-native-reanimated'
import { AnimatedPressable } from '../pressable'
import { AnimatedText } from '../text'
import { View } from '../view'

type Props<T> = {
  value: T
  onChange: (value: T) => void
  tabs: { value: T; label: string }[]
}

export function RoundedTabBar<T>(props: Props<T>) {
  const { value, onChange, tabs } = props
  const valueSharedValue = useSharedValue(value)

  useAnimatedReaction(
    () => {
      return valueSharedValue.value
    },
    (value) => {
      runOnJS(onChange)(value)
    }
  )

  useEffect(() => {
    if (valueSharedValue.value === value) return
    valueSharedValue.value = value
  }, [value, valueSharedValue])

  return (
    <View className="gap-4 flex-row items-center bg-surfaceDarkShade2 px-2 py-1 rounded-full">
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          onPress={() => {
            valueSharedValue.value = tab.value
          }}
          activeSharedValue={valueSharedValue}
          value={tab.value}
          label={tab.label}
        />
      ))}
    </View>
  )
}

type TabProps<T> = {
  activeSharedValue: SharedValue<T>
  onPress: () => void
  label: string
  value: T
}

function Tab<T>(props: TabProps<T>) {
  const { activeSharedValue, onPress, label, value } = props
  const activeBgStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: activeSharedValue.value === value ? 'white' : 'transparent',
    }
  })

  const activeTextStyle = useAnimatedStyle(() => {
    return {
      color: activeSharedValue.value === value ? 'black' : 'white',
    }
  })

  return (
    <AnimatedPressable onPress={onPress} className="rounded-full px-6 py-2" style={activeBgStyle}>
      <AnimatedText className="font-semibold text-sm md:text-xs" style={activeTextStyle}>
        {label}
      </AnimatedText>
    </AnimatedPressable>
  )
}
