import { Image } from 'expo-image'

export const PowerUserBadge = ({ width = 18, height = 18 }) => {
  return (
    <Image
      source={require('@my/ui/src/icons/power-user-checkmark.svg')}
      style={{ width, height, borderRadius: 9999 }}
      alt="Power user badge"
    />
  )
}
