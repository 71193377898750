import Svg, { type SvgProps, Circle, Path } from 'react-native-svg'

export const BaseNetworkIcon = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 146 146" height="50" width="50" {...props}>
    <Circle cx={73} cy={73} r={73} fill="#0052ff" />
    <Path
      fill="#fff"
      d="M73.323 123.729c28.294 0 51.23-22.897 51.23-51.142s-22.936-51.14-51.23-51.14c-26.843 0-48.865 20.609-51.052 46.842h67.715v8.597H22.27c2.187 26.233 24.209 46.843 51.052 46.843z"
    />
  </Svg>
)
