import { Pressable, ShareIcon } from '@my/ui'
import { useShareSheet } from 'app/utils/share/use-share'
import type { VideoFeedItemProps } from './types'

export const PostShareButton = ({ postItem }: { postItem: VideoFeedItemProps['item'] }) => {
  const share = useShareSheet()
  return (
    <Pressable
      haptics
      onPress={() => {
        if (postItem.post.id && postItem.user?.username)
          share.share({
            type: 'post',
            data: {
              postId: postItem.post.id,
              username: postItem.user.username,
            },
          })
      }}>
      <ShareIcon width={30} height={30} color="#fff" />
    </Pressable>
  )
}
