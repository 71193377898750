import Ionicons from '@expo/vector-icons/Ionicons'
import { BaseIcon, LeanText, LeanView, Pressable, theme } from '@my/ui'
import { formatNumberForPrice } from 'app/utils/common'
import { Image } from 'expo-image'
import { useCallback, useEffect, useRef } from 'react'
import { Platform } from 'react-native'
import { formatUnits } from 'viem'
import * as DropdownMenu from 'zeego/dropdown-menu'
import { ImageWithBaseLogo } from '../common/image-with-base-logo'
import { ethToken, usdcToken } from './constants'
import { useEthBalanceInUsd } from './hooks/use-eth-balance-in-usd'
import { useTokenBalance } from './hooks/use-token-balance'

export const SelectCurrency = ({
  selectedOption,
  setSelectedOption,
  hideEth,
  hideUsdc,
}: {
  selectedOption: 'eth' | 'usdc'
  setSelectedOption: (option: 'eth' | 'usdc') => void
  hideEth: boolean
  hideUsdc: boolean
}) => {
  const initialSetValue = useRef(false)
  const ethBalanceInUsd = useEthBalanceInUsd()
  const usdcBalance = useTokenBalance({
    address: usdcToken.address,
  })

  const formattedUsdcBalance =
    typeof usdcBalance.data !== 'undefined'
      ? formatNumberForPrice(Number(formatUnits(usdcBalance.data, usdcToken.decimals)))
      : '0'

  const formattedEthBalanceInUsd =
    typeof ethBalanceInUsd !== 'undefined'
      ? formatNumberForPrice(Number(formatUnits(ethBalanceInUsd, usdcToken.decimals)))
      : '0'

  const handleSelectETH = useCallback(() => setSelectedOption('eth'), [setSelectedOption])

  const handleSelectUSDC = useCallback(() => setSelectedOption('usdc'), [setSelectedOption])

  const cashString = `Cash: $${formattedUsdcBalance}`
  const ethString = `ETH: $${formattedEthBalanceInUsd}`

  useEffect(() => {
    if (
      !hideEth &&
      !hideUsdc &&
      typeof ethBalanceInUsd !== 'undefined' &&
      typeof usdcBalance.data !== 'undefined'
    ) {
      if (initialSetValue.current) return
      initialSetValue.current = true
      if (ethBalanceInUsd > usdcBalance.data) {
        setSelectedOption('eth')
      } else {
        setSelectedOption('usdc')
      }
    }
  }, [ethBalanceInUsd, usdcBalance.data, setSelectedOption, hideEth, hideUsdc])

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Pressable scale className="rounded-[48px] px-4 py-2 self-center">
            <LeanView className="flex-row items-center gap-2 justify-center">
              {selectedOption === 'eth' ? (
                <ImageWithBaseLogo uri={ethToken.image} size={16} baseLogoSize={10} />
              ) : (
                <LeanView>
                  <LeanView className="bg-neutral-700 rounded-full overflow-hidden">
                    <Image
                      source={{
                        uri: usdcToken.image,
                        width: 25,
                        height: 25,
                      }}
                      style={{ height: 16, width: 16 }}
                      contentFit="cover"
                    />
                  </LeanView>
                  <LeanView className="absolute bottom-[-4px] right-[-4px]">
                    <BaseIcon width={10} height={11} />
                  </LeanView>
                </LeanView>
              )}
              <LeanText className="text-white text-base">
                {selectedOption === 'eth'
                  ? `ETH: $${formattedEthBalanceInUsd}`
                  : `Cash: $${formattedUsdcBalance}`}
              </LeanText>
              <Ionicons name="chevron-down" size={16} color={theme.colors.grayTextColor} />
            </LeanView>
          </Pressable>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          style={Platform.select({
            web: {
              backgroundColor: 'hsl(0, 0%, 13.6%)',
              padding: 16,
              gap: 16,
              borderRadius: 8,
              marginTop: 16,
              display: 'flex',
              flexDirection: 'column',
            },
          })}>
          {!hideEth ? (
            <DropdownMenu.Item
              key="eth"
              onSelect={handleSelectETH}
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}>
              <DropdownMenu.ItemIcon
                ios={selectedOption === 'eth' ? { name: 'checkmark' } : undefined}>
                <Image source={ethToken.image} style={{ width: 16, height: 16 }} />
              </DropdownMenu.ItemIcon>
              <DropdownMenu.ItemTitle style={{ color: 'white' }}>
                {ethString}
              </DropdownMenu.ItemTitle>
            </DropdownMenu.Item>
          ) : null}
          {!hideUsdc ? (
            <DropdownMenu.Item
              key="usdc"
              onSelect={handleSelectUSDC}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'flex',
                gap: 8,
              }}>
              <DropdownMenu.ItemIcon
                ios={selectedOption === 'usdc' ? { name: 'checkmark' } : undefined}>
                <Image source={usdcToken.image} style={{ width: 16, height: 16 }} />
              </DropdownMenu.ItemIcon>
              <DropdownMenu.ItemTitle style={{ color: 'white' }}>
                {cashString}
              </DropdownMenu.ItemTitle>
            </DropdownMenu.Item>
          ) : null}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  )
}
