import { LeanText, LeanView, Pressable, cn } from '@my/ui'
import { useEffect, useImperativeHandle, useMemo, useRef, useState, type RefObject } from 'react'
import { TextInput } from 'react-native'

type Props = {
  code: string
  setCode: (text: string) => void
  otpInputRef?: RefObject<{
    focus: () => void
  }>
}

const MAXIMUM_LENGTH = 6
const boxArray = new Array(MAXIMUM_LENGTH).fill(0)

export const OtpInput = ({ code, setCode, otpInputRef }: Props) => {
  const inputRef = useRef<TextInput>(null)

  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false)

  const focusFirstInput = () => {
    setIsInputBoxFocused(true)
    inputRef.current?.focus()
  }

  useImperativeHandle(otpInputRef, () => ({
    focus: focusFirstInput,
    blur: () => {
      setIsInputBoxFocused(false)
      inputRef.current?.blur()
    },
  }))

  const handleOnBlur = () => {
    setIsInputBoxFocused(false)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus()
    }, 300)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Pressable onPress={focusFirstInput} className="h-20 flex-row justify-evenly gap-x-1">
        <BoxDigits isInputBoxFocused={isInputBoxFocused} code={code} />
      </Pressable>
      <TextInput
        className="opacity-0 web:text-[16px]"
        value={code}
        onChangeText={setCode}
        maxLength={MAXIMUM_LENGTH}
        ref={inputRef}
        onBlur={handleOnBlur}
        keyboardType="numeric"
        textContentType="oneTimeCode"
        keyboardAppearance="dark"
      />
    </>
  )
}

const BoxDigits = ({ isInputBoxFocused, code }: { isInputBoxFocused: boolean; code: string }) => {
  return boxArray.map((_, index) => {
    const emptyInput = ''
    const digit = code[index] || emptyInput

    const isCurrentValue = index === code.length
    const isLastValue = index === MAXIMUM_LENGTH - 1
    const isCodeComplete = code.length === MAXIMUM_LENGTH

    const isValueFocused = isCurrentValue || (isLastValue && isCodeComplete)

    return (
      <BoxDigit
        isInputBoxFocused={isInputBoxFocused}
        isValueFocused={isValueFocused}
        digit={digit}
        key={index}
      />
    )
  })
}

const BoxDigit = ({
  isInputBoxFocused,
  isValueFocused,
  digit,
}: { isInputBoxFocused: boolean; isValueFocused: boolean; digit: string }) => {
  const className = useMemo(
    () =>
      cn(
        'flex-[1] grow w-24 justify-center border-b',
        isInputBoxFocused && isValueFocused ? 'border-white' : 'border-borderShade1'
      ),
    [isInputBoxFocused, isValueFocused]
  )

  return (
    <LeanView className={className}>
      <LeanText className="text-3xl text-white font-bold text-center">{digit}</LeanText>
    </LeanView>
  )
}
