import { BottomSheetFlashList, BottomSheetFooter } from '@gorhom/bottom-sheet'
import type { BottomSheetDefaultFooterProps } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetFooter/types'
import type { BottomSheetFlashListProps } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetScrollable/BottomSheetFlashList'
import { Platform, useWindowDimensions } from 'react-native'
import { FlashList, type FlashListProps } from '..'
import { View, type ViewProps } from '../view'

type PlatformFlashListProps<T> = FlashListProps<T> | BottomSheetFlashListProps<T>

export const PlatformAwareFlashList = <T,>(props: PlatformFlashListProps<T>) => {
  const { width } = useWindowDimensions()

  if (Platform.OS === 'web' && width > 767) {
    return <FlashList {...(props as FlashListProps<T>)} />
  }

  return <BottomSheetFlashList {...props} />
}
PlatformAwareFlashList.displayName = 'PlatformFlashList'

export const PlatformAwareBottomSheetFooter = (
  props: BottomSheetDefaultFooterProps | ViewProps
) => {
  const { width } = useWindowDimensions()

  if (Platform.OS === 'web' && width > 767) {
    return <View {...(props as ViewProps)} />
  }

  return <BottomSheetFooter {...(props as BottomSheetDefaultFooterProps)} />
}
PlatformAwareFlashList.displayName = 'PlatformFlashList'
