import { experimental_createPersister } from '@tanstack/react-query-persist-client'
import Constants from 'expo-constants'
import * as Updates from 'expo-updates'
import { MMKV } from 'react-native-mmkv'

export const storage = new MMKV({
  id: 'drakula',
})

//storage.clearAll()

type ClientStorage = {
  setItem: typeof storage.set
  getItem: (key: string) => string | null
  removeItem: typeof storage.delete
}

/**
 * Determines whether an error is a QuotaExceededError.
 *
 * Browsers love throwing slightly different variations of QuotaExceededError
 * (this is especially true for old browsers/versions), so we need to check
 * different fields and values to ensure we cover every edge-case.
 *
 * @param err - The error to check
 * @return Is the error a QuotaExceededError?
 */
function isQuotaExceededError(err: unknown): boolean {
  return (
    err instanceof DOMException &&
    // everything except Firefox
    (err?.code === 22 ||
      // Firefox
      err?.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      err?.name === 'QuotaExceededError' ||
      // Firefox
      err?.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  )
}

const buildKey = process.env.EXPO_PUBLIC_CODEBUILD_BUILD_ID || `build-0`
const buster = `${Constants.manifest2?.runtimeVersion ?? 'web'}-${
  Updates.updateId ?? 'default'
}-${buildKey}`
const prefix = 'drakula-query'

export const clearStalePersistedReactQueryData = () => {
  // this is needed to avoid issues with SSR rendering
  // prevents metro from build failing
  if (typeof window === 'undefined') return

  const persistentStoreTime = Date.now() - 1 * 24 * 60 * 60 * 1000 // Timestamp of 1 day

  for (const entry of storage.getAllKeys()) {
    // we only want to clear the data for react-query with the prefix defined above
    if (!entry.startsWith(prefix)) {
      continue
    }

    const rawValue = storage.getString(entry)
    if (!rawValue) continue

    try {
      const value = JSON.parse(rawValue)
      // Check if the entry contains "buster" and if it matches the current buster
      // otherwise delete the entry
      if (value?.buster !== buster) {
        storage.delete(entry)
        continue
      }

      if (value?.state?.dataUpdatedAt && value.state.dataUpdatedAt < persistentStoreTime) {
        // Delete the stale entry
        storage.delete(entry)
      } else {
        // Check if the entry contains "pages" and "pageParams"
        if (value.state.data?.pages && value.state.data.pageParams) {
          const { pages, pageParams } = value.state.data
          // Check if the length of "pages" and "pageParams" is greater than 1
          if (pages.length > 1 && pageParams.length > 1) {
            // Slice "pages" and "pageParams" to keep only the first entry
            value.state.data.pages = pages.slice(0, 1)
            value.state.data.pageParams = pageParams.slice(0, 1)

            // We need to update the nextCursor if it exists
            // We remove all pages but keep page 1.
            // nextCursor could be any number in the cache, but we need to set it to 2.
            // We need to indicate which is the nextPage so that getNextPageParam works correctly.
            // Since we're storing the first page inside the persister,
            // react-query will start fetching page 2 onEndReached (while revalidating page 1).
            if (value.state.data.pages?.[0]?.nextCursor !== undefined) {
              value.state.data.pages[0].nextCursor = 2
            }

            // Update the stored value with the modified data
            storage.set(entry, JSON.stringify(value))
          }
        }
      }
    } catch (error) {
      console.error(`Error parsing JSON for key ${entry}:`, error)
    }
  }
}

export const clearAllPersistedReactQueryData = () => {
  // this is needed to avoid issues with SSR rendering
  // prevents metro from build failing
  if (typeof window === 'undefined') return
  for (const entry of storage.getAllKeys()) {
    // we only want to clear the data for react-query with the prefix defined above
    if (entry.startsWith(prefix)) {
      storage.delete(entry)
    }
  }
}

export const ReactQueryMMKVPersisterStorage: ClientStorage = {
  setItem: (key, value) => {
    try {
      storage.set(key, value)
    } catch (err) {
      if (isQuotaExceededError(err)) {
        // should we clear all the data?
        // Todo: Discuss with nishan
      } else {
        // Handle the case where the localStorage API is not supported.
        // Todo: Discuss with nishan
      }
    }
  },
  getItem: (key) => {
    const value = storage.getString(key)
    return value === undefined ? null : value
  },
  removeItem: (key) => {
    storage.delete(key)
  },
}

export const persister = experimental_createPersister({
  maxAge: 1000 * 60 * 60 * 12, // 12 hours
  storage: ReactQueryMMKVPersisterStorage,
  prefix,
  buster: buster,
})
