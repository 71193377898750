import { Pressable, Text, View } from '@my/ui'
import { Link } from 'expo-router'

export const FollowerFollowingCount = ({
  username,
  followersCount,
  followingCount,
}: { username: string; followersCount: number; followingCount: number }) => {
  return (
    <View className="flex-row gap-4">
      <View className="flex-row gap-1 items-center">
        <Link push href={`/following/${username}`} asChild>
          <Pressable className="flex-row gap-1">
            <Text className="text-white font-bold">
              {followingCount?.toLocaleString('en-US') ?? 0}
            </Text>
            <Text className="text-zinc-400">Following</Text>
          </Pressable>
        </Link>
      </View>
      <View className="flex-row gap-1 items-center">
        <Link push href={`/followers/${username}`} asChild>
          <Pressable className="flex-row gap-1">
            <Text className="text-white font-bold">
              {followersCount?.toLocaleString('en-US') ?? 0}
            </Text>
            <Text className="text-zinc-400">Followers</Text>
          </Pressable>
        </Link>
      </View>
    </View>
  )
}
