import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const FireIcon = (props: Props) => {
  return (
    <Svg
      width={props.width ?? 12}
      height={props.height ?? 17}
      viewBox="0 0 12 17"
      fill={props.color}>
      <Path
        d="M6.0429 16.0876C9.0664 16.0876 11.5174 13.6366 11.5174 10.6131C11.4027 11.0951 10.9356 11.9318 9.98528 11.4222C8.79741 10.7853 8.62628 9.17705 9.84859 8.11247C10.8551 7.23586 10.2532 4.82828 8.90704 4.29219C6.96169 3.51749 7.93508 1.97287 8.65813 0.974365C7.43521 1.20783 5.53935 2.00754 5.53935 3.95068C5.53935 5.68944 7.11301 6.65988 5.66846 7.61328C4.3773 8.46545 2.84512 6.99352 3.21039 5.92733C1.62671 6.88671 0.568359 8.6263 0.568359 10.6131C0.568359 13.6366 3.01939 16.0876 6.0429 16.0876Z"
        fill={props.color ?? 'white'}
      />
    </Svg>
  )
}
