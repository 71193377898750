import { Text, View, cn } from '@my/ui'
import React, { useMemo } from 'react'
import { UnorderedListLevelContext, useUnorderedListLevel } from './context'

interface BulletPointProps {
  children: React.ReactNode
  bulletUnicode?: number
  color?: string
  itemClassName?: string
  bulletClassName?: string
  itemInnerClassName?: string
  textWrapperClassName?: string
}

const bullets = ['\u2022', '\u25E6', '\u25AA']

export const UnorderedList: React.FC<BulletPointProps> = ({
  children,
  bulletUnicode,
  bulletClassName,
  itemClassName,
  itemInnerClassName,
  textWrapperClassName,
}) => {
  // Use the current level from the context
  const currentLevel = useUnorderedListLevel()

  // Increment level for children
  const newLevel = currentLevel + 1

  const itemClass = useMemo(
    () => cn('flex-row items-start justify-start', itemClassName),
    [itemClassName]
  )

  const itemInnerClass = useMemo(
    () => cn('self-start items-start mt-[-3px] md:-mt-1 ios:mt-0.5', itemInnerClassName),
    [itemInnerClassName]
  )

  const bulletClass = useMemo(
    () => cn('text-2xl leading-none text-white', bulletClassName),
    [bulletClassName]
  )

  const textWrapperClass = useMemo(
    () => cn('pl-4 flex-1', textWrapperClassName),
    [textWrapperClassName]
  )

  // Map children to add bullet points and increment unorderedListLevel
  const newChildren = React.Children.map(children, (child) =>
    React.isValidElement(child) ? (
      <View className={itemClass}>
        <View className={itemInnerClass}>
          <Text className={bulletClass}>
            {bulletUnicode
              ? String.fromCharCode(bulletUnicode)
              : newLevel > bullets.length
                ? bullets[bullets.length - 1]
                : bullets[newLevel - 1]}
            {/* Subtract 1 because level starts from 1 but array index starts from 0 */}
          </Text>
        </View>

        <View className={textWrapperClass}>{child}</View>
      </View>
    ) : (
      child
    )
  )

  // Provide the new level to children
  return (
    <UnorderedListLevelContext.Provider value={newLevel}>
      {newChildren}
    </UnorderedListLevelContext.Provider>
  )
}

export default UnorderedList
