import {
  ItemKeyContext,
  ViewabilityItemsContext,
} from 'app/utils/with-viewability-infinite-scroll-list'
import { useContext, useState } from 'react'
import { runOnJS, useAnimatedReaction } from 'react-native-reanimated'

export const useIsItemVisible = () => {
  const context = useContext(ViewabilityItemsContext)
  const id = useContext(ItemKeyContext)
  const [visible, setVisible] = useState(false)
  const isItemInList = typeof id !== 'undefined'

  useAnimatedReaction(
    () => context.value,
    (ctx) => {
      if (isItemInList) {
        if (ctx[1] === id) {
          runOnJS(setVisible)(true)
        } else {
          runOnJS(setVisible)(false)
        }
      } else {
        runOnJS(setVisible)(false)
      }
    },
    [id, isItemInList, context]
  )

  return visible
}
