import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import type { User } from '@privy-io/react-auth'
import Constants from 'expo-constants'
import { Platform } from 'react-native'

// TODO: had to copy and hard code due to web build breaking when importing from "expo-datadog"
// See discussion: https://showtime-xyz.slack.com/archives/C06SRGK6BMK/p1715077937146109
export enum RumActionType {
  /** User tapped on a widget. */
  TAP = 'TAP',
  /** User scrolled a view. */
  SCROLL = 'SCROLL',
  /** User swiped on a view. */
  SWIPE = 'SWIPE',
  /** User pressed hardware back button (Android only). */
  BACK = 'BACK',
  /** A custom action. */
  CUSTOM = 'CUSTOM',
}

if (!process.env.EXPO_PUBLIC_DATA_DOG_CLIENT_TOKEN) {
  throw new Error('EXPO_PUBLIC_DATA_DOG_CLIENT_TOKEN is not defined')
}

if (!process.env.EXPO_PUBLIC_DATA_DOG_APPLICATION_ID) {
  throw new Error('EXPO_PUBLIC_DATA_DOG_APPLICATION_ID is not defined')
}

if (!process.env.EXPO_PUBLIC_APP_ENV) {
  throw new Error('EXPO_PUBLIC_APP_ENV is not defined')
}

const config = {
  applicationId: process.env.EXPO_PUBLIC_DATA_DOG_APPLICATION_ID,
  clientToken: process.env.EXPO_PUBLIC_DATA_DOG_CLIENT_TOKEN,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: Platform.OS,
  version: Constants.expoConfig?.version,
  env: process.env.EXPO_PUBLIC_APP_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
}

const initDatadog = async () => {
  if (__DEV__) return
  datadogRum.init(config)
  datadogLogs.init(config)
}

const identifyDatadogUser = (user: User) => {
  const dataDogUser = user.linkedAccounts.reduce(
    (existingFields, linkedAccount) =>
      Object.assign(existingFields, { [`${linkedAccount.type}`]: linkedAccount }),
    {
      privyId: user.id,
      accountTypes: user.linkedAccounts.map((account) => account.type),
      acceptedTerms: user.hasAcceptedTerms,
      createdAt: user.createdAt,
    }
  )
  datadogRum.setUser(dataDogUser)
}

export const getDDogRumActionType = (type: keyof typeof RumActionType) => {
  switch (type) {
    case 'BACK':
      return RumActionType.BACK
    case 'SCROLL':
      return RumActionType.SCROLL
    case 'TAP':
      return RumActionType.TAP
    case 'CUSTOM':
      return RumActionType.CUSTOM
    case 'SWIPE':
      return RumActionType.SWIPE
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}

export const datadog = {
  init: () => initDatadog(),
  identify: (user: User) => identifyDatadogUser(user),
  reset: () => datadogRum.clearUser(),
  log: {
    info: (msg: string, args?: object) => datadogLogs.logger.info(msg, args),
    warn: (msg: string, args?: object) => datadogLogs.logger.warn(msg, args),
    error: (msg: string, args?: object) => datadogLogs.logger.error(msg, args),
    debug: (msg: string, args?: object) => datadogLogs.logger.debug(msg, args),
  },
  rum: {
    addAction: (type: RumActionType, name: string, args?: object) =>
      datadogRum.addAction(type, { name, ...args }),
    startView: (viewKey: string, _1: string, _2?: object) => datadogRum.startView(viewKey),
  },
}
