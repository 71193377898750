import { Button, ShareIcon } from '@my/ui'
import { useShareSheet } from 'app/utils/share/use-share'

export const ShareProfileButton = ({ username }: { username?: string }) => {
  const { share } = useShareSheet()

  return (
    <Button
      className="rounded-lg"
      icon={<ShareIcon width={16} height={14} color={`#000`} />}
      variant="white"
      onPress={() => {
        if (username)
          share({
            type: 'user',
            data: {
              username,
            },
          })
      }}>
      Share
    </Button>
  )
}
