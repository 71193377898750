import { LeanView, Pressable, SearchIcon } from '@my/ui'
import { useIsFocused } from '@react-navigation/native'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { trpc } from 'app/utils/api'
import { Image } from 'expo-image'
import { useNavigation, useRouter } from 'expo-router'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Platform, TextInput } from 'react-native'

interface SearchBarProps {
  autoFocus?: boolean
  onSearch?: (query: string) => void
  isPlaceHolder?: boolean
}

export const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  autoFocus = true,
  isPlaceHolder = false,
}) => {
  const navigation = useNavigation()
  const didAutoFocus = useRef(false)
  const [query, setQuery] = useState('')
  const inputRef = useRef<TextInput>(null)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)
  const util = trpc.useUtils().user.search
  const isScreenFocused = useIsFocused()
  const router = useRouter()

  useEffect(() => {
    const focusInput = () => {
      if (isScreenFocused && autoFocus && !didAutoFocus.current) {
        inputRef.current?.focus()
      }
    }

    if (isScreenFocused) {
      if (Platform.OS === 'web' && autoFocus && !didAutoFocus.current) {
        focusInput()
        didAutoFocus.current = true
      } else {
        // @ts-expect-error TODO: DO NOT REMOVE, CI FAILS OTHERWISE type transitionEnd is missing in the type definitions, but it exists
        const unsubscribe = navigation.addListener('transitionEnd', focusInput)
        return unsubscribe
      }
    }
  }, [navigation.addListener, isScreenFocused, autoFocus])

  const debounce = useEventCallback((callback: (query: string) => void, delay: number) => {
    return (query: string) => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current)
      }
      timeoutId.current = setTimeout(() => {
        callback(query)
        timeoutId.current = null
      }, delay)
    }
  })

  const debouncedSearch = useEventCallback(
    debounce((query: string) => {
      util.cancel()
      onSearch?.(query)
    }, 600)
  )

  const handleQueryChange = useEventCallback((text: string) => {
    setQuery(text)
    debouncedSearch(text)
  })

  const handleCancel = useEventCallback(() => {
    if (router.canGoBack()) {
      router.back()
    } else {
      router.replace('/')
    }
  })

  const handlePress = useEventCallback(() => {
    if (isPlaceHolder) {
      router.navigate('/search')
    }
  })

  return (
    <LeanView className="flex-row items-center px-3 py-3.5 h-18">
      <LeanView className="pl-1 pr-3">
        <Pressable onPress={handleCancel} className="items-center justify-center" hitSlop={30}>
          <Image
            source={require('@my/ui/src/icons/back-icon.png')}
            style={{ width: 24, height: 24 }}
          />
        </Pressable>
      </LeanView>
      <Pressable onPress={handlePress} className="flex-1 h-12" hitSlop={0}>
        <LeanView
          pointerEvents={isPlaceHolder ? 'none' : 'auto'}
          className="flex-1 bg-zinc-800 rounded-xl justify-center h-12">
          <LeanView className="absolute left-3">
            <SearchIcon width={18} height={18} color="#8F8F8F" />
          </LeanView>

          <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            ref={inputRef}
            className="h-12 android:h-12 pl-10 text-white text-[16px]"
            placeholder="Search users, tokens, videocoins, etc."
            onChangeText={handleQueryChange}
            value={query}
            placeholderTextColor="#888"
            keyboardAppearance="dark"
            keyboardType="web-search"
            returnKeyType="search"
            clearButtonMode="always"
            cursorColor="red"
            selectionColor="red"
          />
        </LeanView>
      </Pressable>
    </LeanView>
  )
}
