import Svg, { Rect, Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
}

export function FarcasterIcon(props: Props) {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 225 225" fill="none" {...props}>
      <Rect width={225} height={225} rx={50} fill="#855DCD" />
      <Path
        d="M58 35h109v155h-16v-71h-.157c-1.768-19.623-18.26-35-38.343-35-20.083 0-36.575 15.377-38.343 35H74v71H58V35z"
        fill="#fff"
      />
      <Path
        d="M29 57l6.5 22H41v89a5 5 0 00-5 5v6h-1a5 5 0 00-5 5v6h56v-6a5 5 0 00-5-5h-1v-6a5 5 0 00-5-5h-6V57H29zM152 168a5 5 0 00-5 5v6h-1a5 5 0 00-5 5v6h56v-6a5 5 0 00-5-5h-1v-6a5 5 0 00-5-5V79h5.5l6.5-22h-40v111h-6z"
        fill="#fff"
      />
    </Svg>
  )
}
