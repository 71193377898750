import { toast } from '@my/ui'
import * as Clipboard from 'expo-clipboard'
import { useRouter } from 'expo-router'
import { useCallback, useMemo } from 'react'
import { Platform, Share as RNShare, type ShareAction } from 'react-native'

export const useShare = () => {
  const share = useCallback(async ({ url }: { url: string }): Promise<ShareAction> => {
    if (Platform.OS === 'web') {
      if (navigator.canShare?.({ url })) {
        await navigator.share({
          url,
        })
      } else {
        await Clipboard.setStringAsync(url)
        toast.utils.copyToClipboard.success()
      }
      return {
        action: 'sharedAction',
        activityType: 'web',
      }
    }

    const result = await RNShare.share({
      url,
    })

    return result
  }, [])

  return useMemo(
    () => ({
      share,
      canShare: Platform.OS === 'web' ? navigator.canShare : true,
    }),
    [share]
  )
}

export type ShareType =
  | {
      type: 'post'
      data: {
        postId: string
        username: string
      }
    }
  | {
      type: 'user'
      data: {
        username: string
      }
    }
  | {
      type: 'token'
      data: {
        address: string
      }
    }

export const useShareSheet = () => {
  const router = useRouter()
  const share = useCallback(
    async (params: ShareType) => {
      if (params.type === 'post') {
        router.push(
          `/share?postId=${params.data.postId}&username=${params.data.username}&type=${params.type}`
        )
      } else if (params.type === 'user') {
        router.push(`/share?username=${params.data.username}&type=${params.type}`)
      } else if (params.type === 'token') {
        router.push(`/share?address=${params.data.address}&type=${params.type}`)
      }
    },
    [router]
  )

  return useMemo(
    () => ({
      share,
      canShare: Platform.OS === 'web' ? navigator.canShare : true,
    }),
    [share]
  )
}
