import { skipToken, useQuery } from '@tanstack/react-query'
import { trpc } from 'app/utils/api'
import { chain } from 'app/utils/chain'
import { publicClient } from 'app/utils/viem-public-client'
import { erc20Abi, type Address } from 'viem'
import type { Token } from '../../select-token/hooks/use-fetch-tokens'
import { ethToken, usdcToken } from '../constants'

export const useGetTokenInfo = ({ tokenAddress }: { tokenAddress?: Address }) => {
  const trpcUtils = trpc.useUtils()

  return useQuery({
    queryKey: ['get-from-to-token-for-buy', tokenAddress],
    queryFn: tokenAddress
      ? async () => {
          let token: Token | undefined
          if (tokenAddress.toLowerCase() === usdcToken.address.toLowerCase()) {
            token = usdcToken
          } else if (tokenAddress.toLowerCase() === '0x') {
            token = ethToken
          } else {
            const tokenStats = await trpcUtils.tokenContract.getTokenStats.fetch([
              {
                address: tokenAddress,
                networkId: chain.id,
              },
            ])
            const tokenStat = tokenStats?.results?.[0]
            if (tokenStat?.token) {
              token = {
                address: tokenStat?.token?.address as Address,
                chainId: chain.id,
                decimals: tokenStat.token.decimals,
                image: tokenStat.token.info?.imageSmallUrl ?? '',
                name: tokenStat.token.name ?? '',
                symbol: tokenStat.token.symbol ?? '',
              }
            } else {
              const [decimals, name, symbol] = await publicClient.multicall({
                contracts: [
                  {
                    abi: erc20Abi,
                    address: tokenAddress,
                    functionName: 'decimals',
                  },
                  {
                    abi: erc20Abi,
                    address: tokenAddress,
                    functionName: 'name',
                  },
                  {
                    abi: erc20Abi,
                    address: tokenAddress,
                    functionName: 'symbol',
                  },
                ],
              })
              if (
                decimals.status === 'success' &&
                name.status === 'success' &&
                symbol.status === 'success'
              ) {
                token = {
                  address: tokenAddress as Address,
                  chainId: chain.id,
                  decimals: decimals.result,
                  image: '',
                  name: name.result,
                  symbol: symbol.result,
                }
              }
            }
          }

          return token
        }
      : skipToken,
  })
}
