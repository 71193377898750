import { Button, Text, View } from '@my/ui'
import { Image } from 'expo-image'

export const TokenGraphError = ({ reset }: { reset: () => void }) => {
  return (
    <View className="pt-4 px-2 items-center gap-3">
      <View style={{ width: 150, height: 150 }}>
        <Image
          style={{ width: '100%', height: '100%' }}
          source={{ uri: 'https://drakula-media.b-cdn.net/assets/drakula-error.webp' }}
          priority="high"
          contentFit="cover"
        />
      </View>
      <View className="gap-3">
        <View className="gap-0">
          <Text className="text-2xl text-white font-bold text-center">Oh no! We messed up.</Text>
          <Text className="text-lg text-white text-center">We couldn't load the chart data.</Text>
        </View>
        <View className="flex-row justify-center">
          <Button variant="outline" onPress={reset} className="rounded-lg">
            Retry
          </Button>
        </View>
      </View>
    </View>
  )
}
