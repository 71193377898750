import { getTwitterIntent } from 'app/utils/share/helpers'
import * as Clipboard from 'expo-clipboard'
import { Image } from 'expo-image'
import { Linking as RNLinking } from 'react-native'
import { View } from './components/view'
import { XLogo } from './icons/x-logo'
import { toast as sonnerToast } from './sonner'

type LeafPaths<T, K extends keyof T = keyof T> = K extends string | number
  ? // biome-ignore lint/suspicious/noExplicitAny: it's fine because this is sourcery
    T[K] extends (...args: any[]) => any
    ? `${K}`
    : T[K] extends object
      ? `${K}-${LeafPaths<T[K]>}`
      : never
  : never

// we use this path for dimissing toasts in a type-safe way. For this to work, the id of a toast must always be the full object path in kebab case
// for example: toast.video.upload.started() must have this id: "video-upload-started"
type ToastPaths = LeafPaths<typeof toast>

export const dismiss = (toast: ToastPaths) => sonnerToast.dismiss(toast)

type Options = {
  message?: string
  description?: string
  action?: () => void
}

export const toast = {
  login: {
    success: (options?: Options) => {
      sonnerToast.success(options?.message ?? 'Login successful.', {
        description: options?.description,
      })
    },
    error: (options?: Options) => {
      sonnerToast.error(options?.message ?? 'Login failed. Please try again.', {
        description: options?.description,
      })
    },
  },
  deposit: {
    success: (options?: Options) => {
      sonnerToast.success(options?.message ?? 'Deposit successful!', {
        description: options?.description,
      })
    },
  },
  video: {
    upload: {
      started: (options?: Options) => {
        sonnerToast.loading(options?.message ?? 'Uploading video. Please wait...', {
          description: options?.description,
          id: 'video-upload-started',
        })
      },
      success: (options?: Options) => {
        dismiss('video-upload-started')
        sonnerToast.success(options?.message ?? 'Video uploaded successfully.', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Upload failed. Please try again.', {
          description: options?.description,
        })
      },
    },
    report: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Video report submitted successfully.', {
          description: options?.description ?? 'Thank you for helping to keep our community safe.',
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Report failed. Please try again.', {
          description: options?.description,
        })
      },
    },
    delete: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Successfully deleted video', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to delete video', {
          description: options?.description ?? 'Please try again.',
        })
      },
    },
    vote: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to up-/downvote video', {
          description: options?.description ?? 'Please try again.',
        })
      },
    },
  },
  wallet: {
    link: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to link Coinbase Smart Wallet.', {
          description:
            options?.description ??
            'Please contact us on X or Farcaster so we can help you fix the issue.',
        })
      },
    },
    withdraw: {
      success: (options?: Options & { transactionHash?: string }) => {
        sonnerToast.success(options?.message ?? 'Withdrawal complete!', {
          description: options?.description,
          action: options?.transactionHash?.length
            ? {
                label: 'Copy transaction hash',
                onClick: async () => {
                  if (options?.transactionHash) {
                    await Clipboard.setStringAsync(options?.transactionHash)
                    toast.utils.copyToClipboard.success()
                  }
                },
              }
            : undefined,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Withdrawal failed. Please try again.', {
          description: options?.description,
        })
      },
    },
  },
  swaps: {
    select: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to select token. Please try again.', {
          description: options?.description,
        })
      },
    },
    buy: {
      pending: (options?: Options) => {
        sonnerToast.loading(
          options?.message ?? 'Transaction pending. Please check again in a few minutes.',
          {
            description: options?.description,
            duration: 5000,
          }
        )
      },
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Token bought successfully.', {
          description: options?.description,
          action: {
            label: 'Share on X',
            onClick: async () => {
              const intent = getTwitterIntent({
                url: 'https://x.com/AlexMasmej/status/1841478649427448192',
                message: `Just traded on @drakulaapp`,
              })
              RNLinking.openURL(intent)
            },
          },
        })
      },
      notEnoughBalance: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Not enough balance', {
          description: options?.description ?? 'Please deposit more USDC.',
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Buy failed. Please try again.', {
          description: options?.description,
        })
      },
    },
    sell: {
      pending: (options?: Options) => {
        sonnerToast.loading(
          options?.message ?? 'Transaction pending. Please check again in a few minutes.',
          {
            description: options?.description,
            duration: 5000,
          }
        )
      },
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Token sold', {
          description: options?.description ?? "Congrats! You've successfully sold the token.",
          action: {
            label: 'Share on X',
            onClick: async () => {
              const intent = getTwitterIntent({
                url: 'https://x.com/AlexMasmej/status/1841478649427448192',
                message: 'Just traded on @drakulaapp',
              })
              RNLinking.openURL(intent)
            },
          },
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Sell failed. Please try later again.', {
          description: options?.description,
        })
      },
    },
  },
  creatorTokens: {
    sell: {
      success: (options?: Options & { transactionHash?: string }) => {
        sonnerToast.success(options?.message ?? 'Token sold successfully.', {
          description: options?.description ?? 'Congrats! You sold the token.',
          action: options?.transactionHash
            ? {
                label: 'Copy transaction hash',
                onClick: async () => {
                  if (options?.transactionHash) {
                    await Clipboard.setStringAsync(options?.transactionHash)
                    toast.utils.copyToClipboard.success()
                  }
                },
              }
            : undefined,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Sell failed. Please try again.', {})
      },
    },
  },
  zora: {
    presale: {
      buy: {
        success: (options?: Options) => {
          sonnerToast.success(options?.message ?? 'Presale mint successful.', {
            description: options?.description,
          })
        },
      },
      sell: {
        success: (options?: Options) => {
          sonnerToast.success(options?.message ?? 'Presale sell successful.', {
            description: options?.description,
          })
        },
        error: (options?: Options) => {
          sonnerToast.error(options?.message ?? 'Presale sell failed. Please try again.', {
            description: options?.description,
          })
        },
      },
    },
    create: {
      started: (options?: Options) => {
        sonnerToast.loading(options?.message ?? 'Creating Zora Mint. Please wait...', {
          id: 'zora-create-started',
          description: options?.description,
          // if we are done sooner, we dismiss programmatically via id
          duration: 5000,
        })
      },
      success: (options?: Options) => {
        dismiss('zora-create-started')
        sonnerToast.success(options?.message ?? 'Zora mint creation complete.', {
          description: options?.description ?? 'Congratulations! Your mint is live on Zora.',
        })
      },
    },
    mint: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to mint. Please try again.', {
          description: options?.description,
        })
      },
    },
    reward: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to reward user.', {
          description: options?.description,
        })
      },
    },
    collectReward: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Successfully collected rewards.', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to collect rewards. Please try again.', {
          description: options?.description,
        })
      },
    },
  },
  profile: {
    update: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Successfully updated profile.', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to update profile. Please try again.', {
          description: options?.description,
        })
      },
      farcaster: {
        loading: (options?: Options) => {
          sonnerToast.loading('Updating profile with Farcaster details...', {
            id: 'profile-update-farcaster-loading',
            description: options?.description,
            // we want to programmatically dismiss is, so we increase its duration
            duration: 5000,
          })
        },
        error: (options?: Options) => {
          sonnerToast.error(
            options?.message ?? 'Could not update profile with Farcaster details.',
            {
              ...farcasterToastOptions,
              description: options?.description,
            }
          )
        },
      },
      twitter: {
        loading: (options?: Options) => {
          sonnerToast.loading('Updating profile with X details...', {
            id: 'profile-update-twitter-loading',
            description: options?.description,
            // we want to programmatically dismiss is, so we increase its duration
            duration: 5000,
          })
        },
        error: (options?: Options) => {
          sonnerToast.error(options?.message ?? 'Could not update profile with X details.', {
            ...twitterToastOptions,
            description: options?.description,
          })
        },
      },
    },
    delete: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Successfully deleted account.', {
          description: options?.description,
        })
      },
      invalid: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Wrong Input', {
          description:
            options?.description ?? "Account wasn't deleted. Please provide valid input.",
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to delete account.', {
          description: options?.description ?? "Account wasn't deleted. Please try again.",
        })
      },
    },
    link: {
      twitter: {
        success: (options?: Options) => {
          sonnerToast.success(options?.message ?? 'Successfully linked X.', {
            id: 'profile-link-twitter-success',
            ...twitterToastOptions,
            description: options?.description,
            ...(options?.action
              ? {
                  action: {
                    label: 'Yes, update profile',
                    onClick: options?.action,
                  },
                  cancel: {
                    label: 'Cancel',
                    onClick: () => {
                      dismiss('profile-link-twitter-success')
                    },
                  },
                  duration: Number.POSITIVE_INFINITY,
                }
              : {}),
          })
        },
        loading: (options?: Options) => {
          sonnerToast.loading('Linking X account...', {
            id: 'profile-link-twitter-loading',
            description: options?.description ?? 'Follow the X auth flow to link your account.',
            // we want to programmatically dismiss is, so we increase its duration
            duration: 5000,
          })
        },
        error: (options?: Options) => {
          sonnerToast.error(options?.message ?? 'Failed to link X. Please try again.', {
            ...twitterToastOptions,
            description: options?.description,
          })
        },
      },
      farcaster: {
        success: (options?: Options) => {
          sonnerToast.success(options?.message ?? 'Successfully linked Farcaster.', {
            id: 'profile-link-farcaster-success',
            ...farcasterToastOptions,
            description: options?.description,
            ...(options?.action
              ? {
                  action: {
                    label: 'Yes, update profile',
                    onClick: options?.action,
                  },
                  cancel: {
                    label: 'Cancel',
                    onClick: () => {
                      dismiss('profile-link-farcaster-success')
                    },
                  },
                  duration: Number.POSITIVE_INFINITY,
                }
              : {}),
          })
        },
        loading: (options?: Options) => {
          sonnerToast.loading('Linking Farcaster account...', {
            id: 'profile-link-twitter-loading',
            description:
              options?.description ?? 'Follow instructions on Warpcast to link your account.',
            // we want to programmatically dismiss is, so we increase its duration
            duration: 5000,
          })
        },
        error: (options?: Options) => {
          sonnerToast.error(options?.message ?? 'Failed to link Farcaster. Please try again.', {
            ...farcasterToastOptions,
            description: options?.description,
          })
        },
      },
    },
  },
  user: {
    block: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Successfully blocked user.', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to block user. Please try again.', {
          description: options?.description,
        })
      },
    },
    unblock: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Successfully unblocked user.', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to unblock user. Please try again.', {
          description: options?.description,
        })
      },
    },
  },
  comments: {
    delete: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to delete. Please try again later.', {
          description: options?.description,
        })
      },
    },
    report: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Comment report submitted successfully.', {
          description: options?.description ?? 'Thank you for helping to keep our community safe.',
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to report. Please try again later.', {
          description: options?.description,
        })
      },
    },
  },
  utils: {
    copyToClipboard: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Link copied to clipboard', {
          description: options?.description,
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to copy link', {
          description: options?.description,
        })
      },
    },
    tradeRiskDisclaimer: {
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to save. Please try again.', {
          description: options?.description,
        })
      },
    },
  },
  farcaster: {
    connectForCrosspost: {
      success: (options?: Options) => {
        sonnerToast.success(options?.message ?? 'Farcaster connected successfully.', {
          description: options?.description ?? 'Your video will be crossposted on Farcaster.',
        })
      },
      error: (options?: Options) => {
        sonnerToast.error(options?.message ?? 'Failed to link Farcaster. Please try again.', {
          description: options?.description,
        })
      },
    },
  },
  withdraw: {
    error: (options?: Options) => {
      sonnerToast.error(options?.message ?? 'Withdrawal failed. Please try again.', {
        description: options?.description,
      })
    },
    success: (options?: Options) => {
      sonnerToast.success(options?.message ?? 'Withdrawal successful', {
        description: options?.description,
      })
    },
  },
}

const twitterToastOptions = {
  className: 'gap-3',
  icon: (
    <View className="flex-1 pt-2">
      <XLogo color="white" height={18} width={18} />
    </View>
  ),
}

const farcasterToastOptions = {
  className: 'gap-3',
  icon: (
    <Image
      source={require('@my/ui/src/icons/farcaster-purple-white.svg')}
      style={{ width: 24, height: 24, borderRadius: 9999 }}
    />
  ),
}
