import { QueryClient } from '@tanstack/react-query'
import { isTRPCClientError } from 'app/utils/api'
import { persister } from 'app/utils/mmkv/storage'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 30000,
      persister,
      retry: (failureCount, error) => {
        // do not retry if there are already 3 failures
        if (failureCount >= 3) {
          return false
        }

        if (isTRPCClientError(error)) {
          // do not retry if the user is not logged in
          if (error.data?.httpStatus === 401) {
            return false
          }
          // do not retry if the user is not onboarded
          if (error.data?.httpStatus === 403) {
            return false
          }
        }

        return true
      },
    },
  },
})

export const resetQueries = async () => {
  await queryClient.cancelQueries().catch(() => {})
  // https://tanstack.com/query/v4/docs/reference/QueryClient/#queryclientresetqueries
  // Reset resets all the queries to initial state and triggers a refetch of active queries
  // We call it after logout so we're sure that the session token is gone
  queryClient.resetQueries().catch(() => {})
}
