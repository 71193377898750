import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function ShareIcon(props: Props) {
  return (
    <Svg
      width={props.width ?? 19}
      height={props.height ?? 15}
      viewBox="0 0 19 15"
      fill="none"
      {...props}>
      <Path
        d="M1.75 13.334c-.34.345-1.078.112-1.02-.37 1.079-8.952 9.59-8.952 9.59-8.952V.613c0-.396.468-.606.764-.343l7.43 6.605a.459.459 0 01.002.684l-7.43 6.663a.459.459 0 01-.765-.341v-3.555s-5.602 0-8.57 3.008z"
        fill={props.color}
      />
    </Svg>
  )
}
