import type { useSmartWallets } from '@privy-io/expo/smart-wallets'
import { createContext, useContext } from 'react'
import type { Address } from 'viem'

export type WalletClientType = ReturnType<typeof useSmartWallets>['client']

export const EmbeddedSmartWalletProviderContext = createContext({
  address: undefined as Address | undefined,
  walletClient: undefined as WalletClientType | undefined,
})

export const useEmbeddedSmartWallet = () => {
  return useContext(EmbeddedSmartWalletProviderContext)
}
