import { observable } from '@legendapp/state'

export const priceStore = observable({
  currentPrice: 0,
  initialPrice: 0,
  selectedDate: Date.now(),
  absoluteChange: () => {
    const currentPrice = priceStore.currentPrice.get()
    const initialPrice = priceStore.initialPrice.get()
    if (!currentPrice || !initialPrice) return 0
    return currentPrice - initialPrice
  },
  percentageChange: () => {
    const currentPrice = priceStore.currentPrice.get()
    const initialPrice = priceStore.initialPrice.get()
    if (!currentPrice || !initialPrice) return 0
    return ((currentPrice - initialPrice) / initialPrice) * 100
  },
  reset: (price: number) => {
    if (price !== priceStore.currentPrice.get()) {
      priceStore.selectedDate.set(Date.now())
      priceStore.currentPrice.set(price)
    }
  },
})
