import { Alert, MoreIcon, View } from '@my/ui'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { useCallback } from 'react'
import { Platform } from 'react-native'
import * as DropdownMenu from 'zeego/dropdown-menu'
import { useBlockUser } from './hooks/use-block-user'

type Props = {
  userId?: string
  username?: string
  isBlockedByMe?: boolean
}

export const MoreOptionsProfileDropdown = (props: Props) => {
  const { blockUser, unblockUser } = useBlockUser({
    username: props.username,
  })
  const authStatus = useAuthenticationStatus()
  const toggleBlock = useCallback(() => {
    if (props.isBlockedByMe && props.userId) {
      unblockUser.mutate({ userId: props.userId })
    } else {
      Alert.alert('Block user?', `Are you sure you want to block @${props.username}?`, [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          style: 'destructive',
          text: 'Block user',
          onPress: async () => {
            if (!props.userId) return
            blockUser.mutate({
              userId: props.userId,
            })
          },
        },
      ])
    }
  }, [blockUser, props.userId, props.username, props.isBlockedByMe, unblockUser.mutate])

  if (authStatus !== 'authenticated') return null

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <View>
          <MoreIcon />
        </View>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="end"
        style={Platform.select({
          web: {
            backgroundColor: 'hsl(0, 0%, 13.6%)',
            padding: 16,
            gap: 16,
            borderRadius: 8,
            marginTop: 16,
          },
        })}>
        {props.isBlockedByMe ? (
          <DropdownMenu.Item key="b_unblock_user" onSelect={toggleBlock} destructive>
            <DropdownMenu.ItemTitle style={Platform.select({ web: { color: '#fff' } })}>
              Unblock user
            </DropdownMenu.ItemTitle>
          </DropdownMenu.Item>
        ) : (
          <DropdownMenu.Item key="b_block_user" onSelect={toggleBlock} destructive>
            <DropdownMenu.ItemTitle style={Platform.select({ web: { color: '#dc2626' } })}>
              Block user
            </DropdownMenu.ItemTitle>
          </DropdownMenu.Item>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
