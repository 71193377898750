import { MoreIcon, Pressable } from '@my/ui'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { useRouter } from 'expo-router'

export const PostMoreButton = ({ postId }: { postId: string }) => {
  const router = useRouter()
  const authStatus = useAuthenticationStatus()
  const isLoggedOut = authStatus !== 'authenticated'
  const redirectToSignIn = useRedirectToSignIn()

  const handlePress = useEventCallback(() => {
    if (isLoggedOut) {
      return redirectToSignIn()
    }
    router.navigate(`/video-actions?postId=${postId}`)
  })

  return (
    <Pressable onPress={handlePress} haptics hitSlop={8}>
      <MoreIcon />
    </Pressable>
  )
}
