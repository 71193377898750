import { useObservable } from '@legendapp/state/react'
import { useEventCallback } from 'app/hooks/use-event-callback'
import {
  ItemKeyContext,
  ViewabilityItemsContext,
  type ViewabilityItemsContextType,
} from 'app/utils/with-viewability-infinite-scroll-list'
import { useCallback, useContext, useRef } from 'react'
import { runOnJS, useAnimatedReaction } from 'react-native-reanimated'

type UseVideoVisibilityProps = {
  uri: string
  onItemInWindow?: (ctx: ViewabilityItemsContextType) => void
  onItemOutOfWindow?: () => void
  onItemInList?: (ctx: ViewabilityItemsContextType) => void
  onItemOutOfList?: () => void
}

export const useVideoVisibilityState = ({
  uri,
  onItemInWindow,
  onItemOutOfWindow,
  onItemInList,
  onItemOutOfList,
}: UseVideoVisibilityProps) => {
  const id = useContext(ItemKeyContext)
  const context = useContext(ViewabilityItemsContext)
  const isItemInList = typeof id !== 'undefined'
  const lastContextValue = useRef<ViewabilityItemsContextType>([])

  const state$ = useObservable({
    isInViewport: false,
  })

  const scheduleInstances = useEventCallback((ctx: ViewabilityItemsContextType) => {
    lastContextValue.current = ctx
    if (ctx && !ctx.includes(id)) {
      state$.isInViewport.set(false)
      onItemOutOfList?.()
    } else if (ctx) {
      onItemInList?.(ctx)
      if (ctx[1] === id) {
        onItemInWindow?.(ctx)
        state$.isInViewport.set(true)
      } else {
        onItemOutOfWindow?.()
        state$.isInViewport.set(false)
      }
    }
  })

  useAnimatedReaction(
    () => context.value,
    (ctx) => {
      if (isItemInList) {
        runOnJS(scheduleInstances)(ctx)
      }
    },
    [id, isItemInList, context, uri]
  )

  const isItemTrulyVisible = useCallback(() => {
    return state$.isInViewport.get()
  }, [state$.isInViewport])

  return {
    isItemTrulyVisible,
    visibilityState: state$,
  }
}
