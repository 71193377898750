import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function WebIcon(props: Props) {
  return (
    <Svg width={props.width ?? 26} height={props.height ?? 27} viewBox="0 0 26 27" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8193 0.509033C19.8658 0.509033 25.5781 6.22134 25.5781 13.2678C25.5781 20.3143 19.8658 26.0266 12.8193 26.0266C5.77285 26.0266 0.0605469 20.3143 0.0605469 13.2678C0.0605469 6.22134 5.77285 0.509033 12.8193 0.509033ZM16.6115 14.5449H9.02659C9.16813 17.1012 9.73034 19.4284 10.5905 21.1487C11.3916 22.7509 12.2477 23.4751 12.8191 23.4751C13.3905 23.4751 14.2465 22.7509 15.0476 21.1487C15.9078 19.4284 16.47 17.1012 16.6115 14.5449ZM22.9472 14.5449L19.1671 14.5453C19.0127 17.6507 18.3017 20.4284 17.2363 22.4736C20.2961 21.0017 22.5098 18.0492 22.9472 14.5449ZM6.47151 14.5453L2.69142 14.5449C3.12883 18.0492 5.34258 21.0016 8.40306 22.4726C7.40367 20.5561 6.71616 17.995 6.50693 15.1236L6.47151 14.5453ZM8.40242 4.06193L8.36905 4.0794C5.32595 5.55607 3.1269 8.49998 2.69142 11.9916L6.47158 11.9915C6.6258 8.88561 7.33693 6.10739 8.40242 4.06193ZM12.8191 3.06079L12.7154 3.06834C12.1472 3.14902 11.3446 3.8792 10.5906 5.38718C9.73026 7.10782 9.16802 9.4355 9.02659 11.9922H16.6117C16.4703 9.4355 15.908 7.10782 15.0477 5.38718C14.2466 3.78495 13.3905 3.06079 12.8191 3.06079ZM17.2357 4.06318L17.2645 4.11639C18.3145 6.1568 19.0146 8.91375 19.1673 11.9927L22.9475 11.9921C22.5105 8.48734 20.2965 5.53438 17.2357 4.06318Z"
        fill={props.color ?? 'white'}
      />
    </Svg>
  )
}
