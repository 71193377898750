import { mixpanel } from 'app/utils/tracking/mixpanel'

export const events = {
  TOKEN_BOUGHT: 'TOKEN_BOUGHT',
  TOKEN_SOLD: 'TOKEN_SOLD',
  SHARE_POST: 'SHARE_POST',
  SHARE_TOKEN: 'SHARE_TOKEN',
  SHARE_PROFILE: 'SHARE_PROFILE',
  VIDEO_WATCHED: 'VIDEO_WATCHED',
  FEED_SWIPE: 'FEED_SWIPE',
  POST_TIP: 'POST_TIP',
  POST_LIKE: 'POST_LIKE',
  VIDEO_UPLOADED: 'VIDEO_UPLOADED',
  VIDEO_UPLOAD_FAILED: 'VIDEO_UPLOAD_FAILED',
  SIGNED_IN: 'SIGNED_IN',
  WITHDRAWAL: 'WITHDRAWAL',
  COMMENT_ADDED: 'COMMENT_ADDED',
  COMMENT_LIKED: 'COMMENT_LIKED',
}

export const globalBalanceCache: { [key: string]: string } = {}

export const trackBalanceChange = (balance: string, currency: string, walletAddress: string) => {
  const key = walletAddress + currency + balance
  if (balance !== globalBalanceCache[key]) {
    mixpanel.getPeople().set({
      [`account_balance_${currency}`]: balance.toString(),
      walletAddress,
    })
    globalBalanceCache[currency] = balance
  }
}

type TrackTokenTradeArgs = {
  quantity: number
  currency?: string
  totalPrice?: string
  tokenContractAddress?: string | null
}

export const trackTokenBought = (args: TrackTokenTradeArgs & { location?: string }) => {
  mixpanel.track(events.TOKEN_BOUGHT, {
    quantity: args.quantity,
    currency: args.currency,
    totalPrice: args.totalPrice,
    tokenContractAddress: args.tokenContractAddress,
    location: args.location,
  })
}

export const trackTokenSold = (args: TrackTokenTradeArgs) => {
  mixpanel.track(events.TOKEN_SOLD, {
    quantity: args.quantity,
    currency: args.currency,
    totalPrice: args.totalPrice,
    tokenContractAddress: args.tokenContractAddress,
  })
}

export const trackWithdrawal = (args: { amount: string; balance?: string; token: string }) => {
  mixpanel.track(events.WITHDRAWAL, {
    amount: args.amount,
    balance: args.balance,
    token: args.token,
  })
}
