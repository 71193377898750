import { skipToken, useQuery } from '@tanstack/react-query'
import { wowAbi } from 'app/features/wow/abi'
import { publicClient } from 'app/utils/viem-public-client'
import { type Address } from 'viem'

export enum MarketType {
  BONDING_CURVE = 'BONDING_CURVE',
  UNISWAP_POOL = 'UNISWAP_POOL',
}

export const useTokenMarketState = (params?: { address: Address; isWowToken?: boolean }) => {
  return useQuery({
    queryKey: ['wow-token-market-state', params],
    persister: undefined,
    queryFn: params
      ? async () => {
          if (!params.isWowToken) {
            return MarketType.UNISWAP_POOL
          }

          const state = (await publicClient.readContract({
            address: params.address,
            abi: wowAbi,
            functionName: 'state',
            args: [],
          })) as {
            marketType: 0 | 1
            marketAddress: Address
          }
          if (state.marketType === 0) {
            return MarketType.BONDING_CURVE
          }

          return MarketType.UNISWAP_POOL
        }
      : skipToken,
  })
}
