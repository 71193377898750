import { LeanView, Text } from '@my/ui'
import { Image } from 'expo-image'
import { useLocalSearchParams } from 'expo-router'
import { useUserByUsername } from '../profile/hooks/use-user-by-username'
import type { SearchParams } from './types'

export const HeaderLeft = () => {
  const searchParam = useLocalSearchParams<SearchParams>()
  const user = useUserByUsername({ username: searchParam.username })

  return searchParam.username ? (
    <LeanView className="flex-row gap-1 items-center">
      <Image
        source={require('@my/ui/src/icons/pin-icon.svg')}
        style={{ height: 14, width: 13 }}
        contentFit="contain"
      />
      <Image
        source={
          user.data?.userDetails.profileImage
            ? {
                uri: user.data.userDetails.profileImage,
              }
            : undefined
        }
        style={{
          height: 24,
          width: 24,
          borderRadius: 99,
        }}
      />
      <Text className="text-grayTextColor text-sm">@{user.data?.userDetails.username}</Text>
    </LeanView>
  ) : null
}
