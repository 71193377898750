import Ionicons from '@expo/vector-icons/Ionicons'
import { View } from '@my/ui'
import { usePrivySignOut } from 'app/utils/auth/use-privy-sign-out'
import { Platform } from 'react-native'
import * as DropdownMenu from 'zeego/dropdown-menu'

export const ProfileHeaderRight = () => {
  const signOut = usePrivySignOut()

  return (
    // on web, we need to provide padding to react navigation's headerRight/headerLeft ourselves:
    <View className="web:pr-4 web:cursor-pointer">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <View>
            <Ionicons name="settings-outline" size={20} color="white" />
          </View>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          align="end"
          style={Platform.select({
            web: {
              backgroundColor: 'hsl(0, 0%, 13.6%)',
              padding: 16,
              gap: 16,
              borderRadius: 8,
              marginTop: 16,
            },
          })}>
          <DropdownMenu.Item key="b_sign_out" onSelect={signOut} destructive>
            <DropdownMenu.ItemTitle style={Platform.select({ web: { color: '#dc2626' } })}>
              Sign out
            </DropdownMenu.ItemTitle>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </View>
  )
}
