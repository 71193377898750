import { LeanView, theme } from '@my/ui'
import { Header } from '@react-navigation/elements'
import type { MaterialTopTabNavigationOptions } from '@react-navigation/material-top-tabs'
import type {
  NativeStackHeaderProps,
  NativeStackNavigationOptions,
} from '@react-navigation/native-stack'
import { Platform } from 'react-native'

const CustomHeader = (props: NativeStackHeaderProps) => {
  return (
    <>
      <LeanView
        pointerEvents="box-none"
        className="web:w-full web:lg:max-w-2xl web:lg:-translate-x-1/2 web:lg:left-1/2 web:z-20">
        <Header {...props} {...props.options} title={props.options.title ?? ''} />
      </LeanView>
      {!props.options.headerTransparent && (
        <LeanView className="bg-appDarkBg w-full h-full absolute top-0 left-0" />
      )}
    </>
  )
}

export const defaultStackScreenOptions: NativeStackNavigationOptions = {
  statusBarStyle: 'light',
  navigationBarColor: '#00000000',
  fullScreenGestureEnabled: true,
  fullScreenGestureShadowEnabled: true,
  headerBackButtonDisplayMode: 'minimal',
  contentStyle: {
    backgroundColor: theme.colors.appDarkBg,
  },
  headerTitleStyle: {
    fontSize: 16,
  },
  headerBackButtonMenuEnabled: false,
  headerTintColor: theme.colors.headerTintColor,
  headerStyle: {
    ...Platform.select({
      web: {
        backgroundColor: theme.colors.appDarkBg,
        height: 50,
        width: '100%',
        maxWidth: '50rem',
        margin: 'auto',
      },
    }),
  },
  //header: Header,
  headerTitleAlign: 'left',
  header: Platform.OS === 'web' ? CustomHeader : undefined,
}

export const defaultMaterialTopTabsScreenOptions: MaterialTopTabNavigationOptions = {
  tabBarIndicatorStyle: { backgroundColor: 'white' },
  tabBarStyle: {
    backgroundColor: theme.colors.appDarkBg,
  },
  tabBarActiveTintColor: 'white',
  tabBarLabelStyle: {
    fontSize: 15,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  tabBarContentContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}
