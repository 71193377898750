import { useNavigationContainerRef } from 'expo-router'
import { useRef } from 'react'
import { useEventCallback } from '../hooks/use-event-callback'

function useNavigationReady() {
  const navigationRef = useNavigationContainerRef()
  const hasExecuted = useRef(false)

  const startNavigationCheck = useEventCallback((callback: () => void) => {
    if (hasExecuted.current) return

    let intervalId: NodeJS.Timeout

    const checkNavigationReady = () => {
      if (navigationRef.isReady() && !hasExecuted.current) {
        clearInterval(intervalId)
        hasExecuted.current = true

        if (typeof callback === 'function') {
          callback()
        } else {
          console.warn('Provided callback is not a function.')
        }
      }
    }

    if (navigationRef.isReady()) {
      callback()
      hasExecuted.current = true
    } else {
      intervalId = setInterval(checkNavigationReady, 100)
    }

    return () => clearInterval(intervalId)
  })

  return startNavigationCheck
}

export default useNavigationReady
