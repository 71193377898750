import Ionicons from '@expo/vector-icons/Ionicons'
import { useMuted } from '../../provider/mute-provider'
import type { MuteRef } from './types'

type MuteButtonProps = {
  muteRef: React.MutableRefObject<MuteRef>
}

export const MuteButton = ({ muteRef }: MuteButtonProps) => {
  const { muted } = useMuted()

  return (
    <button
      type="button"
      className="bg-gray-200/70 w-8 h-7 native:w-10 native:h-8 flex items-center justify-center rounded-md z-20"
      onClick={(event) => {
        event.stopPropagation()
        if (muted) {
          muteRef.current?.unmute()
        } else {
          muteRef.current?.mute()
        }
      }}>
      {muted ? (
        <Ionicons name="volume-mute" color="black" size={18} />
      ) : (
        <Ionicons name="volume-high" color="black" size={20} />
      )}
    </button>
  )
}
