import Svg, { Path, Circle } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function UserIcon(props: Props) {
  return (
    <Svg width={props.width ?? 19} height={props.height ?? 20} viewBox="0 0 19 20" fill="none">
      <Path
        d="M17.132 18.162c.552-.115.882-.694.607-1.187-.605-1.088-1.56-2.044-2.78-2.772-1.572-.938-3.498-1.447-5.479-1.447-1.981 0-3.907.509-5.479 1.447-1.22.728-2.174 1.684-2.78 2.772-.274.493.055 1.072.608 1.187a37.503 37.503 0 0015.303 0z"
        fill={props.color}
      />
      <Circle cx={9.48022} cy={5.75635} r={5} fill={props.color} />
    </Svg>
  )
}
