import { observer, useSelector } from '@legendapp/state/react'
import { LeanView, Text } from '@my/ui'
import { formatNumberForPrice, roundToFirstNonZero } from 'app/utils/common'
import dayjs from 'dayjs'
import { PriceChange } from './price-change'
import { priceStore } from './price-store'

export const TokenPrice = observer(function TokenPrice() {
  const initialPrice = useSelector(() => formatNumberForPrice(priceStore.initialPrice.get(), 3))
  const currentPrice = useSelector(() => formatNumberForPrice(priceStore.currentPrice.get(), 3))
  const selectedDate = useSelector(() =>
    dayjs(priceStore.selectedDate.get()).format('h:mm A MMM D')
  )
  const absoluteChange = priceStore.absoluteChange()
  const percentageChange = priceStore.percentageChange()

  return (
    <LeanView className="px-6 gap-1">
      <Text
        className="text-5xl text-white font-semibold"
        adjustsFontSizeToFit
        numberOfLines={1}>{`$${currentPrice || initialPrice}`}</Text>
      <LeanView className="flex-row items-center gap-6">
        <LeanView className="flex-shrink flex-grow-0">
          <PriceChange
            absoluteChange={roundToFirstNonZero(absoluteChange)}
            percentageChange={percentageChange ? percentageChange : null}
          />
        </LeanView>
        <LeanView className="flex-grow-0">
          <Text className="text-sm text-grayTextColor font-semibold">{selectedDate}</Text>
        </LeanView>
      </LeanView>
    </LeanView>
  )
})
