// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(app)/(follow)/_layout.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(follow)/_layout.tsx"); } },
  "./(app)/(follow)/followers/[username].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(follow)/followers/[username].tsx"); } },
  "./(app)/(follow)/following/[username].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(follow)/following/[username].tsx"); } },
  "./(app)/(sheets)/_layout.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/_layout.tsx"); } },
  "./(app)/(sheets)/buy-presale.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/buy-presale.tsx"); } },
  "./(app)/(sheets)/buy/[address].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/buy/[address].tsx"); } },
  "./(app)/(sheets)/channel-select.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/channel-select.tsx"); } },
  "./(app)/(sheets)/comments.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/comments.tsx"); } },
  "./(app)/(sheets)/crypto-deposit.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/crypto-deposit.tsx"); } },
  "./(app)/(sheets)/deposit.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/deposit.tsx"); } },
  "./(app)/(sheets)/explanation.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/explanation.tsx"); } },
  "./(app)/(sheets)/farcaster-crossposting.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/farcaster-crossposting.tsx"); } },
  "./(app)/(sheets)/mobile-wallet-protocol.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/mobile-wallet-protocol.tsx"); } },
  "./(app)/(sheets)/select-token.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/select-token.tsx"); } },
  "./(app)/(sheets)/sell-secondary.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sell-secondary.tsx"); } },
  "./(app)/(sheets)/sell/[address].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sell/[address].tsx"); } },
  "./(app)/(sheets)/share.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/share.tsx"); } },
  "./(app)/(sheets)/sign-in/email/index.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/email/index.tsx"); } },
  "./(app)/(sheets)/sign-in/email/otp.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/email/otp.tsx"); } },
  "./(app)/(sheets)/sign-in/index.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/index.tsx"); } },
  "./(app)/(sheets)/sign-in/more-options.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/more-options.tsx"); } },
  "./(app)/(sheets)/sign-in/sms/country-code.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/sms/country-code.tsx"); } },
  "./(app)/(sheets)/sign-in/sms/index.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/sms/index.tsx"); } },
  "./(app)/(sheets)/sign-in/sms/otp.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/sign-in/sms/otp.tsx"); } },
  "./(app)/(sheets)/success.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/success.tsx"); } },
  "./(app)/(sheets)/video-actions.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/video-actions.tsx"); } },
  "./(app)/(sheets)/withdraw.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/(sheets)/withdraw.tsx"); } },
  "./(app)/_layout.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/_layout.tsx"); } },
  "./(app)/create.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/create.tsx"); } },
  "./(app)/edit-profile.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/edit-profile.tsx"); } },
  "./(app)/explore.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/explore.tsx"); } },
  "./(app)/history.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/history.tsx"); } },
  "./(app)/index.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/index.tsx"); } },
  "./(app)/notifications.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/notifications.tsx"); } },
  "./(app)/portfolio.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/portfolio.tsx"); } },
  "./(app)/post/[postId].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/post/[postId].tsx"); } },
  "./(app)/presales.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/presales.tsx"); } },
  "./(app)/profile.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/profile.tsx"); } },
  "./(app)/search.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/search.tsx"); } },
  "./(app)/swipe-list.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/swipe-list.tsx"); } },
  "./(app)/token/[address]/holders.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/token/[address]/holders.tsx"); } },
  "./(app)/token/[address]/index.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/token/[address]/index.tsx"); } },
  "./(app)/tokens.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/tokens.tsx"); } },
  "./(app)/traders.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/traders.tsx"); } },
  "./(app)/trending-creators.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/trending-creators.tsx"); } },
  "./(app)/user/[username].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/(app)/user/[username].tsx"); } },
  "./[...unmatched].tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/[...unmatched].tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/_layout.tsx"); } },
  "./_sitemap.tsx": { enumerable: true, get() { return import("/codebuild/output/src3211542507/src/apps/expo/app/_sitemap.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;