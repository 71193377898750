import { type GetSwapQuoteResponse } from '@coinbase/onchainkit/api'
// @ts-ignore
import { getSwapQuote } from '@coinbase/onchainkit/esm/api'
import { skipToken, useQuery } from '@tanstack/react-query'
import { wowAbi } from 'app/features/wow/abi'
import { chain } from 'app/utils/chain'
import { publicClient } from 'app/utils/viem-public-client'
import type { Address } from 'viem'
import { ethToken, usdcToken } from '../constants'
import { MarketType, useTokenMarketState } from './use-token-market-state'
import { useUsdQuote } from './use-usd-quote'

export const useWowTokenUsdPrice = (params?: {
  amount: bigint
  address: Address
}) => {
  const ethPrice = useUsdQuote({
    amount: 10n ** 18n,
    token: ethToken,
  })
  const wowTokenMarketType = useTokenMarketState(
    params ? { address: params.address, isWowToken: true } : undefined
  )
  const query = useQuery({
    queryKey: ['wow-token-price', params, ethPrice.data?.toAmount, wowTokenMarketType.data],
    queryFn:
      params && wowTokenMarketType.data && ethPrice.data
        ? async () => {
            const tokensToBuy = params?.amount
            const address = params?.address

            if (wowTokenMarketType.data === MarketType.BONDING_CURVE) {
              // Bonding curve market
              const ethAmount = (await publicClient.readContract({
                address: address,
                abi: wowAbi,
                functionName: 'getTokenBuyQuote',
                args: [tokensToBuy],
              })) as bigint

              if (ethPrice.data) {
                const quote = {
                  quote: {
                    toAmount: (
                      (ethAmount * BigInt(ethPrice.data.toAmount)) /
                      10n ** 18n
                    ).toString(),
                  },
                }
                return quote
              }
            } else {
              const response = (await getSwapQuote({
                from: {
                  address,
                  chainId: chain.id,
                  decimals: 18,
                  image: '',
                  name: '',
                  symbol: '',
                },
                to: usdcToken,
                amount: tokensToBuy.toString(),
                useAggregator: false,
              })) as GetSwapQuoteResponse

              if ('error' in response) {
                throw new Error(response.code)
              }

              return {
                quote: {
                  toAmount: response.toAmount,
                },
              }
            }
          }
        : skipToken,
  })

  return query
}
