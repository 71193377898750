import type { BuildSwapTransactionParams } from '@coinbase/onchainkit/api'
// @ts-expect-error
import { buildSwapTransaction } from '@coinbase/onchainkit/esm/api'
import type { BuildSwapTransactionResponse } from '@coinbase/onchainkit/swap'
import { skipToken, useQuery } from '@tanstack/react-query'
import { wowAbi } from 'app/features/wow/abi'
import { useEmbeddedSmartWallet } from 'app/provider/embedded-smart-wallet-provider/context'
import { publicClient } from 'app/utils/viem-public-client'
import type { Address } from 'viem'
import { encodeFunctionData, parseAbi, parseUnits } from 'viem'
import { ethToken, usdcToken } from '../constants'
import { PERMINT2_CONTRACT_ADDRESS, UNIVERSALROUTER_CONTRACT_ADDRESS } from './constants'
import { MarketType, useTokenMarketState } from './use-token-market-state'

const orderReferrer = process.env.EXPO_PUBLIC_CREATOR_TOKEN_ADMIN_ADDRESS

type Params = {
  fromAmount: string
  isWowToken?: boolean
  fromToken: {
    address: Address
    chainId: number
    decimals: number
  }
  toToken: {
    address: Address
    chainId: number
    decimals: number
  }
}

const maxSlippage = 15

export const useBuySwapQuote = (params?: Params) => {
  const embeddedSmartWallet = useEmbeddedSmartWallet()
  const wowTokenMarketType = useTokenMarketState(
    params ? { address: params.toToken.address, isWowToken: params.isWowToken } : undefined
  )

  const query = useQuery({
    queryKey: ['buy-swap-quote', params, wowTokenMarketType.data],
    queryFn:
      params && embeddedSmartWallet?.address && wowTokenMarketType.data
        ? async () => {
            let wasBoughtFromWowContract = false

            const calls = []
            // If wow token and bonding curve market, buy from contract
            if (params.isWowToken) {
              let ethAmount = 0n

              let tokensToBuy = 0n
              // Bonding curve market. Buy needs to happen from contract
              if (wowTokenMarketType.data === MarketType.BONDING_CURVE) {
                wasBoughtFromWowContract = true
                // User selected USDC as the currency. Need to swap to ETH first as the bonding curve is ETH only
                if (params.fromToken.address === usdcToken.address) {
                  const buildSwapTxRequest: BuildSwapTransactionParams = {
                    fromAddress: embeddedSmartWallet.address as `0x${string}`,
                    from: usdcToken,
                    to: ethToken,
                    amount: params.fromAmount,
                    useAggregator: false,
                  }
                  const response = (await buildSwapTransaction(
                    buildSwapTxRequest
                  )) as BuildSwapTransactionResponse

                  if ('error' in response) {
                    throw new Error(response.code)
                  }

                  const { approveTransaction, transaction, quote, fee } = response

                  if (approveTransaction?.data) {
                    calls.push({
                      to: approveTransaction.to,
                      data: approveTransaction.data,
                      value: approveTransaction.value,
                    })

                    const permint2ContractAbi = parseAbi([
                      'function approve(address token, address spender, uint160 amount, uint48 expiration) external',
                    ])

                    const data = encodeFunctionData({
                      abi: permint2ContractAbi,
                      functionName: 'approve',
                      args: [
                        quote.from.address as `0x${string}`,
                        UNIVERSALROUTER_CONTRACT_ADDRESS,
                        BigInt(quote.fromAmount),
                        20_000_000_000_000, // The deadline where the approval is no longer valid - see https://docs.uniswap.org/contracts/permit2/reference/allowance-transfer
                      ],
                    })

                    calls.push({
                      to: PERMINT2_CONTRACT_ADDRESS as `0x${string}`,
                      data: data,
                      value: 0n,
                    })
                  }

                  calls.push({
                    to: transaction.to,
                    value: transaction.value,
                    data: transaction.data,
                  })

                  // We subtract fees from the amount we need to buy
                  ethAmount = BigInt(quote.toAmount) - BigInt(fee.amount)
                }
                // User selected ETH as the currency. No need to swap
                else {
                  ethAmount = parseUnits(params.fromAmount, params.fromToken.decimals)
                }

                tokensToBuy = (await publicClient.readContract({
                  address: params.toToken.address,
                  abi: wowAbi,
                  functionName: 'getEthBuyQuote',
                  args: [ethAmount],
                })) as bigint

                // Min order less than 10% actual order for slippage. a.k.a 10% slippage
                const minOrderSize = (tokensToBuy * BigInt(100 - maxSlippage)) / 100n
                calls.push({
                  to: params.toToken.address,
                  data: encodeFunctionData({
                    abi: wowAbi,
                    functionName: 'buy',
                    args: [
                      // Token recipient
                      embeddedSmartWallet.address as `0x${string}`,
                      // Refund recipient
                      embeddedSmartWallet.address as `0x${string}`,
                      // The address of referrer
                      orderReferrer,
                      // Comment
                      '',
                      // Expected market type. Always 0 as this is a bonding curve market
                      0,
                      // For slippage
                      minOrderSize,
                      // The price limit for Uniswap V3 pool swaps, ignored if market is bonding curve.
                      0n,
                    ],
                  }),
                  value: ethAmount,
                })

                return {
                  calls,
                  wasBoughtFromWowContract,
                  quote: {
                    from: params.fromToken,
                    to: params.toToken,
                    fromAmount: params.fromAmount,
                    toAmount: tokensToBuy.toString(),
                    // Keep it undefined for wow tokens, we don't need it to track history anyways
                    fromAmountUSD: undefined,
                    toAmountUSD: undefined,
                    warning: undefined,
                    priceImpact: undefined,
                    hasHighPriceImpact: false,
                  },
                }
              }
            }

            // If not wow token or not bonding curve market, swap directly with onchainkit
            const buildSwapTxRequest = {
              fromAddress: embeddedSmartWallet.address,
              from: params.fromToken,
              to: params.toToken,
              amount: params.fromAmount,
              useAggregator: false,
              maxSlippage: maxSlippage.toString(),
            } as BuildSwapTransactionParams

            const response = (await buildSwapTransaction(
              buildSwapTxRequest
            )) as BuildSwapTransactionResponse

            if ('error' in response) {
              throw new Error(response.code)
            }

            const { approveTransaction, transaction, quote } = response

            if (approveTransaction?.data) {
              calls.push({
                to: approveTransaction.to,
                data: approveTransaction.data,
                value: approveTransaction.value,
              })

              const permit2ContractAbi = parseAbi([
                'function approve(address token, address spender, uint160 amount, uint48 expiration) external',
              ])

              const data = encodeFunctionData({
                abi: permit2ContractAbi,
                functionName: 'approve',
                args: [
                  quote.from.address as `0x${string}`,
                  UNIVERSALROUTER_CONTRACT_ADDRESS,
                  BigInt(quote.fromAmount),
                  20_000_000_000_000, // The deadline where the approval is no longer valid - see https://docs.uniswap.org/contracts/permit2/reference/allowance-transfer
                ],
              })

              calls.push({
                to: PERMINT2_CONTRACT_ADDRESS as `0x${string}`,
                data: data,
                value: 0n,
              })
            }

            calls.push({
              to: transaction.to,
              value: transaction.value,
              data: transaction.data,
            })

            return { wasBoughtFromWowContract, calls, quote, warning: response.warning }
          }
        : skipToken,
  })

  return query
}

export const SWAP_LOW_LIQUIDITY_ERROR = 'SWAP_QUOTE_LOW_LIQUIDITY_ERROR'
