import { type LegacyRef, forwardRef } from 'react'
import {
  Text as RNText,
  type TextProps as RNTextProps,
  type TextStyle as RNTextStyle,
} from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import Animated from 'react-native-reanimated'

const Text = forwardRef<typeof RNText, RNTextProps>((props, ref) => {
  return <RNText maxFontSizeMultiplier={1} {...props} ref={ref as LegacyRef<RNText>} />
})
Text.displayName = 'Text'

type TextProps = RNTextProps
type TextStyle = RNTextStyle

const AnimatedText = Animated.createAnimatedComponent(Text)
const AnimatedTextInput = Animated.createAnimatedComponent(TextInput)

export { AnimatedText, AnimatedTextInput, Text, type TextProps, type TextStyle }
