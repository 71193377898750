import { LeanText, LeanView } from '@my/ui'
import { formatShortNumber } from 'app/utils/common'
import { Image } from 'expo-image'
import { Link, type Href } from 'expo-router'
import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

type ZoraIconLinkProps = {
  count: number
  url: Href
}

export const ZoraIconLink = (props: ZoraIconLinkProps) => {
  const mintCount = useMemo(() => formatShortNumber(props.count), [props.count])
  return (
    <LeanView className="items-center justify-center gap-0.5">
      <Link className="flex" target="_blank" rel="noreferrer" href={props.url}>
        <Image
          source={require('@my/ui/src/icons/zora-icon.png')}
          style={{ width: 36, height: 36 }}
        />
      </Link>
      <LeanText className="text-xs native:text-sm text-white" style={styles.shadow}>
        {mintCount}
      </LeanText>
    </LeanView>
  )
}

const styles = StyleSheet.create({
  shadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.4)',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 2,
  },
})
