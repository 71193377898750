import { cn, Pressable, Text } from '@my/ui'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { Image } from 'expo-image'
import { useRouter } from 'expo-router'
import { useMemo } from 'react'
import { useWalletAction } from '../coinbase-smart-wallet/use-wallet-action'
import { useMe } from './hooks/use-me'
import { useUserByUsername } from './hooks/use-user-by-username'

type Props = {
  username: string
}

export const PinAToken = (props: Props) => {
  const router = useRouter()
  const user = useUserByUsername({ username: props.username })
  const me = useMe()
  const hasToken = !!user.data?.userPinnedToken?.address
  const handleWalletAction = useWalletAction(() => {
    router.push('/select-token')
  })

  const presseableStyle = useMemo(
    () =>
      cn(
        'animate-none flex-row items-center justify-center gap-1.5 ios:-mt-1.5',
        user.isRefetching ? 'animate-[pulse_400ms_linear_infinite]' : 'animate-none'
      ),
    [user.isRefetching]
  )

  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()

  if (isWeb3ActionsDisabled || hasToken) {
    return null
  }

  const isMyProfile = me.data?.id === user.data?.userDetails.id

  if (isMyProfile) {
    return (
      <Pressable
        key="pin-a-token-button"
        className={presseableStyle}
        scale
        disabled={user.isLoading || user.isPending || user.isRefetching}
        onPress={handleWalletAction}>
        <Image
          source={require('@my/ui/src/icons/pin-token-star.svg')}
          style={{ height: 20, width: 20 }}
          contentFit="contain"
        />
        <Text className="text-secondary text-xs native:text-base font-semibold">Pin a token</Text>
      </Pressable>
    )
  }
}
