import { Button, Text, View } from '@my/ui'
import { useRefreshByUser } from 'app/utils/react-query/useRefreshByUser'
import { useNavigation, useRouter } from 'expo-router'
import { useCallback, useEffect, useState } from 'react'
import { useEventCallback } from '../../hooks/use-event-callback'
import { BlockedUserScene } from './block-user-scene'
import { useMe } from './hooks/use-me'
import { useUserByUsername } from './hooks/use-user-by-username'
import { MoreOptionsProfileDropdown } from './more-options-profile-dropdown'
import { Posts } from './posts'
import { ProfileHeader } from './profile-header'
import { RestrictedUserScene } from './restricted-user-scene'

export const Profile = ({ username }: { username: string }) => {
  const me = useMe()
  const navigation = useNavigation()

  useEffect(() => {
    if (username !== me.data?.username) {
      navigation.setOptions({
        headerRight: () => (
          <View className="web:pr-2">
            <MoreOptionsProfileDropdown
              userId={profile.data?.userDetails.id}
              username={profile.data?.userDetails.username}
              isBlockedByMe={profile.data?.userDetails.isBlockedByMe}
            />
          </View>
        ),
      })
    }
  }, [navigation, me, username])

  const router = useRouter()

  const profile = useUserByUsername({ username })

  const { refetchByUser } = useRefreshByUser(profile.refetch)
  const [refreshing, setRefreshing] = useState(false)
  const handleRefresh = useCallback(() => {
    setRefreshing(true)
    refetchByUser()
    setRefreshing(false)
  }, [refetchByUser])

  const header = useEventCallback(() => {
    return (
      <View className="p-4 mb-4">
        <ProfileHeader username={username} />
      </View>
    )
  })

  if (profile.error?.data?.httpStatus === 404) {
    return (
      <View className="flex-1 items-center justify-center">
        <Text className="text-white text-xl font-medium">This user does not exist</Text>
        <View className="mt-4">
          <Button onPress={() => (router.canGoBack() ? router.back() : router.replace('/'))}>
            Go back
          </Button>
        </View>
      </View>
    )
  }

  const isBlockedByMe = profile.data?.userDetails.isBlockedByMe
  const hasBlockedMe = profile.data?.userDetails.hasBlockedMe

  if (isBlockedByMe && profile.data?.userDetails.id) {
    return (
      <View className="flex-[1] items-center justify-center">
        <BlockedUserScene
          userId={profile.data?.userDetails.id}
          username={profile.data?.userDetails.username}
        />
      </View>
    )
  }

  if (hasBlockedMe) {
    return (
      <View className="flex-[1] items-center justify-center">
        <RestrictedUserScene />
      </View>
    )
  }

  return (
    <Posts
      username={username}
      refreshing={refreshing}
      onRefresh={handleRefresh}
      HeaderComponent={header}
    />
  )
}
