import { theme } from '@my/ui'
import { payTokenConfigs } from '@my/utils'
import * as WebBrowser from 'expo-web-browser'
import { formatEther } from 'viem'

export const WEB_HEADER_HEIGHT = {
  desktop: 77,
  mobile: 0,
}

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never

export const getFormattedPayTokenPrice = (price: string | number | bigint) => {
  const bigIntPrice = typeof price !== 'bigint' ? BigInt(price) : price
  const formattedPrice = bigIntPrice / payTokenConfigs.decimals
  return Number(formattedPrice).toFixed(0)
}

export const formatAddressShort = (address?: string | null) => {
  if (!address) return null

  // Skip over ENS names
  if (address.includes('.')) return address

  return `${address.slice(0, 4)}…${address.slice(address.length - 4, address.length)}`
}

type Loggable = string | number | boolean | Record<string, unknown> | unknown[]
const loggedMessages = new Set<string>()
export const logOnce = (...args: Loggable[]) => {
  const message = args.map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(' ')

  if (!loggedMessages.has(message)) {
    console.log(...args)
    loggedMessages.add(message)
  }
}
export const getFormattedEther = (value: bigint) => {
  return formatEther(value).slice(0, 8)
}

export function formatShortNumber(number: number) {
  if (number >= 1000000000) {
    return number % 1000000000 === 0
      ? `${Math.round(number / 1000000000)}B`
      : `${Math.round(number / 100000000) / 10}B`
  }
  if (number >= 1000000) {
    return number % 1000000 === 0
      ? `${Math.round(number / 1000000)}M`
      : `${Math.round(number / 100000) / 10}M`
  }
  if (number >= 1000) {
    return number % 1000 === 0
      ? `${Math.round(number / 1000)}K`
      : `${Math.round(number / 100) / 10}K`
  }
  const str = number.toString()
  const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
  return str.replace(reg, '$1,')
}

/**
 * Capitalizes the first letter of each word in a string and ensures the rest of the word remains in lowercase.
 * @param str The string to be transformed.
 * @returns A new string with the first letter of each word capitalized and all other letters in lowercase.
 */
export function capitalizeWords(str: string) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
}

export const browserParams: WebBrowser.WebBrowserOpenOptions = {
  presentationStyle: WebBrowser.WebBrowserPresentationStyle.FORM_SHEET,
  enableBarCollapsing: true,
  dismissButtonStyle: 'close',
  controlsColor: theme.colors.primary,
  showTitle: false,
  toolbarColor: 'black',
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

type RetryableFunction<T> = () => Promise<T>

export const retryWithExponentialBackoff = async <T>(
  fn: RetryableFunction<T>,
  retries = 3,
  delay = 1000
): Promise<T> => {
  let attempt = 0

  while (attempt < retries) {
    try {
      return await fn()
    } catch (error) {
      attempt += 1
      if (attempt >= retries) {
        throw error // rethrow the error if max retries are reached
      }
      await new Promise((resolve) => setTimeout(resolve, delay * 2 ** attempt - 1))
    }
  }

  // This line should technically never be reached since we're either returning or throwing an error.
  throw new Error('Exponential backoff failed to return a result.')
}

export function formatNumberForPrice(number: number, xNonZero?: number) {
  // Find the position of the first non-zero digit after the decimal point
  const str = number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 20,
  })
  const [, decimalPart] = str.split('.')
  const firstNonZeroIndex = decimalPart
    ? decimalPart.split('').findIndex((char) => char !== '0')
    : -1

  // Set maximumFractionDigits to the position of first non-zero digit, or 2 if it's less
  const maxDigits = Math.min(Math.max(firstNonZeroIndex + (xNonZero || 1), 2), 12)

  return number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDigits,
  })
}

export function formatNumberForUsdcPrice(number: number, xNonZero?: number) {
  // Always limit to 2 decimals
  const str = number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const [, decimalPart] = str.split('.')
  const firstNonZeroIndex = decimalPart
    ? decimalPart.split('').findIndex((char) => char !== '0')
    : -1

  // Set maximumFractionDigits to the position of first non-zero digit, or 2 if it's less
  const maxDigits = Math.min(Math.max(firstNonZeroIndex + (xNonZero || 1), 2), 12)

  return number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDigits,
  })
}

export function roundToFirstNonZero(num: number) {
  if (num === 0) return 0

  const sign = Math.sign(num)
  const absNum = Math.abs(num)
  const magnitude = Math.floor(Math.log10(absNum))
  const scale = 10 ** -magnitude

  return (sign * Math.round(absNum * scale)) / scale
}
