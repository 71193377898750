import { useObservable } from '@legendapp/state/react'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { Image } from 'expo-image'
import { useNavigation } from 'expo-router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppState, type ImageProps } from 'react-native'
import { runOnJS, useAnimatedReaction, type SharedValue } from 'react-native-reanimated'

type Props = {
  itemId: string
  imageUrls: string[]
  visibleItems: SharedValue<string[]>
  interval?: number
  style?: ImageProps['style']
}

export const ImageSequencer = ({
  itemId,
  imageUrls = [],
  interval = 2000,
  style,
  visibleItems,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const isPlayingRef = useRef(false)

  // Method to prefetch the images
  const prefetch = useEventCallback(async () => {
    await Promise.all(imageUrls.slice(1).map((imageUrl) => Image.prefetch(imageUrl)))
  })

  // Method to start the image loop
  const play = useEventCallback(() => {
    if (!isPlayingRef.current && imageUrls.length > 1) {
      prefetch()
      isPlayingRef.current = true
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length)
      }, interval)
    }
  })

  // Method to pause the image loop
  const pause = useEventCallback(() => {
    if (isPlayingRef.current && intervalRef.current) {
      clearInterval(intervalRef.current)
      isPlayingRef.current = false
    }
  })

  const navigation = useNavigation()

  const visibilityState$ = useObservable({
    isItemInWindow: false,
    isAppActive: AppState.currentState === 'active',
    isScreenFocused: navigation.isFocused(),
  })

  useEffect(() => {
    const unsubscribeFocusListener = navigation.addListener('focus', () => {
      visibilityState$.isScreenFocused.set(true)
    })
    const unsubscribeBlurListener = navigation.addListener('blur', () => {
      visibilityState$.isScreenFocused.set(false)
    })

    const appstateListener = AppState.addEventListener('change', (nextAppState) => {
      visibilityState$.isAppActive.set(nextAppState === 'active')
    })

    return () => {
      unsubscribeFocusListener()
      unsubscribeBlurListener()
      appstateListener.remove()
    }
  }, [navigation, visibilityState$.isAppActive, visibilityState$.isScreenFocused])

  const isItemTrulyVisible = useEventCallback(() => {
    const isVisible =
      visibilityState$.isAppActive.get() &&
      visibilityState$.isItemInWindow.get() &&
      visibilityState$.isScreenFocused.get()
    return isVisible
  })

  useEffect(() => {
    const dipose = visibilityState$.onChange(() => {
      if (isItemTrulyVisible()) {
        play()
      } else {
        pause()
      }
    })
    return dipose
  }, [isItemTrulyVisible, visibilityState$, play, pause])

  const setIsItemInWindow = useCallback(
    (value: boolean) => {
      // If item is already in window, setting it again to true will not trigger the onChange legend state callback.
      // So we play it manually instead of relying on onChange
      if (visibilityState$.isItemInWindow.get() === value) {
        if (isItemTrulyVisible()) {
          play()
        } else {
          pause()
        }
      } else {
        visibilityState$.isItemInWindow.set(value)
      }
    },
    [isItemTrulyVisible, visibilityState$.isItemInWindow, play, pause]
  )

  useAnimatedReaction(
    () => visibleItems.value,
    (visibleItems) => {
      if (visibleItems.includes(itemId)) {
        runOnJS(setIsItemInWindow)(true)
      } else {
        runOnJS(setIsItemInWindow)(false)
      }
    },
    [visibleItems]
  )

  // Clear the interval when the component unmounts
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <Image
      recyclingKey={imageUrls[0]}
      transition={400}
      source={{ uri: imageUrls[currentIndex] }}
      style={style}
    />
  )
}
