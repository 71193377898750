import { toast } from '@my/ui'
import { trpc } from 'app/utils/api'
import { logError } from 'app/utils/logging/logError'
import { Platform } from 'react-native'
import { useUpdateProfileWithFarcasterDetails } from './use-update-profile-with-farcaster-details'

export const useSaveFarcasterDetails = () => {
  const utils = trpc.useUtils()
  const updateProfileWithFarcasterDetails = useUpdateProfileWithFarcasterDetails()

  const saveFarcasterDetails = trpc.user.saveFarcasterDetails.useMutation({
    onError: (error) => {
      logError({
        type: 'TAP',
        level: 'error',
        action: 'LINK_ACCOUNT',
        error,
        message: 'Failed to save farcaster details',
      })
    },
    onSuccess: () => {
      toast.profile.link.farcaster.success({
        message: 'Successfully linked Farcaster account',
        description:
          Platform.OS !== 'web'
            ? 'Do you want to use your Farcaster name, bio, and profile picture on Drakula?'
            : undefined,
        action:
          // TODO: add toasts with buttons on web
          Platform.OS !== 'web'
            ? () => {
                updateProfileWithFarcasterDetails.mutate()
              }
            : undefined,
      })
      // we invalidate so that the Link Farcaster button disappears
      utils.user.me.invalidate()
    },
  })

  return saveFarcasterDetails
}
