import { useMe } from 'app/features/profile/hooks/use-me'
import { useMemo } from 'react'
import { createDeeplinkURL } from './helpers'
import type { ShareType } from './use-share'

type Params = {
  type?: ShareType['type']
  pathParams: {
    username?: string
    postId?: string
    address?: string
  }
}

export const useShareUrl = (params: Params) => {
  const me = useMe()
  const shareUrl = useMemo(() => {
    const { type, pathParams } = params

    const searchParams = new URLSearchParams()
    if (me.data?.referralCode) {
      searchParams.append('invite', me.data?.referralCode)
    }

    let shareUrlSlug =
      type === 'user'
        ? `/user/${pathParams.username}`
        : type === 'token'
          ? `/token/${pathParams.address}`
          : `/post/${pathParams.postId}`

    const searchParamString = searchParams.toString()
    if (searchParamString.length > 0) {
      shareUrlSlug += `?${searchParamString}`
    }

    const shareUrl = createDeeplinkURL(shareUrlSlug)

    return shareUrl
  }, [me.data?.referralCode, params])

  return shareUrl
}
