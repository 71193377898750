import { dismiss, toast } from '@my/ui'
import { trpc } from 'app/utils/api'
import { logError } from 'app/utils/logging/logError'

export const useUpdateProfileWithFarcasterDetails = () => {
  const trpcUtils = trpc.useUtils()

  const updateProfileWithFarcasterDetails = trpc.user.updateProfileWithFarcasterDetails.useMutation(
    {
      onError: (error) => {
        logError({
          type: 'TAP',
          level: 'error',
          action: 'LINK_ACCOUNT',
          error,
          message: 'Failed to use farcaster profile data',
        })

        dismiss('profile-update-farcaster-loading')

        toast.profile.update.farcaster.error()
      },
      onMutate: () => {
        dismiss('profile-link-farcaster-success')
        toast.profile.update.farcaster.loading()
      },
      onSuccess: (data) => {
        trpcUtils.user.me.setData(undefined, (d) => {
          if (!d) return
          const updaterObject = {
            ...d,
            name: data.name,
            bio: data.bio,
            profileImage: data.profileImage,
          }
          return updaterObject
        })

        trpcUtils.user.byUsername.setData(
          {
            username: data.username,
          },
          (d) => {
            if (!d) return
            return {
              ...d,
              userDetails: {
                ...d.userDetails,
                name: data.name,
                bio: data.bio,
                profileImage: data.profileImage,
                farcaster: data.farcaster,
              },
            }
          }
        )

        dismiss('profile-update-farcaster-loading')
      },
    }
  )

  return updateProfileWithFarcasterDetails
}
