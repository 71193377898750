import React from 'react'
import type { FlatListProps, FlatList as RNFlatList } from 'react-native'

import Animated from 'react-native-reanimated'

import { SceneComponent } from '../scene'

export type TabFlatListProps<T> = FlatListProps<T> & {
  index: number
}

function FlatList<T>(props: TabFlatListProps<T>, ref: React.Ref<RNFlatList<T>>) {
  return <SceneComponent {...props} forwardedRef={ref} ContainerView={Animated.FlatList} />
}

export const TabFlatList = React.forwardRef(FlatList) as <T>(
  props: TabFlatListProps<T> & {
    ref?: React.Ref<RNFlatList<T>>
  }
) => React.ReactElement
