import { useEventCallback } from 'app/hooks/use-event-callback'
import * as Clipboard from 'expo-clipboard'
import { toast } from '../toast'
import { Pressable } from './pressable'
import { View } from './view'

export const CopyToClipboardButton = ({
  textToCopy,
  size = 14, // Default height (original was 14)
}: {
  textToCopy: string
  size?: number
}) => {
  const handlePress = useEventCallback(async () => {
    await Clipboard.setStringAsync(textToCopy)
    toast.utils.copyToClipboard.success()
  })

  // Maintain original ratio: width was 12, height was 14
  const width = (size * 12) / 14

  return (
    <Pressable scale onPress={handlePress} className="relative" style={{ width, height: size }}>
      {/* Back square */}
      <View
        className="border-2 border-white absolute bg-transparent"
        style={{
          width,
          height: size,
          bottom: size / 7, // Scale the original 1px offset
          left: size / 3,
        }}
      />
      {/* Front square */}
      <View
        className="w-full h-full border-2 border-white bg-appDarkBg"
        style={{
          transform: [{ translateY: size / 7 }], // Scale the original 0.5px translation
        }}
      />
    </Pressable>
  )
}
