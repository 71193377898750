export const formatTime = (seconds = 0) => {
  'worklet'
  const minutes = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`
}

export const clamp = (value: number, min: number, max: number): number => {
  'worklet'
  return Math.min(Math.max(value, min), max)
}

export const calculateThumbnailTimes = (duration: number, numThumbnails: number): number[] => {
  const interval = duration / (numThumbnails - 1)
  return Array.from({ length: numThumbnails }, (_, i) => clamp(i * interval, 0, duration))
}

export const getSizeFromWidth = (width: number): { width: number; height: number } => {
  const aspectRatio = 16 / 9
  const height = width / aspectRatio
  return { width, height }
}
