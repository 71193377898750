import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  focused?: boolean
}

export function BloodTabIcon(props: Props) {
  return (
    <Svg width={props.width ?? 20} height={props.height ?? 26} viewBox="0 0 20 26">
      {props.focused ? (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.660564 15.4033C0.660564 13.1803 1.49049 11.1513 2.85739 9.60901L9.27348 0.143311L16.0754 9.76258C17.363 11.2848 18.1392 13.2534 18.1392 15.4033C18.1392 20.2299 14.2265 24.1426 9.3999 24.1426C4.5733 24.1426 0.660564 20.2299 0.660564 15.4033ZM8.57179 19.0605V13.0772H6.58714V11.4209H8.57179V9.22182H10.228V11.4209H12.2125V13.0772H10.228L10.228 19.0605H8.57179Z"
          fill="#fff"
        />
      ) : (
        <>
          <Path
            d="M3.677 10.07l.56.498.033-.037.028-.04-.621-.42zm11.969.139l-.612.433.018.026.021.025.573-.484zm-6.16-8.71l.613-.433L9.474.18l-.608.897.62.42zm-6.37 8.074a8.635 8.635 0 00-2.18 5.746h1.5c0-1.824.68-3.486 1.802-4.751l-1.122-.995zm-2.18 5.746a8.665 8.665 0 008.665 8.665v-1.5a7.165 7.165 0 01-7.165-7.165h-1.5zm8.665 8.665a8.665 8.665 0 008.665-8.665h-1.5a7.165 7.165 0 01-7.165 7.165v1.5zm8.665-8.665c0-2.132-.77-4.086-2.047-5.595l-1.146.97a7.134 7.134 0 011.693 4.625h1.5zm-2.008-5.543L10.1 1.066l-1.225.866 6.16 8.71 1.224-.866zM8.866 1.078l-5.81 8.571 1.242.842 5.81-8.572-1.242-.841z"
            fill="#fff"
          />
          <Path d="M9.601 9.72v8.911M7.054 12.462h5.095" stroke="#fff" strokeWidth={1.5} />
        </>
      )}
    </Svg>
  )
}
