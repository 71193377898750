import { type OAuthTokens, useOAuthTokens } from '@privy-io/react-auth'
import { type ReactNode, createContext, useContext, useState } from 'react'

type TwitterOauthContextType = {
  tokens: TwitterOauth | null
  setTokens: (token: TwitterOauth | null) => void
}

const TwitterOauthContext = createContext<TwitterOauthContextType | undefined>(undefined)

type TwitterOauth = {
  accessToken: string
  refreshToken?: string
  scopes?: string
}

export const TwitterOauthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tokens, setTokens] = useState<TwitterOauth | null>(null)

  useOAuthTokens({
    onOAuthTokenGrant: (tokens: OAuthTokens) => {
      setTokens({
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        scopes: tokens.scopes?.join(','),
      })
    },
  })

  return (
    <TwitterOauthContext.Provider value={{ tokens, setTokens }}>
      {children}
    </TwitterOauthContext.Provider>
  )
}

export const useTwitterOauth = (): TwitterOauthContextType => {
  const context = useContext(TwitterOauthContext)
  if (context === undefined) {
    throw new Error('useTwitterOauth must be used within a TwitterOauthProvider')
  }
  return context
}
