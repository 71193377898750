import type { RouterOutputs } from '@my/api'
import { usdcToken } from 'app/features/buy-token/constants'
import { useEthBalanceInUsd } from 'app/features/buy-token/hooks/use-eth-balance-in-usd'
import { useTokenBalance } from 'app/features/buy-token/hooks/use-token-balance'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { trpc } from 'app/utils/api'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { chain } from 'app/utils/chain'
import { useRouter } from 'expo-router'
import { checksumAddress } from 'viem'

export const useNavigateToBuyToken = () => {
  const authStatus = useAuthenticationStatus()
  const isLoggedOut = authStatus !== 'authenticated'
  const router = useRouter()
  const trpcUtils = trpc.useUtils()
  const ethBalanceInUsd = useEthBalanceInUsd()
  const usdcBalance = useTokenBalance({
    address: usdcToken.address,
  })
  const redirectToSignIn = useRedirectToSignIn()

  const navigateToBuyToken = useEventCallback(
    (params: {
      tokenAddress?: string
      amount: string
      username?: string
      userPinnedToken?: RouterOutputs['tokenContract']['byAddress'] | null
      referral?: string
      location?: 'post' | 'user'
      isWowToken?: boolean
    }) => {
      if (isLoggedOut) {
        return redirectToSignIn()
      }
      if (ethBalanceInUsd === 0n && usdcBalance.data === 0n) {
        router.push('/deposit')
        return
      }
      const tokenAddress = checksumAddress(params.tokenAddress as `0x${string}`)
      const urlParams = new URLSearchParams({
        initialAmount: params.amount,
      })

      if (params.username) {
        urlParams.append('username', params.username)
      }

      if (params.isWowToken) {
        urlParams.append('isWowToken', 'true')
      }

      if (params.referral) {
        urlParams.append('referral', params.referral)
      }

      if (params.location) {
        urlParams.append('location', params.location)
      }

      if (params.userPinnedToken) {
        trpcUtils.tokenContract.byAddress.setData(
          {
            address: tokenAddress,
            networkId: chain.id.toString() as '8453' | '84532',
          },
          params.userPinnedToken
        )
      }

      router.push(`/buy/${tokenAddress}?${urlParams.toString()}`)
    }
  )

  return navigateToBuyToken
}
