import { AnimatedLeanView, AnimatedTextInput, AnimatedView, LeanText, LeanView } from '@my/ui'
import React, { useId } from 'react'
import type { TextInput, TextInputProps } from 'react-native'
import { FadeIn, FadeOut, LinearTransition } from 'react-native-reanimated'

export type TextFieldProps = TextInputProps & {
  errorMessage?: string
  disabled?: boolean
  label?: string
  isOptional?: boolean
  placeholder?: string
  maxLength?: number
  isEmail?: boolean
  value?: string
  onChangeText?: (text: string) => void
  onBlur?: () => void
  multiline?: boolean
  className?: string
  inputClassName?: string
  noBorder?: boolean
  inputComponent?: typeof TextInput
  renderRightComponent?: React.ReactNode
}

export const TextField = React.forwardRef<TextInput, TextFieldProps>(function TextField(
  props,
  ref: React.LegacyRef<TextInput>
) {
  const id = useId()
  const {
    errorMessage,
    disabled,
    label,
    isOptional,
    placeholder,
    maxLength,
    isEmail,
    value,
    onChangeText,
    onBlur,
    className,
    noBorder,
    inputClassName,
    renderRightComponent,
    ...rest
  } = props

  const InputComponent = props.inputComponent ?? (AnimatedTextInput as unknown as typeof TextInput)

  return (
    <AnimatedView layout={LinearTransition}>
      {!!label && (
        <LeanText
          className="text-gray-300 mb-2"
          // @ts-expect-error - web only
          htmlFor={id}
        >
          {label} {isOptional && `(Optional)`}
        </LeanText>
      )}
      <LeanView
        className={`rounded-md bg-[#151515] ${className}`}
        style={{
          borderColor: errorMessage ? '#ff0000' : '#323232',
          borderWidth: noBorder ? 0 : 1,
        }}>
        <LeanView className="flex-row">
          <InputComponent
            ref={ref}
            aria-disabled={disabled}
            editable={!disabled}
            className={`flex-[1] p-2 web:outline-none text-[16px] color-white ${inputClassName}`}
            placeholderTextColor="#626262"
            maxLength={maxLength}
            spellCheck={isEmail ? false : undefined}
            autoCapitalize={isEmail ? 'none' : undefined}
            keyboardType={isEmail ? 'email-address' : undefined}
            value={value}
            onChangeText={onChangeText}
            onBlur={onBlur}
            placeholder={placeholder}
            id={id}
            {...rest}
          />
          <LeanView>{renderRightComponent}</LeanView>
        </LeanView>
      </LeanView>
      {errorMessage ? (
        <AnimatedLeanView className="mt-3" entering={FadeIn} exiting={FadeOut}>
          <LeanText className="text-red-500">{errorMessage}</LeanText>
        </AnimatedLeanView>
      ) : null}
    </AnimatedView>
  )
})

TextField.displayName = 'TextField'
