import Ionicons from '@expo/vector-icons/Ionicons'
import { InfiniteScrollList, LeanView, Pressable, Text, theme, View } from '@my/ui'
import { useScrollToTop } from '@react-navigation/native'
import { withViewabilityInfiniteScrollList } from 'app/utils/with-viewability-infinite-scroll-list'
import { Link } from 'expo-router'
import { useCallback, useEffect, useRef } from 'react'
import { ActivityIndicator, Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context'
import { VolumeManager } from 'react-native-volume-manager'
import { isWeb } from '../../utils/platform'
import { MobileVideoFeedItem } from './mobile-video-feed-item'
import type { VideoFeedItemProps, VideoFeedListProps } from './types'
const ViewabilityInfiniteScrollList = withViewabilityInfiniteScrollList(InfiniteScrollList)

export const MobileVideoFeedList = (props: VideoFeedListProps) => {
  // We're using useSafeAreaFrame for native and useWindowDimensions for web, because useSafeAreaFrame has no resize event on web
  const screenSize = useWindowDimensions()
  const screenFrame = useSafeAreaFrame()
  const insets = useSafeAreaInsets()

  const windowSize = Platform.OS === 'web' ? screenSize : screenFrame

  const availableScreenHeight = windowSize.height
  const availableScreenWidth = windowSize.width
  const listRef = useRef(null)
  useScrollToTop(listRef)

  useEffect(() => {
    if (!isWeb) {
      // Set the audio session to ambient
      // Expo-Video is too slow to switch the audio session, so we use VolumeManager to set the audio session to ambient
      // this allows for seamless audio switching
      VolumeManager.setCategory('Ambient', false)
    }

    return () => {
      if (!isWeb) {
        // when we unmount this screen, we want to disable the Audio Session to allow the system to resume the audio
        VolumeManager.enable(false)
        VolumeManager.setActive(false)
      }
    }
  }, [])

  const renderItem = useCallback(
    ({ item }: { item: VideoFeedItemProps['item'] }) => {
      return (
        <MobileVideoFeedItem
          item={item}
          availableScreenHeight={availableScreenHeight}
          availableScreenWidth={availableScreenWidth}
        />
      )
    },
    [availableScreenHeight, availableScreenWidth]
  )

  const FooterComponent = useCallback(() => {
    return props.loadingMore ? (
      <LeanView className="w-full items-center justify-center absolute h-14">
        <ActivityIndicator color="red" />
      </LeanView>
    ) : null
  }, [props.loadingMore])

  const EmptyComponent = useCallback(() => {
    return (
      <LeanView
        className="flex-[1] items-center justify-center"
        style={{ height: availableScreenHeight }}>
        <LeanView
          className="absolute top-0 w-full flex-[1] z-20 flex-row h-16"
          style={{
            top: insets.top,
          }}>
          <LeanView className="p-4 flex-row w-full flex-1 justify-end">
            <Link href="/explore" className="lg:hidden" style={styles.shadow} asChild>
              <Pressable hitSlop={10}>
                <Ionicons name="search" color="white" size={25} />
              </Pressable>
            </Link>
          </LeanView>
        </LeanView>
        <Text className="text-white text-lg">No more posts</Text>
      </LeanView>
    )
  }, [availableScreenHeight, insets.top])

  if (props.loading) {
    return (
      <View
        className="animate-pulse items-center bg-borderShade1"
        style={{ width: availableScreenWidth, height: availableScreenHeight }}
      />
    )
  }

  return (
    <ViewabilityInfiniteScrollList
      showsVerticalScrollIndicator={false}
      useWindowScroll={false}
      data={props.data}
      onEndReached={props.onEndReached}
      ref={listRef}
      estimatedItemSize={availableScreenHeight}
      renderItem={renderItem}
      preserveScrollPosition
      pagingEnabled={true}
      ListFooterComponent={FooterComponent}
      ListEmptyComponent={EmptyComponent}
      initialScrollIndex={props.initialScrollIndex}
      onViewableItemsChanged={props.onViewableItemsChanged}
      drawDistance={availableScreenHeight * 2}
      onEndReachedThreshold={0.3}
      overscan={1}
      refreshControl={
        <RefreshControl
          refreshing={props.isRefetching}
          onRefresh={props.refetch}
          tintColor={theme.colors.primary}
        />
      }
    />
  )
}

const styles = StyleSheet.create({
  shadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.4)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
  },
})
