import { toast, type BottomSheetGorhomRef } from '@my/ui'
import { PrivyClientError, type User } from '@privy-io/expo'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { resetQueries } from 'app/provider/react-query/query-client'
import { logError } from 'app/utils/logging/logError'
import { logInfo } from 'app/utils/logging/logInfo'
import { storage } from 'app/utils/mmkv/storage'
import { datadog, mixpanel } from 'app/utils/tracking'
import { events } from 'app/utils/tracking/events'
import { router } from 'expo-router'
import type React from 'react'

export function useLoginStatusHandlers(modalRef?: React.RefObject<BottomSheetGorhomRef>) {
  const onSuccess = useEventCallback(async (user: User) => {
    storage.set('isLoggedIn', true)
    mixpanel.identify(user.id)
    datadog.identify(user)
    logInfo({ type: 'TAP', action: 'SIGN_IN', message: 'Logged in successfully', data: { user } })
    mixpanel.track(events.SIGNED_IN)
    resetQueries()
    toast.login.success()
  })

  const onError = useEventCallback((error) => {
    const isPrivyError = error instanceof PrivyClientError

    // Do not show a toast if the user cancelled the login
    // A cancel is not an error
    if (isPrivyError && error.code === 'login_with_oauth_was_cancelled_by_user') {
      return
    }

    // this is super rare and should never happen, but if it does, we need to dismiss the sheet
    if (
      isPrivyError &&
      (error.code === 'attempted_login_with_siwe_while_already_logged_in' ||
        error.code === 'attempted_login_with_sms_while_already_logged_in')
    ) {
      // There is a bug where the sheet will sometimes not dismiss and wiggle around
      // This is a hack to fix it by scheduling the dismiss after the next frame
      requestAnimationFrame(async () => {
        if (modalRef) {
          await modalRef.current?.dismiss()
        }
        try {
          // yes, this is a hack since we don't know how deep the stack is
          // this is a super rare edge case and we should never see it
          // TODO: fix this ugly mess
          router.dismissAll()
          router.dismissAll()
          router.dismissAll()
        } catch {}
      })

      return
    }

    if (isPrivyError || error instanceof Error) {
      toast.login.error({ description: error.message ?? 'Something went wrong. Please try again.' })
    }

    logError({
      type: 'TAP',
      level: 'error',
      action: 'SIGN_IN',
      error,
      message: 'Failed to log in',
    })
  })

  return { onSuccess, onError }
}
