import { Button, XLogo, toast } from '@my/ui'
import { useLinkAccount, usePrivy } from '@privy-io/react-auth'
import { useTwitterOauth } from 'app/provider/twitter-oauth-provider'
import { logError } from 'app/utils/logging/logError'
import { logInfo } from 'app/utils/logging/logInfo'
import { Image } from 'expo-image'
import { useEffect, useMemo, useRef } from 'react'
import { useEventCallback } from '../../hooks/use-event-callback'
import { useSaveFarcasterDetails } from './hooks/use-save-farcaster-details'
import { useSaveTwitterDetails } from './hooks/use-save-twitter-details'

export function LinkSocialAccounts({
  hasTwitterDetails,
  hasFarcasterDetails,
}: { hasTwitterDetails: boolean; hasFarcasterDetails: boolean }) {
  const { user, unlinkFarcaster, unlinkTwitter } = usePrivy()
  const saveFarcasterDetails = useSaveFarcasterDetails()
  const saveTwitterDetails = useSaveTwitterDetails()
  const { tokens } = useTwitterOauth()
  const hasCalledSaveTwitterDetails = useRef(false)

  const linkedFarcasterAccount = useMemo(() => user?.farcaster, [user])
  const linkedTwitterAccount = useMemo(() => user?.twitter, [user])

  const { linkFarcaster, linkTwitter } = useLinkAccount({
    onSuccess: (_, linkMethod) => {
      logInfo({
        type: 'TAP',
        message: `${linkMethod} linked successfully`,
        action: 'LINK_ACCOUNT',
        data: {
          user,
        },
      })
      if (linkMethod === 'farcaster') {
        saveFarcasterDetails.mutate()
      }
    },
    onError: (error, details) => {
      if (error === 'exited_link_flow') return
      logError({
        type: 'TAP',
        level: 'error',
        action: 'LINK_ACCOUNT',
        error,
        message: `Failed to link ${details.linkMethod}`,
        indexedTags: {
          linkMethod: details.linkMethod,
        },
      })
      if (details.linkMethod === 'twitter') {
        toast.profile.link.twitter.error()
      } else if (details.linkMethod === 'farcaster') {
        toast.profile.link.farcaster.error()
      }
    },
  })

  useEffect(() => {
    if (tokens && !hasCalledSaveTwitterDetails.current) {
      saveTwitterDetails.mutate({
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        scopes: tokens.scopes,
      })
      hasCalledSaveTwitterDetails.current = true
    }
  }, [tokens, saveTwitterDetails])

  const handleLinkFarcasterPress = useEventCallback(async () => {
    // if we do not have farcaster details in the db, but farcaster is linked on privy, we have to unlink it first
    if (linkedFarcasterAccount?.fid) {
      await unlinkFarcaster(linkedFarcasterAccount.fid)
      linkFarcaster()
    } else {
      linkFarcaster()
    }
  })

  const handleLinkTwitterPress = useEventCallback(async () => {
    // if we do not have farcaster details in the db, but farcaster is linked on privy, we have to unlink it first
    if (linkedTwitterAccount?.subject) {
      await unlinkTwitter(linkedTwitterAccount.subject)
      linkTwitter()
    } else {
      linkTwitter()
    }
  })

  if (hasTwitterDetails && hasFarcasterDetails) return null

  return (
    <>
      {!hasTwitterDetails ? (
        <Button
          className="rounded-lg"
          variant="outline"
          onPress={handleLinkTwitterPress}
          iconAfter={<XLogo color="white" height={16} width={16} />}>
          Link
        </Button>
      ) : null}
      {!hasFarcasterDetails ? (
        <Button
          className="rounded-lg"
          variant="outline"
          onPress={handleLinkFarcasterPress}
          iconAfter={
            <Image
              source={require('@my/ui/src/icons/farcaster-purple-white.svg')}
              style={{ width: 20, height: 20, borderRadius: 9999 }}
            />
          }>
          Link
        </Button>
      ) : null}
    </>
  )
}
