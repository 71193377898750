import { Avatar, CollapsibleText, LeanText, LeanView } from '@my/ui'
import { Link, useLocalSearchParams } from 'expo-router'
import { memo, useRef } from 'react'
import { TokenDetailsModal } from '../token/token-details-modal'
import { DesktopVideoContainer } from './desktop-video-container'
import { EngagementButtons } from './engagement-buttons'
import { FeedItemGradient } from './feed-item-gradient'
import { TransactionAnimation } from './transaction-animation/transaction-animation'
import type { MuteRef, TogglePauseRef, VideoFeedItemProps, VideoVisibilityRef } from './types'
import { VideoFeedTokenDetails } from './video-feed-token-details'
import { VideoFeedHeader } from './video-feed-video/video-feed-header'
import { FeedVideo } from './video-feed-video/video-feed-video'

export const DesktopVideoFeedItem = memo((props: VideoFeedItemProps) => {
  const searchParams = useLocalSearchParams<{ expand: 'trade' }>()
  const muteRef = useRef<MuteRef>(null)
  const togglePauseRef = useRef<TogglePauseRef>(null)
  const videoVisibilityRef = useRef<VideoVisibilityRef>(null)
  const media = props.item.media
  const mediaUrl =
    media?.mp4_720 ?? media?.mp4_480 ?? media?.mp4_360 ?? media?.mp4_240 ?? media?.originalUrl
  const mediaImage = media?.thumbnailUrl
  const author = props.item.user

  if (!media || !mediaUrl) return null

  return (
    <>
      <LeanView className="w-full items-center">
        <LeanView className="flex-row items-end ml-12 gap-4">
          <LeanView
            style={{
              width: props.availableScreenWidth,
              height: props.availableScreenHeight,
            }}
            className="overflow-hidden rounded-md">
            <VideoFeedHeader muteRef={muteRef} />
            <DesktopVideoContainer togglePauseRef={togglePauseRef}>
              <FeedVideo
                uri={mediaUrl}
                availableScreenWidth={props.availableScreenWidth}
                availableScreenHeight={props.availableScreenHeight}
                videoHeight={media.height ?? props.availableScreenHeight}
                videoWidth={media.width ?? props.availableScreenWidth}
                postId={props.item.post.id}
                poster={mediaImage}
                muteRef={muteRef}
                togglePauseRef={togglePauseRef}
                videoVisibilityRef={videoVisibilityRef}
              />
            </DesktopVideoContainer>
            <LeanView className="absolute bottom-0 left-0 w-full">
              <FeedItemGradient />
              <LeanView className="flex-row">
                <LeanView className="p-4 gap-2 flex-1">
                  {props.item.post.tokenDetails ? (
                    <LeanView pointerEvents="box-none" className="gap-3 flex-1 max-w-xs">
                      <TransactionAnimation
                        key={props.item.post.tokenDetails.address}
                        address={props.item.post.tokenDetails.address as `0x${string}`}
                      />
                      <VideoFeedTokenDetails
                        postId={props.item.post.id}
                        username={author.username}
                        token={props.item.post.tokenDetails}
                      />
                    </LeanView>
                  ) : (
                    <Link href={`/user/${author.username}`}>
                      <LeanText
                        className="text-white font-bold max-w-[120px] text-ellipsis"
                        numberOfLines={1}>
                        {props.item.user.name ? props.item.user.name : props.item.user.username}
                      </LeanText>
                    </Link>
                  )}
                  <CollapsibleText initialNumberOfLines={2} className="text-white">
                    {props.item.post.description}
                  </CollapsibleText>
                </LeanView>
              </LeanView>
            </LeanView>
          </LeanView>
          <LeanView className="gap-10 mb-4 justify-center items-center">
            {author ? (
              <Avatar
                source={author.profileImage}
                href={`/user/${author.username}`}
                border
                size={44}
                asChild
                label={`Go to ${author.username}'s profile`}
              />
            ) : null}
            <EngagementButtons item={props.item} />
          </LeanView>
        </LeanView>
      </LeanView>
      {searchParams.expand === 'trade' && videoVisibilityRef.current?.isItemTrulyVisible() ? (
        <TokenDetailsModal item={props.item} />
      ) : null}
    </>
  )
})

DesktopVideoFeedItem.displayName = 'DesktopVideoFeedItem'
