import Ionicons from '@expo/vector-icons/Ionicons'
import { cn, Text, theme, View } from '@my/ui'
import { useMemo } from 'react'

export const UsdChange = ({ value, classNameText }: { value: number; classNameText?: string }) => {
  const isPositive = value > 0
  const isNearlyZero = value > -0.005 && value < 0.005

  const valueTextClassName = useMemo(() => {
    return cn(
      'text-lg text-primary',
      classNameText,
      isPositive ? 'text-secondary' : '',
      isNearlyZero ? 'text-white' : ''
    )
  }, [isPositive, isNearlyZero, classNameText])

  const timeTextClassName = useMemo(() => {
    return cn('text-lg text-grayTextColor', classNameText)
  }, [classNameText])

  if (isNearlyZero) {
    return (
      <View className="flex-row items-center justify-center gap-1">
        <Text className={valueTextClassName}>
          $
          {Math.abs(value).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Text>
        <Text className={timeTextClassName}>Past day</Text>
      </View>
    )
  }

  if (isPositive) {
    return (
      <View className="flex-row items-center justify-center gap-1">
        <Ionicons name="caret-up" color={theme.colors.secondary} size={15} />
        <View className="flex-row items-center gap-2">
          <Text className={valueTextClassName}>
            ${value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </Text>
          <Text className={timeTextClassName}>Past day</Text>
        </View>
      </View>
    )
  }

  return (
    <View className="flex-row items-center justify-center gap-1">
      <Ionicons name="caret-down" color={theme.colors.primary} size={15} />
      <View className="flex-row items-center gap-2">
        <Text className={valueTextClassName}>
          -$
          {Math.abs(value).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Text>
        <Text className={timeTextClassName}>Past day</Text>
      </View>
    </View>
  )
}
