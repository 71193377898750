import { LeanView, Pressable } from '@my/ui'
import { Image } from 'expo-image'
import { router } from 'expo-router'

export const DefaultHeaderLeft = () => {
  return (
    <LeanView className="web:pl-4">
      <Pressable
        onPress={() => {
          if (router.canGoBack()) {
            router.back()
          } else {
            router.navigate('/')
          }
        }}
        hitSlop={10}>
        <Image
          source={require('@my/ui/src/icons/back-icon.png')}
          style={{ width: 24, height: 24 }}
        />
      </Pressable>
    </LeanView>
  )
}
