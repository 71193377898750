import { Avatar, Button, LeanText, LeanView, Text, View, XLogo, cn } from '@my/ui'
import { Image } from 'expo-image'
import { Link } from 'expo-router'
import { useMemo } from 'react'
import { FollowButton } from '../follow/follow-button'
import { FollowingButton } from '../follow/following-button'
import { FollowerFollowingCount } from './follower-following-count'
import { useMe } from './hooks/use-me'
import { type UserByUsername, useUserByUsername } from './hooks/use-user-by-username'
import { LinkSocialAccounts } from './link-social-accounts'
import { MoreOptionsProfileDropdown } from './more-options-profile-dropdown'
import { PinAToken } from './pin-a-token'
import { ProfileAddTokenCard } from './profile-add-token-card'
import { ProfileHeaderRight } from './profile-header-right'
import { ShareProfileButton } from './share-profile-button'

export const ProfileHeader = ({ username }: { username: string }) => {
  return (
    <LeanView className="gap-6">
      <LeanView>
        <ProfileMeta username={username} />
      </LeanView>
      <LeanView>
        <ProfileAddTokenCard username={username} />
      </LeanView>
    </LeanView>
  )
}

const ProfileMeta = ({ username }: { username: string }) => {
  const profile = useUserByUsername({ username })

  if (profile.isPending) {
    return <MobileSkeleton />
  }

  if (!profile.data) {
    return (
      <View>
        <Text>Not Found</Text>
      </View>
    )
  }

  return (
    <LeanView className="gap-6">
      <LeanView className="flex-row items-center gap-5">
        <Avatar source={profile.data.userDetails.profileImage} size={100} />
        <LeanView className="gap-3">
          <LeanView className="gap-0.5">
            {profile.data.userDetails.name?.length ? (
              <LeanText
                className="text-white text-xl font-bold max-w-[200px] text-ellipsis"
                numberOfLines={1}>
                {profile.data?.userDetails.name}
              </LeanText>
            ) : null}
            <LeanText className="text-white">@{profile.data?.userDetails.username}</LeanText>
          </LeanView>
          <SocialButtons user={profile.data.userDetails} />
        </LeanView>
      </LeanView>
      <LeanView className="gap-6">
        <LeanView className="gap-3">
          <FollowerFollowingCount
            followersCount={profile.data.userDetails.followersCount}
            followingCount={profile.data.userDetails.followingCount}
            username={profile.data.userDetails.username}
          />
          {profile.data.userDetails.bio?.length ? (
            <LeanView className="line-clamp-3 overflow-hidden">
              <LeanText className="text-white min-h-5 max-h-16" numberOfLines={3}>
                {profile.data.userDetails.bio}
              </LeanText>
            </LeanView>
          ) : null}
        </LeanView>
        <ActionButtons user={profile.data.userDetails} />
      </LeanView>
    </LeanView>
  )
}

const SocialButtons = ({ user }: { user: UserByUsername['userDetails'] }) => {
  return (
    <LeanView className="flex-row items-center gap-2.5">
      {user.launchpadCollectionAddress ? (
        <Link
          href={
            `https://zora.co/collect/base:${user.launchpadCollectionAddress}` as `${string}:${string}`
          }
          target="_blank"
          className="flex">
          <Image
            source={require('@my/ui/src/icons/zora-icon.png')}
            style={{ height: 20, width: 20 }}
          />
        </Link>
      ) : null}
      {user.twitterUsername ? (
        <Link
          href={`https://x.com/${user.twitterUsername}`}
          target="_blank"
          className="flex items-center">
          <XLogo color="white" height={18} width={18} />
        </Link>
      ) : null}
      {user.farcaster ? (
        <Link
          href={`https://warpcast.com/${user.farcaster}`}
          target="_blank"
          className="flex items-center">
          <Image
            source={require('@my/ui/src/icons/farcaster-purple-white.svg')}
            style={{ width: 20, height: 20, borderRadius: 9999 }}
          />
        </Link>
      ) : null}
      <PinAToken username={user.username} />
    </LeanView>
  )
}

const ActionButtons = ({
  user,
  isDesktop,
}: { user: UserByUsername['userDetails']; isDesktop?: boolean }) => {
  const me = useMe()
  const isMyProfile = me.data?.username === user.username

  const viewClass = useMemo(() => cn('flex-row gap-3 items-stretch h-10web:h-8'), [])

  if (isMyProfile) {
    return (
      <LeanView className={viewClass}>
        <ShareProfileButton username={user.username} />
        <Link asChild href="/edit-profile">
          <Button
            icon={
              <Image
                source={require('@my/ui/src/icons/edit-pencil-svg.svg')}
                style={{ height: 14, width: 14 }}
              />
            }
            variant="outline"
            className="rounded-lg">
            Edit
          </Button>
        </Link>
        <LeanView className="flex-row gap-3">
          <LinkSocialAccounts
            hasFarcasterDetails={!!me.data?.fcUsername}
            hasTwitterDetails={!!me.data?.twitterId}
          />
        </LeanView>

        {isDesktop ? (
          <View className="justify-center">
            <ProfileHeaderRight />
          </View>
        ) : null}
      </LeanView>
    )
  }

  return (
    <LeanView className="flex-row gap-3 self-start items-center justify-center">
      {user.isFollowedByMe ? (
        <FollowingButton user={user} variant="gray" className="w-32 rounded-lg" />
      ) : (
        <FollowButton user={user} variant="primary" className="w-32 rounded-lg" />
      )}
      <ShareProfileButton username={user.username} />
      {isDesktop ? (
        <MoreOptionsProfileDropdown
          userId={user.id}
          username={user.username}
          isBlockedByMe={user.isBlockedByMe}
        />
      ) : null}
    </LeanView>
  )
}

const MobileSkeleton = () => {
  return (
    <LeanView className="gap-6">
      <LeanView className="flex-row items-center gap-5">
        <LeanView
          key="skeleton-avatar-desktop"
          className="animate-pulse bg-borderShade1"
          style={{
            width: 100,
            height: 100,
            borderRadius: 999,
          }}
        />
        <LeanView className="gap-3">
          <LeanView className="gap-0.5">
            <LeanView key="skeleton-name" className="h-9 w-56 animate-pulse bg-borderShade1" />
            <LeanView key="skeleton-username" className="h-5 w-36 animate-pulse bg-borderShade1" />
          </LeanView>
          <LeanView className="flex-row items-center gap-1">
            <LeanView
              key="skeleton-zora"
              className="h-8 w-8 animate-pulse bg-borderShade1 rounded-full"
            />
            <LeanView
              key="skeleton-farcaster"
              className="h-8 w-8 animate-pulse bg-borderShade1 rounded-full"
            />
            <LeanView
              key="skeleton-x"
              className="h-8 w-8 animate-pulse bg-borderShade1 rounded-full"
            />
          </LeanView>
        </LeanView>
      </LeanView>
      <LeanView className="gap-6">
        <LeanView className="gap-3">
          <LeanView className="flex-row items-center gap-3">
            <LeanView key="skeleton-followers" className="h-4 w-24 animate-pulse bg-borderShade1" />
            <LeanView key="skeleton-following" className="h-4 w-24 animate-pulse bg-borderShade1" />
          </LeanView>
          <LeanView key="skeleton-bio" className="h-10 w-96 animate-pulse bg-borderShade1" />
        </LeanView>
        <LeanView key="skeleton-actions" className="h-5 w-32 animate-pulse bg-borderShade1" />
      </LeanView>
    </LeanView>
  )
}

const DesktopSkeleton = () => {
  return (
    <View className="gap-6">
      <View className="flex-row items-center gap-5">
        <View
          key="skeleton-avatar-desktop"
          className="animate-pulse bg-borderShade1"
          style={{
            width: 145,
            height: 145,
            borderRadius: 999,
          }}
        />
        <View className="gap-3">
          <View className="gap-0.5">
            <View key="skeleton-name" className="h-9 w-56 animate-pulse bg-borderShade1" />
            <View key="skeleton-username" className="h-5 w-36 animate-pulse bg-borderShade1" />
          </View>
          <View className="flex-row items-center gap-1">
            <View
              key="skeleton-zora"
              className="h-8 w-8 animate-pulse bg-borderShade1 rounded-full"
            />
            <View
              key="skeleton-farcaster"
              className="h-8 w-8 animate-pulse bg-borderShade1 rounded-full"
            />
            <View key="skeleton-x" className="h-8 w-8 animate-pulse bg-borderShade1 rounded-full" />
          </View>
          <View key="skeleton-actions" className="h-5 w-32 animate-pulse bg-borderShade1" />
        </View>
      </View>
      <View className="gap-3">
        <View className="flex-row items-center gap-3">
          <View key="skeleton-followers" className="h-4 w-24 animate-pulse bg-borderShade1" />
          <View key="skeleton-following" className="h-4 w-24 animate-pulse bg-borderShade1" />
        </View>
        <View key="skeleton-bio" className="h-5 w-96 animate-pulse bg-borderShade1" />
      </View>
    </View>
  )
}
