import mixpanel from 'mixpanel-browser'

const initMixpanel = () => {
  // Initialize with specific options
  mixpanel.init(process.env.EXPO_PUBLIC_MIXPANEL_PROJECT_TOKEN!, {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
    opt_out_tracking_by_default: __DEV__,
  })
}

// Inconsistency in mixpanel web sdk. The getPeople method is not available in the web sdk.
const updatedMixPanel = Object.assign(mixpanel, {
  getPeople: () => mixpanel.people,
})

export { initMixpanel, updatedMixPanel as mixpanel }
