import { skipToken, useQuery } from '@tanstack/react-query'
import { useUserBalances } from 'app/features/trade/hooks/use-user-balances'
import { useEmbeddedSmartWallet } from 'app/provider/embedded-smart-wallet-provider/context'
import { publicClient } from 'app/utils/viem-public-client'
import { erc20Abi } from 'viem'
import { useUserEthBalance } from './use-user-eth-balance'

const key = (token?: { address: string }, cbWalletAddress?: string) => [
  'get-token-balance',
  token,
  cbWalletAddress,
]

export const useTokenBalance = (token?: {
  address: string
}) => {
  const embeddedSmartWallet = useEmbeddedSmartWallet()
  const ethBalance = useUserEthBalance()
  const useBalances = useUserBalances()

  return useQuery({
    queryKey: key(token, embeddedSmartWallet?.address),
    initialData: () => {
      if (useBalances.data && useBalances.data.balances.length > 0) {
        const balance = useBalances.data.balances.find(
          (b) => b.address?.toLowerCase() === token?.address.toLowerCase()
        )
        if (balance) {
          return balance.balance
        }
        return undefined
      }
    },
    queryFn:
      !embeddedSmartWallet.address || !token
        ? skipToken
        : async () => {
            // ETH balance
            if (token.address === '') {
              return ethBalance.data?.balance
            }
            const result = await publicClient.readContract({
              address: token.address as `0x${string}`,
              abi: erc20Abi,
              functionName: 'balanceOf',
              args: [embeddedSmartWallet.address as `0x${string}`],
            })

            return result
          },
  })
}
