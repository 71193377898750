import type { WithLogger } from '@my/utils'
import { datadog } from 'app/utils/tracking/data-dog'

export const logger: WithLogger = {
  info(args, msg) {
    if (__DEV__) {
      return console.log(msg, args)
    }
    return datadog.log.info(msg, args)
  },
  error(args, msg) {
    if (__DEV__) {
      return console.error(msg, args)
    }

    return datadog.log.error(msg, args)
  },
  warn(args, msg) {
    if (__DEV__) {
      return console.warn(msg, args)
    }

    return datadog.log.warn(msg, args)
  },
  debug(args, msg) {
    if (__DEV__) {
      return console.debug(msg, args)
    }

    return datadog.log.debug(msg, args)
  },
  fatal(args, msg) {
    if (__DEV__) {
      return console.debug(msg, args)
    }

    return datadog.log.debug(msg, args)
  },
  child(bindings) {
    const parentLogger = this

    return {
      info(args, msg) {
        parentLogger.info({ ...args, ...bindings }, msg)
      },
      error(args, msg) {
        parentLogger.error({ ...args, ...bindings }, msg)
      },
      debug(args, msg) {
        parentLogger.debug({ ...args, ...bindings }, msg)
      },
      warn(args, msg) {
        parentLogger.warn({ ...args, ...bindings }, msg)
      },
      fatal(args, msg) {
        parentLogger.fatal({ ...args, ...bindings }, msg)
      },
      child(newBindings) {
        return parentLogger.child({ ...bindings, ...newBindings })
      },
    }
  },
}
