// copied from https://github.com/software-mansion/react-native-gesture-handler/blob/main/src/components/ReanimatedSwipeable.tsx with memoization applied

import { useEventCallback } from 'app/hooks/use-event-callback'
import type React from 'react'
import {
  type ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import {
  I18nManager,
  type LayoutChangeEvent,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native'
import {
  Gesture,
  GestureDetector,
  type GestureStateChangeEvent,
  type GestureUpdateEvent,
  type PanGestureHandlerEventPayload,
  type PanGestureHandlerProps,
} from 'react-native-gesture-handler'
import Animated, {
  Extrapolation,
  type SharedValue,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'

const DRAG_TOSS = 0.05

type SwipeableExcludes = Exclude<
  keyof PanGestureHandlerProps,
  'onGestureEvent' | 'onHandlerStateChange'
>

export interface SwipeableProps extends Pick<PanGestureHandlerProps, SwipeableExcludes> {
  /**
   * Enables two-finger gestures on supported devices, for example iPads with
   * trackpads. If not enabled the gesture will require click + drag, with
   * `enableTrackpadTwoFingerGesture` swiping with two fingers will also trigger
   * the gesture.
   */
  enableTrackpadTwoFingerGesture?: boolean

  /**
   * Specifies how much the visual interaction will be delayed compared to the
   * gesture distance. e.g. value of 1 will indicate that the swipeable panel
   * should exactly follow the gesture, 2 means it is going to be two times
   * "slower".
   */
  friction?: number

  /**
   * Distance from the left edge at which released panel will animate to the
   * open state (or the open panel will animate into the closed state). By
   * default it's a half of the panel's width.
   */
  leftThreshold?: number

  /**
   * Distance from the right edge at which released panel will animate to the
   * open state (or the open panel will animate into the closed state). By
   * default it's a half of the panel's width.
   */
  rightThreshold?: number

  /**
   * Distance that the panel must be dragged from the left edge to be considered
   * a swipe. The default value is 10.
   */
  dragOffsetFromLeftEdge?: number

  /**
   * Distance that the panel must be dragged from the right edge to be considered
   * a swipe. The default value is 10.
   */
  dragOffsetFromRightEdge?: number

  /**
   * Value indicating if the swipeable panel can be pulled further than the left
   * actions panel's width. It is set to true by default as long as the left
   * panel render method is present.
   */
  overshootLeft?: boolean

  /**
   * Value indicating if the swipeable panel can be pulled further than the
   * right actions panel's width. It is set to true by default as long as the
   * right panel render method is present.
   */
  overshootRight?: boolean

  /**
   * Specifies how much the visual interaction will be delayed compared to the
   * gesture distance at overshoot. Default value is 1, it mean no friction, for
   * a native feel, try 8 or above.
   */
  overshootFriction?: number

  /**
   * Called when action panel gets open (either right or left).
   */
  onSwipeableOpen?: (direction: 'left' | 'right', swipeable: SwipeableMethods) => void

  /**
   * Called when action panel is closed.
   */
  onSwipeableClose?: (direction: 'left' | 'right', swipeable: SwipeableMethods) => void

  /**
   * Called when action panel starts animating on open (either right or left).
   */
  onSwipeableWillOpen?: (direction: 'left' | 'right') => void

  /**
   * Called when action panel starts animating on close.
   */
  onSwipeableWillClose?: (direction: 'left' | 'right') => void

  /**
   * Called when action panel starts being shown on dragging to open.
   */
  onSwipeableOpenStartDrag?: (direction: 'left' | 'right') => void

  /**
   * Called when action panel starts being shown on dragging to close.
   */
  onSwipeableCloseStartDrag?: (direction: 'left' | 'right') => void

  /**
   *
   * This map describes the values to use as inputRange for extra interpolation:
   * AnimatedValue: [startValue, endValue]
   *
   * progressAnimatedValue: [0, 1] dragAnimatedValue: [0, +]
   *
   * To support `rtl` flexbox layouts use `flexDirection` styling.
   * */
  renderLeftActions?: (
    progressAnimatedValue: SharedValue<number>,
    dragAnimatedValue: SharedValue<number>,
    swipeable: SwipeableMethods
  ) => React.ReactNode
  /**
   *
   * This map describes the values to use as inputRange for extra interpolation:
   * AnimatedValue: [startValue, endValue]
   *
   * progressAnimatedValue: [0, 1] dragAnimatedValue: [0, -]
   *
   * To support `rtl` flexbox layouts use `flexDirection` styling.
   * */
  renderRightActions?: (
    progressAnimatedValue: SharedValue<number>,
    dragAnimatedValue: SharedValue<number>,
    swipeable: SwipeableMethods
  ) => React.ReactNode

  animationOptions?: Record<string, unknown>

  /**
   * Style object for the container (`Animated.View`), for example to override
   * `overflow: 'hidden'`.
   */
  containerStyle?: StyleProp<ViewStyle>

  /**
   * Style object for the children container (`Animated.View`), for example to
   * apply `flex: 1`
   */
  childrenContainerStyle?: StyleProp<ViewStyle>
}

export interface SwipeableMethods {
  close: () => void
  openLeft: () => void
  openRight: () => void
  reset: () => void
}

export const Swipeable = forwardRef<SwipeableMethods, SwipeableProps>(function Swipeable(
  props: SwipeableProps,
  ref: ForwardedRef<SwipeableMethods>
) {
  const rowState = useSharedValue<number>(0)

  const userDrag = useSharedValue<number>(0)
  const appliedTranslation = useSharedValue<number>(0)

  const rowWidth = useSharedValue<number>(0)
  const leftWidth = useSharedValue<number>(0)
  const rightWidth = useSharedValue<number>(0)
  const rightOffset = useSharedValue<number>(0)

  const leftActionTranslate = useSharedValue<number>(0)
  const rightActionTranslate = useSharedValue<number>(0)

  const showLeftProgress = useSharedValue<number>(0)
  const showRightProgress = useSharedValue<number>(0)

  const swipeableMethods = useRef<SwipeableMethods>({
    close: () => {
      'worklet'
    },
    openLeft: () => {
      'worklet'
    },
    openRight: () => {
      'worklet'
    },
    reset: () => {
      'worklet'
    },
  })

  const defaultProps = {
    friction: 1,
    overshootFriction: 1,
  }

  const { friction = defaultProps.friction, overshootFriction = defaultProps.overshootFriction } =
    props

  const overshootLeftProp = props.overshootLeft
  const overshootRightProp = props.overshootRight

  const calculateCurrentOffset = useCallback(() => {
    'worklet'
    if (rowState.value === 1) {
      return leftWidth.value
    }
    if (rowState.value === -1) {
      return -rowWidth.value - rightOffset.value
    }
    return 0
  }, [leftWidth, rightOffset, rowState, rowWidth])

  const updateAnimatedEvent = useCallback(() => {
    'worklet'
    rightWidth.value = Math.max(0, rowWidth.value - rightOffset.value)

    const overshootLeft = overshootLeftProp ?? leftWidth.value > 0
    const overshootRight = overshootRightProp ?? rightWidth.value > 0

    const startOffset =
      rowState.value === 1 ? leftWidth.value : rowState.value === -1 ? -rightWidth.value : 0

    const offsetDrag = userDrag.value / friction + startOffset

    appliedTranslation.value = interpolate(
      offsetDrag,
      [-rightWidth.value - 1, -rightWidth.value, leftWidth.value, leftWidth.value + 1],
      [
        -rightWidth.value - (overshootRight ? 1 / overshootFriction : 0),
        -rightWidth.value,
        leftWidth.value,
        leftWidth.value + (overshootLeft ? 1 / overshootFriction : 0),
      ]
    )

    showLeftProgress.value =
      leftWidth.value > 0
        ? interpolate(appliedTranslation.value, [-1, 0, leftWidth.value], [0, 0, 1])
        : 0
    leftActionTranslate.value = interpolate(
      showLeftProgress.value,
      [0, Number.MIN_VALUE],
      [-10000, 0],
      Extrapolation.CLAMP
    )
    showRightProgress.value =
      rightWidth.value > 0
        ? interpolate(appliedTranslation.value, [-rightWidth.value, 0, 1], [1, 0, 0])
        : 0
    rightActionTranslate.value = interpolate(
      showRightProgress.value,
      [0, Number.MIN_VALUE],
      [-10000, 0],
      Extrapolation.CLAMP
    )
  }, [
    appliedTranslation,
    friction,
    leftWidth,
    overshootFriction,
    overshootLeftProp,
    rightWidth,
    rightOffset,
    rowState,
    rowWidth,
    showLeftProgress,
    showRightProgress,
    leftActionTranslate,
    rightActionTranslate,
    overshootRightProp,
    userDrag,
  ])

  const dispatchImmediateEvents = useCallback(
    (fromValue: number, toValue: number) => {
      if (toValue > 0 && props.onSwipeableWillOpen) {
        props.onSwipeableWillOpen('left')
      } else if (toValue < 0 && props.onSwipeableWillOpen) {
        props.onSwipeableWillOpen('right')
      } else if (props.onSwipeableWillClose) {
        const closingDirection = fromValue > 0 ? 'left' : 'right'
        props.onSwipeableWillClose(closingDirection)
      }
    },
    [props, props.onSwipeableWillClose, props.onSwipeableWillOpen]
  )

  const dispatchEndEvents = useCallback(
    (fromValue: number, toValue: number) => {
      if (toValue > 0 && props.onSwipeableOpen) {
        props.onSwipeableOpen('left', swipeableMethods.current)
      } else if (toValue < 0 && props.onSwipeableOpen) {
        props.onSwipeableOpen('right', swipeableMethods.current)
      } else if (props.onSwipeableClose) {
        const closingDirection = fromValue > 0 ? 'left' : 'right'
        props.onSwipeableClose(closingDirection, swipeableMethods.current)
      }
    },
    [props, props.onSwipeableClose, props.onSwipeableOpen]
  )

  const animationOptionsProp = props.animationOptions

  const animateRow = useCallback(
    (fromValue: number, toValue: number, velocityX?: number) => {
      'worklet'
      rowState.value = Math.sign(toValue)

      const springConfig = {
        duration: 1000,
        dampingRatio: 0.9,
        stiffness: 500,
        velocity: velocityX,
        overshootClamping: true,
        ...animationOptionsProp,
      }

      appliedTranslation.value = withSpring(toValue, springConfig, (isFinished) => {
        if (isFinished) {
          runOnJS(dispatchEndEvents)(fromValue, toValue)
        }
      })

      const progressTarget = toValue === 0 ? 0 : 1

      // Velocity is in px, while progress is in %
      // need to create a new object to avoid modifying the original springConfig
      // since its already workletized at this point (as it's passed to withSpring and thus immutable)
      const progressSpringConfig = {
        ...springConfig,
        velocity: 0,
      }

      showLeftProgress.value =
        leftWidth.value > 0 ? withSpring(progressTarget, progressSpringConfig) : 0
      showRightProgress.value =
        rightWidth.value > 0 ? withSpring(progressTarget, progressSpringConfig) : 0

      runOnJS(dispatchImmediateEvents)(fromValue, toValue)
    },
    [
      showLeftProgress,
      appliedTranslation,
      dispatchEndEvents,
      dispatchImmediateEvents,
      animationOptionsProp,
      rowState,
      leftWidth,
      rightWidth,
      showRightProgress,
    ]
  )

  const onRowLayout = useEventCallback(({ nativeEvent }: LayoutChangeEvent) => {
    rowWidth.value = nativeEvent.layout.width
  })

  const {
    children,
    renderLeftActions,
    renderRightActions,
    dragOffsetFromLeftEdge = 10,
    dragOffsetFromRightEdge = 10,
  } = props

  swipeableMethods.current = {
    close() {
      'worklet'
      animateRow(calculateCurrentOffset(), 0)
    },
    openLeft() {
      'worklet'
      animateRow(calculateCurrentOffset(), leftWidth.value)
    },
    openRight() {
      'worklet'
      rightWidth.value = rowWidth.value - rightOffset.value
      animateRow(calculateCurrentOffset(), -rightWidth.value)
    },
    reset() {
      'worklet'
      userDrag.value = 0
      showLeftProgress.value = 0
      appliedTranslation.value = 0
      rowState.value = 0
    },
  }

  const leftAnimatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateX: leftActionTranslate.value,
        },
      ],
    }),
    [leftActionTranslate]
  )

  const leftElement = useCallback(
    () =>
      renderLeftActions && (
        <Animated.View style={[styles.leftActions, leftAnimatedStyle]}>
          {renderLeftActions(showLeftProgress, appliedTranslation, swipeableMethods.current)}
          <View
            onLayout={({ nativeEvent }) => {
              leftWidth.value = nativeEvent.layout.x
            }}
          />
        </Animated.View>
      ),
    [renderLeftActions, appliedTranslation, showLeftProgress, leftAnimatedStyle, leftWidth]
  )

  const rightAnimatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateX: rightActionTranslate.value,
        },
      ],
    }),
    [rightActionTranslate]
  )

  const rightElement = useCallback(
    () =>
      renderRightActions && (
        <Animated.View style={[styles.rightActions, rightAnimatedStyle]}>
          {renderRightActions(showRightProgress, appliedTranslation, swipeableMethods.current)}
          <View
            onLayout={({ nativeEvent }) => {
              rightOffset.value = nativeEvent.layout.x
            }}
          />
        </Animated.View>
      ),
    [renderRightActions, appliedTranslation, showRightProgress, rightAnimatedStyle, rightOffset]
  )

  const leftThresholdProp = props.leftThreshold
  const rightThresholdProp = props.rightThreshold

  const handleRelease = useCallback(
    (event: GestureStateChangeEvent<PanGestureHandlerEventPayload>) => {
      'worklet'
      const { velocityX } = event
      userDrag.value = event.translationX

      rightWidth.value = rowWidth.value - rightOffset.value

      const leftThreshold = leftThresholdProp ?? leftWidth.value / 2
      const rightThreshold = rightThresholdProp ?? rightWidth.value / 2

      const startOffsetX = calculateCurrentOffset() + userDrag.value / friction
      const translationX = (userDrag.value + DRAG_TOSS * velocityX) / friction

      let toValue = 0

      if (rowState.value === 0) {
        if (translationX > leftThreshold) {
          toValue = leftWidth.value
        } else if (translationX < -rightThreshold) {
          toValue = -rightWidth.value
        }
      } else if (rowState.value === 1) {
        // Swiped to left
        if (translationX > -leftThreshold) {
          toValue = leftWidth.value
        }
      } else {
        // Swiped to right
        if (translationX < rightThreshold) {
          toValue = -rightWidth.value
        }
      }

      animateRow(startOffsetX, toValue, velocityX / friction)
    },
    [
      animateRow,
      calculateCurrentOffset,
      friction,
      leftThresholdProp,
      rightThresholdProp,
      leftWidth,
      rowState,
      rowWidth,
      userDrag,
      rightWidth,
      rightOffset,
    ]
  )

  const close = useCallback(() => {
    'worklet'
    animateRow(calculateCurrentOffset(), 0)
  }, [animateRow, calculateCurrentOffset])

  const tapGesture = useMemo(
    () =>
      Gesture.Tap().onStart(() => {
        if (rowState.value !== 0) {
          close()
        }
      }),
    [rowState, close]
  )

  const onSwipeableOpenStartDrag = props.onSwipeableOpenStartDrag
  const onSwipeableCloseStartDrag = props.onSwipeableCloseStartDrag

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onUpdate((event: GestureUpdateEvent<PanGestureHandlerEventPayload>) => {
          userDrag.value = event.translationX

          const direction =
            rowState.value === -1
              ? 'right'
              : rowState.value === 1
                ? 'left'
                : event.translationX > 0
                  ? 'left'
                  : 'right'

          if (rowState.value === 0 && onSwipeableOpenStartDrag) {
            runOnJS(onSwipeableOpenStartDrag)(direction)
          } else if (rowState.value !== 0 && onSwipeableCloseStartDrag) {
            runOnJS(onSwipeableCloseStartDrag)(direction)
          }
          updateAnimatedEvent()
        })
        .onEnd((event: GestureStateChangeEvent<PanGestureHandlerEventPayload>) => {
          handleRelease(event)
        }),
    [
      handleRelease,
      onSwipeableCloseStartDrag,
      onSwipeableOpenStartDrag,
      updateAnimatedEvent,
      rowState,
      userDrag,
    ]
  )

  if (props.enableTrackpadTwoFingerGesture) {
    panGesture.enableTrackpadTwoFingerGesture(props.enableTrackpadTwoFingerGesture)
  }

  panGesture.activeOffsetX([-dragOffsetFromRightEdge, dragOffsetFromLeftEdge])
  tapGesture.shouldCancelWhenOutside(true)

  useImperativeHandle(ref, () => swipeableMethods.current, [])

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateX: appliedTranslation.value }],
      pointerEvents: rowState.value === 0 ? 'auto' : 'box-only',
    }),
    [appliedTranslation, rowState]
  )

  const containerStyle = props.containerStyle
  const childrenContainerStyle = props.childrenContainerStyle

  return (
    <GestureDetector gesture={panGesture} touchAction="pan-y">
      <Animated.View onLayout={onRowLayout} style={[styles.container, containerStyle]}>
        {leftElement()}
        {rightElement()}
        <GestureDetector gesture={tapGesture} touchAction="pan-y">
          <Animated.View style={[animatedStyle, childrenContainerStyle]}>{children}</Animated.View>
        </GestureDetector>
      </Animated.View>
    </GestureDetector>
  )
})

export type SwipeableRef = ForwardedRef<SwipeableMethods>

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  leftActions: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  },
  rightActions: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: I18nManager.isRTL ? 'row' : 'row-reverse',
  },
})
