import { observer } from '@legendapp/state/react'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { logError } from 'app/utils/logging/logError'
import { logInfo } from 'app/utils/logging/logInfo'
import { abortUpload, chooseVideo, pickVideo, takeVideo } from 'app/utils/upload'
import { setVideoUploadTraceId, videoUploadState$ } from 'app/utils/upload/store/video-upload-state'
import { useRouter } from 'expo-router'
import type React from 'react'
import { useCallback } from 'react'
import { Platform } from 'react-native'
import * as DropdownMenu from 'zeego/dropdown-menu'
import { useWalletAction } from '../coinbase-smart-wallet/use-wallet-action'
import { useMe } from '../profile/hooks/use-me'

type UploadVideoButtonProps = {
  trigger: ({ onPress }: { onPress: () => void }) => React.ReactElement
}

export const UploadVideoButton = observer((props: UploadVideoButtonProps) => {
  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()
  const router = useRouter()
  const redirectToSignIn = useRedirectToSignIn()
  const authStatus = useAuthenticationStatus()
  const me = useMe()

  const isLoggedOut = authStatus !== 'authenticated'
  const isUploading = videoUploadState$.isUploading.get()

  const redirectToCreate = useCallback(() => {
    if (isLoggedOut) {
      redirectToSignIn()
    } else {
      router.navigate('/create')
    }
  }, [router, isLoggedOut, redirectToSignIn])

  const uploadAction = useCallback(async () => {
    const traceId = setVideoUploadTraceId()
    try {
      logInfo({
        action: 'VIDEO_UPLOAD',
        type: 'TAP',
        message: 'Upload video button pressed',
        traceId,
        data: {
          user: {
            id: me.data?.id,
            username: me.data?.username,
            aaAddress: me.data?.aaAddress,
            farcasterConnected: me.data?.farcasterConnected,
          },
        },
      })
      if (isUploading) {
        abortUpload()
      } else {
        await pickVideo()
        redirectToCreate()
      }
    } catch (error) {
      logError({
        level: 'error',
        action: 'VIDEO_UPLOAD',
        type: 'TAP',
        message: 'Upload video button press failed',
        error,
        indexedTags: {
          traceId,
          ...(me.data && { userId: me.data.id, username: me.data.username }),
        },
      })
    }
  }, [isUploading, redirectToCreate, me.data])

  const handleChooseVideo = useWalletAction(async () => {
    setVideoUploadTraceId()
    const success = await chooseVideo()
    if (success) redirectToCreate()
  })

  const legacyHandleChooseVideo = async () => {
    setVideoUploadTraceId()
    const success = await chooseVideo()
    if (success) redirectToCreate()
  }

  const handleTakeVideo = useWalletAction(async () => {
    setVideoUploadTraceId()
    const success = await takeVideo()
    if (success) redirectToCreate()
  })

  const legacyHandleTakeVideo = async () => {
    setVideoUploadTraceId()
    const success = await takeVideo()
    if (success) redirectToCreate()
  }

  const handlePhotoLibraryVideo = useWalletAction(async () => {
    setVideoUploadTraceId()
    const success = await pickVideo()
    if (success) redirectToCreate()
  })

  const legacyHandlePhotoLibraryVideo = async () => {
    setVideoUploadTraceId()
    const success = await pickVideo()
    if (success) redirectToCreate()
  }

  const handleWebUpload = useWalletAction(uploadAction)

  if (Platform.OS === 'web') {
    return props.trigger({ onPress: handleWebUpload })
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>{props.trigger({ onPress: () => {} })}</DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {isUploading ? (
          <DropdownMenu.Item key="b_library" onSelect={abortUpload} destructive>
            <DropdownMenu.ItemTitle>Abort upload</DropdownMenu.ItemTitle>
            <DropdownMenu.ItemIcon ios={{ name: 'clear' }} />
          </DropdownMenu.Item>
        ) : (
          <>
            <DropdownMenu.Item
              key="b_library"
              onSelect={isWeb3ActionsDisabled ? legacyHandleChooseVideo : handleChooseVideo}>
              <DropdownMenu.ItemTitle>Choose Video</DropdownMenu.ItemTitle>
              <DropdownMenu.ItemIcon ios={{ name: 'folder' }} />
            </DropdownMenu.Item>
            <DropdownMenu.Item
              key="c_camera"
              onSelect={isWeb3ActionsDisabled ? legacyHandleTakeVideo : handleTakeVideo}>
              <DropdownMenu.ItemTitle>Take Video</DropdownMenu.ItemTitle>
              <DropdownMenu.ItemIcon ios={{ name: 'camera' }} />
            </DropdownMenu.Item>
            <DropdownMenu.Item
              key="a_roll"
              onSelect={
                isWeb3ActionsDisabled ? legacyHandlePhotoLibraryVideo : handlePhotoLibraryVideo
              }>
              <DropdownMenu.ItemTitle>Photo Library</DropdownMenu.ItemTitle>
              <DropdownMenu.ItemIcon ios={{ name: 'photo.on.rectangle' }} />
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
})
