import { BaseIcon, cn, LeanText, LeanView, Pressable } from '@my/ui'
import { skipToken } from '@tanstack/react-query'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { trpc } from 'app/utils/api'
import { chain } from 'app/utils/chain'
import { formatNumberForPrice, formatShortNumber } from 'app/utils/common'
import { Image } from 'expo-image'
import { useRouter } from 'expo-router'
import { useMemo } from 'react'
import { useWalletAction } from '../coinbase-smart-wallet/use-wallet-action'
import { PercentageChange } from '../common/percentage-change'
import { useNavigateToBuyToken } from '../token/hooks/use-navigate-to-buy-token'
import { TokenVerifiedBadge } from '../token/token-verified-badge'
import { useMe } from './hooks/use-me'
import { useUserByUsername } from './hooks/use-user-by-username'

type Props = {
  username: string
}

export const ProfileAddTokenCard = (props: Props) => {
  const user = useUserByUsername({ username: props.username })
  const hasToken = !!user.data?.userPinnedToken?.address

  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()
  if (isWeb3ActionsDisabled) {
    return null
  }

  if (hasToken) {
    return <HasToken {...props} />
  }
}

const HasToken = (props: Props) => {
  const router = useRouter()
  const user = useUserByUsername({ username: props.username })
  const pinnedToken = user.data?.userPinnedToken
  const me = useMe()
  const navigateToBuyToken = useNavigateToBuyToken()
  const tokenStats = trpc.tokenContract.getTokenStats.useQuery(
    pinnedToken?.address
      ? [
          {
            address: pinnedToken.address.toLowerCase() as `0x${string}`,
            networkId: chain.id,
          },
        ]
      : skipToken
  )
  const token = tokenStats.data?.results?.[0]

  const handleBuyPress = (amount: string) => {
    if (pinnedToken?.address) {
      navigateToBuyToken({
        tokenAddress: pinnedToken.address,
        amount,
        username: props.username,
        userPinnedToken: pinnedToken,
        location: isMyProfile ? undefined : 'user',
        referral: isMyProfile ? undefined : user.data?.userDetails.id,
      })
    }
  }
  const tokenStat = tokenStats.data?.results?.[0]

  const formattedUsdcQuote = useMemo(() => {
    if (!tokenStat?.priceUSD) return '0'
    return formatNumberForPrice(Number(tokenStat.priceUSD))
  }, [tokenStat?.priceUSD])

  const handleWalletAction = useWalletAction(handleBuyPress)

  const isMyProfile = me.data?.id === user.data?.userDetails.id

  const viewStyle = useMemo(
    () =>
      cn('w-full', user.isRefetching ? 'animate-[pulse_400ms_linear_infinite]' : 'animate-none'),
    [user.isRefetching]
  )

  return (
    <Pressable
      key="profile-token-card"
      className={viewStyle}
      onPress={() => {
        router.navigate(`/token/${pinnedToken?.address}`)
      }}>
      {isMyProfile ? (
        <Pressable
          onPress={() => {
            router.push('/select-token')
          }}
          className="items-center justify-center flex-row gap-2 bg-surfaceDarkShade1 rounded-3xl py-2 px-4 self-end top-[-16px] absolute z-[999]">
          <Image
            source={require('@my/ui/src/icons/edit-pencil-svg.svg')}
            style={{ width: 12, height: 12 }}
          />
          <LeanText className="text-grayTextColor text-sm">Edit token</LeanText>
        </Pressable>
      ) : null}

      <LeanView className="rounded-2xl py-8 px-4 gap-8 bg-surfaceDarkShade1">
        <LeanView className="flex-row items-center justify-between">
          <LeanView className="flex-row gap-4 items-center">
            <LeanView className="self-start rounded-full bg-neutral-800">
              {tokenStats.isPending ? (
                <LeanView
                  key="stat-1"
                  className="w-[42px] h-[42px] bg-borderShade1 rounded-full animate-pulse"
                />
              ) : token?.token?.info?.imageLargeUrl ? (
                <Image
                  source={{
                    uri: token?.token?.info?.imageLargeUrl ?? undefined,
                  }}
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 99,
                  }}
                />
              ) : (
                <LeanView className="w-[42px] h-[42px] bg-borderShade1 rounded-full" />
              )}
              <LeanView className="absolute bottom-[-4px] right-[-4px]">
                <BaseIcon width={16} height={17} />
              </LeanView>
            </LeanView>
            <LeanView className="gap-1">
              {tokenStats.isPending ? (
                <LeanView
                  key={'stat-2'}
                  className="w-[80px] h-[16px] bg-gray-500 rounded-full animate-pulse"
                />
              ) : (
                <LeanView className="flex-row gap-[6px] items-center">
                  <LeanText className="text-white font-semibold text-base">
                    {token?.token?.symbol}
                  </LeanText>
                  <TokenVerifiedBadge token={pinnedToken} size={20} />
                </LeanView>
              )}
              {tokenStats.isPending ? (
                <LeanView
                  key="stat-3"
                  className="w-[80px] h-[16px] bg-gray-500 rounded-full animate-pulse"
                />
              ) : (
                <LeanText className="text-grayTextColor">
                  {tokenStat ? `$${formatShortNumber(Number(tokenStat.marketCap))} MKT CAP` : ''}
                </LeanText>
              )}
            </LeanView>
          </LeanView>
          <LeanView className="gap-1">
            {tokenStats.isPending ? (
              <LeanView key="stat-4" className="gap-2">
                <LeanView className="w-[60px] h-[16px] bg-gray-500 rounded-full animate-pulse" />
                <LeanView className="w-[60px] h-[12px] bg-gray-500 rounded-full animate-pulse" />
              </LeanView>
            ) : (
              <LeanView key="stat-5">
                <LeanText className="text-white text-base">${formattedUsdcQuote}</LeanText>
                {tokenStat && <PercentageChange value={Number(tokenStat.change24) * 100} />}
              </LeanView>
            )}
          </LeanView>
        </LeanView>
        {!isMyProfile ? (
          <LeanView className="flex-row justify-center gap-4">
            <Pressable
              scale
              opacity
              className="bg-borderShade1 rounded-[48px] px-4 py-2"
              onPress={() => handleWalletAction('5')}>
              <LeanText className="text-white font-semibold">$5</LeanText>
            </Pressable>
            <Pressable
              scale
              opacity
              className="bg-borderShade1 rounded-[48px] px-4 py-2"
              onPress={() => handleWalletAction('69')}>
              <LeanText className="text-white font-semibold">$69</LeanText>
            </Pressable>
            <Pressable
              scale
              opacity
              className="bg-borderShade1 rounded-[48px] px-4 py-2"
              onPress={() => handleWalletAction('420')}>
              <LeanText className="text-white font-semibold">$420</LeanText>
            </Pressable>
            <Pressable
              scale
              opacity
              className="bg-borderShade1 rounded-[48px] px-4 py-2"
              onPress={() => handleWalletAction('')}>
              <LeanText className="text-white font-semibold">Custom</LeanText>
            </Pressable>
          </LeanView>
        ) : null}
      </LeanView>
    </Pressable>
  )
}
