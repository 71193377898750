import { Buffer } from 'buffer'

// Required by coinbase wallet sdk
// start
if (typeof window !== 'undefined' && !window.Buffer) {
  window.Buffer = Buffer
}
// end

console.warn = () => {}

// @ts-expect-error - Required by some libraries that serialise BigInts. e.g. react query dev tools, cache etc
BigInt.prototype.toJSON = function () {
  return this.toString()
}
