import { toast } from '@my/ui'
import { trpc } from 'app/utils/api'
import { logError } from 'app/utils/logging/logError'

export const useBlockUser = (props: {
  username?: string | null
}) => {
  const trpcUtils = trpc.useUtils()
  const blockUser = trpc.report.block.useMutation({
    onSuccess: () => {
      toast.user.block.success({ message: `Successfully blocked @${props.username}` })

      trpcUtils.post.feedV2.refetch()
      if (props.username) {
        trpcUtils.user.byUsername.cancel({
          username: props.username,
        })
        trpcUtils.user.byUsername.setData(
          {
            username: props.username,
          },
          (d) => {
            if (d) {
              return {
                ...d,
                userDetails: {
                  ...d.userDetails,
                  isBlockedByMe: true,
                },
              }
            }
          }
        )
      }
    },
    onError: (error, params) => {
      logError({
        action: 'BLOCK_USER',
        error,
        level: 'error',
        type: 'CUSTOM',
        message: `Failed to block user: ${params.userId}`,
      })
      toast.user.block.error()
    },
  })

  const unblockUser = trpc.report.unblock.useMutation({
    onSuccess: () => {
      toast.user.unblock.success({ message: `Successfully unblocked @${props.username}` })

      trpcUtils.post.feedV2.refetch()
      if (props.username) {
        trpcUtils.user.byUsername.cancel({
          username: props.username,
        })
        trpcUtils.user.byUsername.setData(
          {
            username: props.username,
          },
          (d) => {
            if (d) {
              return {
                ...d,
                userDetails: {
                  ...d.userDetails,
                  isBlockedByMe: false,
                },
              }
            }
          }
        )
      }
    },
    onError: (error, params) => {
      logError({
        action: 'UNBLOCK_USER',
        error,
        level: 'error',
        type: 'CUSTOM',
        message: `Failed to unblock user: ${params.userId}`,
      })
      toast.user.unblock.error()
    },
  })
  return { blockUser, unblockUser }
}
