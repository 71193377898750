import { dismiss, toast } from '@my/ui'
import { trpc } from 'app/utils/api'
import { logError } from 'app/utils/logging/logError'

export const useUpdateProfileWithTwitterDetails = () => {
  const trpcUtils = trpc.useUtils()

  const updateProfileWithTwitterDetails = trpc.user.updateProfileWithTwitterDetails.useMutation({
    onError: (error) => {
      logError({
        type: 'TAP',
        level: 'error',
        action: 'LINK_ACCOUNT',
        error,
        message: 'Failed to use twitter profile data',
      })

      dismiss('profile-update-twitter-loading')

      toast.profile.update.farcaster.error()
    },
    onMutate: () => {
      dismiss('profile-link-twitter-success')
      toast.profile.update.twitter.loading()
    },
    onSuccess: (data) => {
      trpcUtils.user.me.setData(undefined, (d) => {
        if (!d) return
        const updaterObject = {
          ...d,
          name: data.name,
          bio: data.bio,
          profileImage: data.profileImage,
        }
        return updaterObject
      })

      trpcUtils.user.byUsername.setData(
        {
          username: data.username,
        },
        (d) => {
          if (!d) return
          return {
            ...d,
            userDetails: {
              ...d.userDetails,
              name: data.name,
              bio: data.bio,
              profileImage: data.profileImage,
            },
          }
        }
      )

      dismiss('profile-update-twitter-loading')
    },
  })

  return updateProfileWithTwitterDetails
}
