import { observable } from '@legendapp/state'
import * as Crypto from 'expo-crypto'
import type * as DocumentPicker from 'expo-document-picker'
import type * as ImagePicker from 'expo-image-picker'
import * as tus from 'tus-js-client'

export type VideoPath =
  | ImagePicker.ImagePickerAsset
  | DocumentPicker.DocumentPickerAsset
  | File
  | null
// Upload store with LegendApp State
export const videoUploadState$ = observable({
  selectActionStarted: false,
  videoPath: null as VideoPath,
  uploadProgress: 0,
  isUploading: false,
  uploadInstance: new tus.Upload(new Blob(), {}),
  traceId: null as string | null,
  thumbnailTimestamp: 0,
  setSelectedVideo: (videoPath: VideoPath) => {
    videoUploadState$.set((s) => ({
      ...s,
      videoPath,
      thumbnailTimestamp: 0,
    }))
  },
})

export const setVideoUploadTraceId = () => {
  const traceId = Crypto.randomUUID()
  videoUploadState$.traceId.set(traceId)
  return traceId
}
