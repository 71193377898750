import Svg, { Circle, G, Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
  focused?: boolean
}

export function SearchIcon(props: Props) {
  if (props.focused) {
    return (
      <Svg
        width={props.width ?? 22}
        height={props.height ?? 22}
        viewBox="11 12 26 26"
        fill="none"
        stroke={props.color ?? 'white'}>
        <G stroke="#fff" strokeLinejoin="round" strokeWidth={1.961}>
          <Circle cx={23.244} cy={24.337} r={10.128} strokeLinecap="round" strokeWidth={3} />
          <Path strokeLinecap="square" d="m30.517 31.52 5.307 5.306" strokeWidth={3} />
        </G>
      </Svg>
    )
  }

  return (
    <Svg
      width={props.width ?? 22}
      height={props.height ?? 22}
      viewBox="11 12 26 26"
      fill="none"
      stroke={props.color ?? 'white'}>
      <G stroke="#fff" strokeLinejoin="round" strokeWidth={1.961}>
        <Circle cx={23.244} cy={24.337} r={10.128} strokeLinecap="round" />
        <Path strokeLinecap="square" d="m30.517 31.52 5.307 5.306" />
      </G>
    </Svg>
  )
}
