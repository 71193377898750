import { LeanText, LeanView } from '@my/ui'

export const TokenGraphPlaceholder = () => {
  return (
    <LeanView className="h-[300px] md:h-[420px] items-center pt-24 md:pt-32 gap-3">
      <LeanText className="text-white text-2xl font-semibold">Wow, you're early!</LeanText>
      <LeanText className="text-grayTextColor text-lg">
        We don't have enough data yet to display a graph.
      </LeanText>
    </LeanView>
  )
}
