import { useEventCallback } from 'app/hooks/use-event-callback'
import * as Haptics from 'expo-haptics'
import { type LegacyRef, forwardRef, useMemo } from 'react'
import {
  type GestureResponderEvent,
  Platform,
  Pressable as RNPressable,
  type PressableProps as RNPressableProps,
  type View as RNView,
} from 'react-native'
import Animated from 'react-native-reanimated'
import { cn } from '..'

type PressableType = RNPressableProps & { haptics?: boolean; scale?: boolean; opacity?: boolean }

const Pressable = forwardRef<typeof RNPressable, PressableType>((props, ref) => {
  const { haptics = true, scale = false, opacity = false, onPress, className } = props

  const defaultStyles = useMemo(
    () =>
      cn(
        'transition-all duration-200',
        scale ? 'scale-100 active:scale-95' : '',
        opacity ? 'opacity-100 active:opacity-60' : '',
        className
      ),
    [className, scale, opacity]
  )

  const onPressCallback = useEventCallback((event: GestureResponderEvent) => {
    if (onPress) {
      onPress(event)
    }
    if (haptics && Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium).catch(() => {})
    }
  })

  return (
    <RNPressable
      hitSlop={15}
      {...props}
      className={defaultStyles}
      ref={ref as LegacyRef<RNView>}
      onPress={onPressCallback}
    />
  )
})
Pressable.displayName = 'Pressable'

type PressableProps = PressableType

const AnimatedPressable = Animated.createAnimatedComponent(RNPressable)

export { AnimatedPressable, Pressable, type PressableProps }
