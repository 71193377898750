import { type LegacyRef, forwardRef } from 'react'
import {
  View as RNView,
  type ViewProps as RNViewProps,
  type ViewStyle as RNViewStyle,
} from 'react-native'
import Animated from 'react-native-reanimated'

const View = forwardRef<typeof RNView, RNViewProps>((props, ref) => {
  return <RNView {...props} ref={ref as LegacyRef<RNView>} />
})
View.displayName = 'View'

type ViewProps = RNViewProps
type ViewStyle = RNViewStyle

const AnimatedView = Animated.createAnimatedComponent(View)

export { AnimatedView, View, type ViewProps, type ViewStyle }
