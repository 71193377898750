import Svg, { Rect, Path } from 'react-native-svg'
import { theme } from '../theme'

type Props = {
  width?: number
  height?: number
  variant?: 'primary' | 'secondary' | 'tertiary'
}

export function CreateTabIcon(props: Props) {
  const variant = props.variant ?? 'primary'
  return (
    <Svg width={props.width ?? 35} height={props.height ?? 24} viewBox="0 0 35 24" fill="none">
      {variant === 'tertiary' ? (
        <>
          <Rect x={0.201172} y={0.609375} width={23} height={23} rx={3} fill="#fff" />
          <Path
            d="M21.876 12.975a1 1 0 010-1.732l10.356-5.979a1 1 0 011.5.866V18.09a1 1 0 01-1.5.866l-10.356-5.98z"
            fill="#fff"
          />
          <Path d="M11.701 7.083v10.053M6.675 12.11h10.053" stroke="#000" strokeWidth={2} />
        </>
      ) : (
        <>
          <Rect
            x={0.201172}
            y={0.609375}
            width={23}
            height={23}
            rx={3}
            fill={variant === 'primary' ? theme.colors.primary : '#fff'}
          />
          <Path
            d="M21.876 12.975a1 1 0 010-1.732l10.356-5.979a1 1 0 011.5.866V18.09a1 1 0 01-1.5.866l-10.356-5.98z"
            fill={variant === 'primary' ? theme.colors.primary : '#fff'}
          />
          <Path
            d="M11.701 7.083v10.053M6.675 12.11h10.053"
            stroke={variant === 'primary' ? '#121212' : theme.colors.primary}
            strokeWidth={2}
          />
        </>
      )}
    </Svg>
  )
}
