import { useBatchedState } from 'app/hooks/batching/use-batched-state'
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { Platform } from 'react-native'

// Change the context value type to an object
export const MuteContext = createContext<{
  muted: boolean
  setMuted: Dispatch<SetStateAction<boolean>>
}>({
  muted: true,
  setMuted: () => {},
})

const useMuteOnVisibilityChange = () => {
  // Use the context directly inside the custom hook
  const { muted, setMuted } = useContext(MuteContext)
  const wasMuted = useRef(muted)

  useEffect(() => {
    const handleFocus = () => {
      if (document.visibilityState === 'hidden') {
        if (!muted) {
          setMuted(true)
          wasMuted.current = muted
        }
      } else {
        if (!wasMuted.current) {
          setMuted(false)
        }
      }
    }

    if (Platform.OS === 'web') {
      window.addEventListener('visibilitychange', handleFocus, false)
    }

    // Cleanup
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('visibilitychange', handleFocus, false)
      }
    }
  }, [muted, setMuted]) // Use muted and setMuted as dependencies
}

export const MuteProvider = ({ children }: { children: ReactNode }) => {
  // Initialize state based on platform
  const [muted, setMuted] = useBatchedState(Platform.OS === 'web')

  // Use the custom hook inside the provider
  useMuteOnVisibilityChange()

  // Provide an object instead of an array
  return <MuteContext.Provider value={{ muted, setMuted }}>{children}</MuteContext.Provider>
}

export const useMuted = () => {
  // Here we just return the context directly
  return useContext(MuteContext)
}
