import { LeanView, theme } from '@my/ui'
import { HeaderShownContext } from '@react-navigation/elements'
import { Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

const SHOULD_FIX_HEADER = Platform.OS === 'android'

function Spacer() {
  const insets = useSafeAreaInsets()

  if (!SHOULD_FIX_HEADER) return null
  return (
    <LeanView
      style={{ paddingTop: insets.top, backgroundColor: theme.colors.appDarkBg }}
      collapsable={false}
    />
  )
}

/**
 * NestedTabBarHeaderFix is a component that fixes the header when using a nested stack navigator inside a tab navigator.
 * React Navigation has an outstanding issue with the header causing an initial flicker when navigating to a screen with a header.
 * See https://github.com/react-navigation/react-navigation/issues/11353
 *
 * Luckily, Expo Router allows wrapping Stacks and Screens with views.
 */
export function NestedTabBarHeaderFix({ children }: { children?: React.ReactNode }) {
  return (
    <HeaderShownContext.Provider value={SHOULD_FIX_HEADER}>
      <Spacer />

      {children}
    </HeaderShownContext.Provider>
  )
}
