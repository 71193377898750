import FontAwesome from '@expo/vector-icons/FontAwesome'
import { AnimatedLeanView, LeanText, LeanView, theme } from '@my/ui'
import { type ViewProps } from 'react-native'
import {
  FadeInDown,
  FadeOutUp,
  interpolate,
  LinearTransition,
  useAnimatedStyle,
  useDerivedValue,
  withSpring,
} from 'react-native-reanimated'

export type TransactionItem = {
  key: string
  price: string
}

type TransactionItemProps = {
  item: TransactionItem
  index: number
}
type AnimatedTransactionItemsProps = {
  data: TransactionItem[]
} & ViewProps

export const MAX_MESSAGES = 6
const SPACING = 4
const ITEM_SIZE = 28
const DAMPING = 80
const STIFFNESS = 200

export function AnimatedTransactionItem({ item, index }: TransactionItemProps) {
  const animatedIndex = useDerivedValue(() =>
    withSpring(index, { damping: DAMPING, stiffness: STIFFNESS })
  )

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animatedIndex.value, [0, 1], [1, 0.8]),
  }))

  return (
    <AnimatedLeanView
      layout={LinearTransition.springify().damping(DAMPING).stiffness(STIFFNESS)}
      entering={FadeInDown.springify().damping(DAMPING).stiffness(STIFFNESS)}
      exiting={FadeOutUp.springify().damping(DAMPING).stiffness(STIFFNESS)}>
      <AnimatedLeanView
        style={[
          {
            flexDirection: 'row',
            gap: SPACING * 2,
            alignItems: 'center',
            backgroundColor: '#ffffff',
            paddingVertical: SPACING,
            paddingHorizontal: SPACING * 2,
            borderRadius: 9999,
          },
          animatedStyle,
        ]}>
        <LeanView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: SPACING * 2,
          }}>
          <FontAwesome name="user-circle-o" size={14} color={theme.colors.grayTextColor} />
          <LeanText className="text-sm text-black">bought</LeanText>
        </LeanView>
        <LeanText className="text-[#00A023] text-sm font-semibold">{item.price}</LeanText>
      </AnimatedLeanView>
    </AnimatedLeanView>
  )
}

export function AnimatedTransactionItems({ data, style, ...rest }: AnimatedTransactionItemsProps) {
  return (
    <AnimatedLeanView
      {...rest}
      style={[
        {
          height: (ITEM_SIZE + SPACING * 2) * (MAX_MESSAGES - 1),
          gap: SPACING * 2,
          alignItems: 'flex-start',
          flexDirection: 'column-reverse',
        },
        style,
      ]}
      pointerEvents="none"
      layout={LinearTransition.springify().damping(DAMPING).stiffness(STIFFNESS)}>
      {data.map((item, index) => (
        <AnimatedTransactionItem item={item} index={index} key={item.key} />
      ))}
    </AnimatedLeanView>
  )
}
