import { LeanText, Pressable } from '@my/ui'
import { useBatchedState } from 'app/hooks/batching/use-batched-state'

type CollapsibleTextProps = {
  initialNumberOfLines: number
  children?: string | null
  className?: string
}

export const CollapsibleText = (props: CollapsibleTextProps) => {
  const { initialNumberOfLines, children, className } = props
  const [numberOfLines, setNumberOfLines] = useBatchedState<undefined | number>(
    initialNumberOfLines
  )

  return (
    <Pressable
      onPress={() => {
        setNumberOfLines(typeof numberOfLines === 'number' ? undefined : initialNumberOfLines)
      }}>
      <LeanText numberOfLines={numberOfLines} className={className} ellipsizeMode="tail">
        {children}
      </LeanText>
    </Pressable>
  )
}
