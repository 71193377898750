import type { RouterOutputs } from '@my/api'
import { LeanView, theme } from '@my/ui'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { trpc } from 'app/utils/api'
import { chain } from 'app/utils/chain'
import { useEffect, useMemo, useState } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'
import type { CategoricalChartState } from 'recharts/types/chart/types'
import { priceStore } from './price-store'
import { TokenGraphError } from './token-graph-error'
import { TokenGraphFallback } from './token-graph-fallback'
import { TokenGraphPlaceholder } from './token-graph-placeholder'
import { TokenGraphRange, type TokenGraphRangeOption } from './token-graph-range'

type InitialChartData = RouterOutputs['tokenPage']['token']['initialChartData']

const TokenGraphWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <LeanView className="h-[300px] md:h-[420px] md:w-[500px]">{children}</LeanView>
}

export const TokenGraph = ({
  address,
  initialChartData,
  currentPrice,
}: { address: string; initialChartData: InitialChartData; currentPrice: number }) => {
  const [activeRangeOption, setActiveRangeOption] = useState<TokenGraphRangeOption>('4h')
  const [fallbackData, setFallbackData] = useState<typeof data>([])

  const { data, error, refetch, isPending } = trpc.tokenPage.chart.useQuery(
    {
      address: address.toLowerCase(),
      networkId: chain.id,
      range: activeRangeOption,
    },
    {
      initialData: initialChartData,
      // instead of showing an unresponsive line graph with stale data, we want to show the error with a retry button straight away
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  const chartData = useMemo(() => {
    return data ?? initialChartData ?? fallbackData ?? []
  }, [data, initialChartData, fallbackData])

  const handleRangeOptionChange = useEventCallback((rangeOption: TokenGraphRangeOption) => {
    setActiveRangeOption(rangeOption)
  })

  const handleMouseMove = useEventCallback((chartState: CategoricalChartState) => {
    const payload = chartState.activePayload?.[0]?.payload
    if (payload?.date && payload?.value) {
      priceStore.selectedDate.set(payload.date * 1000)
      priceStore.currentPrice.set(payload.value)
    }
  })

  const handleMouseLeave = useEventCallback(() => {
    priceStore.reset(currentPrice)
  })

  useEffect(() => {
    if (chartData?.length) {
      priceStore.initialPrice.set(chartData[0]?.value || 0)
      priceStore.currentPrice.set(currentPrice)
      setFallbackData(chartData)
    }
  }, [currentPrice, chartData])

  // we only want to show the fallback if the chart is loading price data for the first time
  // if we select another range, we want to continue showing the previous range option's data to allow for a smooth graph change animation
  if (isPending && !chartData?.length) {
    return (
      <TokenGraphWrapper>
        <TokenGraphFallback />
      </TokenGraphWrapper>
    )
  }

  if (!chartData?.length) {
    return (
      <TokenGraphWrapper>
        <TokenGraphPlaceholder />
      </TokenGraphWrapper>
    )
  }

  if (error) {
    return (
      <TokenGraphWrapper>
        <TokenGraphError reset={refetch} />
      </TokenGraphWrapper>
    )
  }

  return (
    <>
      <TokenGraphWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={chartData} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#00F4365D" />
                <stop offset="50%" stopColor="#00F4364D" />
                <stop offset="100%" stopColor="#00F43600" />
              </linearGradient>
            </defs>
            <Area
              type="natural"
              dataKey="value"
              stroke={theme.colors.secondary}
              fill="url(#colorValue)"
              strokeWidth={2}
              dot={false}
              animationDuration={300}
            />
            <Tooltip content={() => null} />
          </AreaChart>
        </ResponsiveContainer>
      </TokenGraphWrapper>
      <TokenGraphRange
        activeRangeOption={activeRangeOption}
        handleRangeOptionChange={handleRangeOptionChange}
      />
    </>
  )
}
