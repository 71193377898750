import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const MoreIcon = (props: Props) => (
  <Svg fill="none" width={40} height={40} viewBox="0 0 60 60" {...props}>
    <Path
      fill="#fff"
      d="M20 30a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm12 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm12 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"
    />
  </Svg>
)
