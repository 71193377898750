import { View } from '@my/ui'
import React, { useEffect, useState } from 'react'
import { TextInput } from 'react-native-gesture-handler'
import { ALLOWED_INPUT_REGEX } from '../../buy-token/constants'
import { SuggestionButton } from './suggestion-button'

export const InScreenDollarKeyboard = ({
  placeholder,
  quickSuggestions,
  value,
  onChangeText,
}: {
  placeholder?: string
  value: string
  quickSuggestions: Array<{
    label: string
    onPress: () => void
  }>
  onChangeText: (text: string) => void
}) => {
  const [usdAmountWithDollarSign, setUsdAmount] = useState(value.length > 0 ? `$${value}` : '')

  const handleChangeText = (textWithDollarSign: string) => {
    const text = textWithDollarSign.replace('$', '')
    if (ALLOWED_INPUT_REGEX.test(text)) {
      onChangeText(text)
      setUsdAmount(`$${text}`)
    }
  }

  useEffect(() => {
    if (value.length > 0) {
      setUsdAmount(`$${value}`)
    }
  }, [value])

  return (
    <>
      <TextInput
        textAlign="center"
        className="text-white text-center flex-[1] web:outline-none text-[64px]"
        placeholderTextColor="#373737"
        placeholder={placeholder}
        onChangeText={handleChangeText}
        keyboardType="decimal-pad"
        value={usdAmountWithDollarSign}
      />
      <View className="gap-6">
        <View className="gap-4 flex-row justify-center">
          {quickSuggestions.map((suggestion) => {
            return <SuggestionButton key={suggestion.label} suggestion={suggestion} />
          })}
        </View>
      </View>
    </>
  )
}
