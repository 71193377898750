import type { RouterInputs } from '@my/api'
import { Pressable, Text, View, cn } from '@my/ui'

export type TokenGraphRangeOption = RouterInputs['tokenPage']['chart']['range']

const RANGE_OPTIONS: { label: string; value: TokenGraphRangeOption }[] = [
  // TODO: add live prices
  // {
  //   label: 'LIVE',
  //   value: 'live',
  // },
  {
    label: '4H',
    value: '4h',
  },
  {
    label: '1D',
    value: '1d',
  },
  {
    label: '1W',
    value: '1w',
  },
  {
    label: '1M',
    value: '1m',
  },
  // TODO: add max range
  // {
  //   label: 'MAX',
  //   value: 'max',
  // },
]

export function TokenGraphRange({
  activeRangeOption,
  handleRangeOptionChange,
  loading = false,
}: {
  activeRangeOption: TokenGraphRangeOption
  handleRangeOptionChange: (rangeOption: TokenGraphRangeOption) => void
  loading?: boolean
}) {
  return (
    <View className="flex-row items-center justify-center gap-5">
      {RANGE_OPTIONS.map((rangeOption) => (
        <Pressable
          key={rangeOption.label}
          disabled={loading}
          onPress={() => handleRangeOptionChange(rangeOption.value)}
          className={cn(
            'py-1 px-3.5',
            activeRangeOption === rangeOption.value ? 'bg-white rounded-full' : ''
          )}>
          <Text
            className={cn(
              'font-semibold text-center',
              activeRangeOption === rangeOption.value ? 'text-black' : 'text-white'
            )}>
            {rangeOption.label}
          </Text>
        </Pressable>
      ))}
    </View>
  )
}
