import { toast } from '@my/ui'
import { useTwitterOauth } from 'app/provider/twitter-oauth-provider'
import { trpc } from 'app/utils/api'
import { logError } from 'app/utils/logging/logError'
import { Platform } from 'react-native'
import { useUpdateProfileWithTwitterDetails } from './use-update-profile-with-twitter-details'

export const useSaveTwitterDetails = () => {
  const utils = trpc.useUtils()
  const updateProfileWithTwitterDetails = useUpdateProfileWithTwitterDetails()
  const { setTokens } = useTwitterOauth()

  const saveTwitterDetails = trpc.user.saveTwitterDetails.useMutation({
    onError: (error) => {
      logError({
        type: 'TAP',
        level: 'error',
        action: 'LINK_ACCOUNT',
        error,
        message: 'Failed to save twitter details',
      })
    },
    onSuccess: () => {
      setTokens(null)
      toast.profile.link.twitter.success({
        message: 'Successfully linked X account',
        description:
          Platform.OS !== 'web'
            ? 'Do you want to use your X name, bio, and profile picture on Drakula?'
            : undefined,
        action:
          // TODO: add toasts with buttons on web
          Platform.OS !== 'web'
            ? () => {
                updateProfileWithTwitterDetails.mutate()
              }
            : undefined,
      })
      // we invalidate so that the Link X button disappears
      utils.user.me.invalidate()
    },
    retry: 3,
  })

  return saveTwitterDetails
}
