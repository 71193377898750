import { AnimatedLeanView, HeartIcon, LeanView, theme } from '@my/ui'
import { mixpanel } from 'app/utils/tracking'
import * as Haptics from 'expo-haptics'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import {
  Easing,
  cancelAnimation,
  makeMutable,
  useAnimatedStyle,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated'
import { useAuthenticationStatus } from '../../utils/auth/use-authentication-status'
import { events } from '../../utils/tracking/events'
import { useLikePost } from './hooks/use-like-post'
import type { TogglePauseRef, VideoFeedItemProps } from './types'

type VideoGestureContainerProps = {
  children: React.ReactNode
  post: VideoFeedItemProps['item']
  togglePauseRef: React.MutableRefObject<TogglePauseRef>
}

const TIP_DIMENSIONS = 100

export const VideoGestureContainer = memo(
  ({ children, post, togglePauseRef }: VideoGestureContainerProps) => {
    const authStatus = useAuthenticationStatus()
    const { likePost } = useLikePost({ likeCount: post.postLikeCount })

    // we use makeMutable instead of useSharedValue because of rules of hooks
    const iconData = useMemo(
      () =>
        Array.from({ length: 5 }, () => ({
          x: makeMutable(0),
          y: makeMutable(0),
          scale: makeMutable(0),
          rotation: makeMutable(0),
          opacity: makeMutable(0),
        })),
      []
    )

    useEffect(() => {
      // Cleanup the animations when the component unmounts
      return () => {
        // need to call cancelAnimation because we're using makeMutable
        for (const icon of iconData) {
          cancelAnimation(icon.x)
          cancelAnimation(icon.y)
          cancelAnimation(icon.scale)
          cancelAnimation(icon.rotation)
          cancelAnimation(icon.opacity)
        }
      }
    }, [iconData])

    const { width: WINDOW_WIDTH } = useWindowDimensions()
    const showHeartIcon = useCallback(
      (index: number, x: number, y: number) => {
        'worklet'

        const icon = iconData[index]
        if (!icon) return

        icon.x.value = x - TIP_DIMENSIONS / 2
        icon.y.value = y - TIP_DIMENSIONS / 2
        icon.opacity.value = 1
        icon.scale.value = 0

        // Randomize the rotation between -30 and 30 degrees to make it look more natural
        const randomRotation = Math.random() * 60 - 30

        icon.rotation.value = withSequence(
          withTiming(randomRotation, { duration: 300 }),
          withTiming(0, { duration: 300 })
        )
        icon.scale.value = withSpring(1, { damping: 10, stiffness: 100 })

        icon.x.value = withDelay(
          500,
          withSpring(WINDOW_WIDTH / 2 - TIP_DIMENSIONS / 2, {
            damping: 100,
            stiffness: 50,
            overshootClamping: true,
            restDisplacementThreshold: 0.1,
            restSpeedThreshold: 0.1,
          })
        )

        icon.y.value = withDelay(
          500,
          withSpring(-100, {
            damping: 100,
            stiffness: 50,
            overshootClamping: true,
            restDisplacementThreshold: 0.1,
            restSpeedThreshold: 0.1,
          })
        )

        icon.opacity.value = withDelay(
          800,
          withTiming(0, {
            duration: 200,
            easing: Easing.out(Easing.ease),
          })
        )
      },
      [WINDOW_WIDTH, iconData]
    )

    const handleDoubleTap = useCallback(async () => {
      try {
        if (Platform.OS !== 'web') {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium).catch(() => {})
        }
        if (!post.hasLiked) {
          await likePost.mutateAsync({ postId: post.post.id })
          mixpanel.track(events.POST_LIKE, {
            amount: 1,
            postId: post.post.id,
            recipientId: post.post.userId,
            origin: 'double-tap',
          })
        }
      } catch {
        // do nothing here, the error is catched and logged in the mutation onError boundary
      }
    }, [post.post.id, post.post.userId, post.hasLiked, likePost.mutateAsync])

    // Memoize the double tap gesture
    const doubleTapGesture = useMemo(
      () =>
        Gesture.Tap()
          .numberOfTaps(2)
          .maxDuration(600)
          .runOnJS(true)
          .onEnd((event, success) => {
            const { x, y } = event

            if (success && authStatus === 'authenticated') {
              handleDoubleTap()

              for (let i = 0; i < iconData.length; i++) {
                if (iconData[i]?.opacity.value === 0) {
                  showHeartIcon(i, x, y)
                  break
                }
              }
            }
          }),
      [showHeartIcon, authStatus, handleDoubleTap, iconData]
    )

    // Memoize the single tap gesture
    const singleTapGesture = useMemo(
      () =>
        Gesture.Tap()
          .numberOfTaps(1)
          .runOnJS(true)
          .onEnd((_, success) => {
            if (success) {
              togglePauseRef.current?.togglePause()
            }
          }),
      [togglePauseRef]
    )

    // Compose the gestures, memoized with all its deps
    const composed = useMemo(
      () => Gesture.Exclusive(doubleTapGesture, singleTapGesture),
      [doubleTapGesture, singleTapGesture]
    )

    return (
      <GestureDetector gesture={composed} touchAction="manipulation">
        <LeanView collapsable={false}>
          {children}
          {iconData.map((icon, index) => {
            const animatedStyle = useAnimatedStyle(() => ({
              transform: [
                { translateX: icon.x.value },
                { translateY: icon.y.value },
                { scale: icon.scale.value },
                { rotate: `${icon.rotation.value}deg` },
              ],
              opacity: icon.opacity.value,
              position: 'absolute',
              zIndex: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }))

            return (
              <AnimatedLeanView pointerEvents="none" key={index} style={animatedStyle}>
                <HeartIcon
                  height={TIP_DIMENSIONS}
                  width={TIP_DIMENSIONS - 7}
                  color={theme.colors.primary}
                />
              </AnimatedLeanView>
            )
          })}
        </LeanView>
      </GestureDetector>
    )
  }
)

VideoGestureContainer.displayName = 'VideoGestureContainer'
