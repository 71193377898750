import { AnimatedLeanView, HeartIcon, LeanText, Pressable, theme } from '@my/ui'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'
import { formatShortNumber } from 'app/utils/common'
import { StyleSheet } from 'react-native'
import {
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withSpring,
  type WithSpringConfig,
} from 'react-native-reanimated'
import { useLikePost } from './hooks/use-like-post'
import type { VideoFeedItemProps } from './types'

const springConfig: WithSpringConfig = { damping: 100, stiffness: 800 }

export const PostLikeButton = (props: {
  postItem: VideoFeedItemProps['item']
}) => {
  const authStatus = useAuthenticationStatus()
  const isLoggedOut = authStatus !== 'authenticated'
  const post = props.postItem
  const { likePost, unlikePost } = useLikePost({ likeCount: props.postItem.postLikeCount })

  const scale = useSharedValue(1)

  const redirectToSignIn = useRedirectToSignIn()

  useAnimatedReaction(
    () => post.hasLiked,
    (currentValue, previousValue) => {
      if (previousValue !== null && currentValue !== previousValue) {
        scale.value = withSequence(withSpring(1.3, springConfig), withSpring(1, springConfig))
      }
    },
    [post.hasLiked] // dependencies to re-run the reaction
  )

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scale.value }],
    }
  }, [scale])

  return (
    <Pressable
      haptics
      className="items-center justify-center gap-0.5"
      onPress={() => {
        if (isLoggedOut) {
          return redirectToSignIn()
        }
        if (post.hasLiked) {
          unlikePost.mutate({ postId: post.post.id })
        } else {
          likePost.mutate({ postId: post.post.id })
        }
      }}>
      <AnimatedLeanView style={animatedStyle}>
        <HeartIcon color={post.hasLiked ? theme.colors.primary : '#fff'} height={30} width={30} />
      </AnimatedLeanView>
      <LeanText className="text-xs native:text-sm text-white" style={styles.shadow}>
        {formatShortNumber(post.postLikeCount)}
      </LeanText>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  shadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.4)',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 2,
  },
})
