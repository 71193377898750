import type { Chain } from 'viem'

import { base } from 'viem/chains'

let chain: Chain = base

if (process.env.EXPO_PUBLIC_RPC_PROVIDER_URL) {
  chain = {
    ...chain,
    rpcUrls: {
      ...chain.rpcUrls,
      alchemy: {
        ...chain.rpcUrls.alchemy,
        http: [process.env.EXPO_PUBLIC_RPC_PROVIDER_URL],
      },
    },
  }
}

export { chain }
