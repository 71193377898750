import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const FlashIcon = (props: Props) => {
  return (
    <Svg
      width={props.width ?? 13}
      height={props.height ?? 17}
      viewBox="0 0 13 17"
      fill={props.color}>
      <Path
        d="M1.51727 9.55533H3.92408C4.20022 9.55533 4.42408 9.77919 4.42408 10.0553V16.1116C4.42408 17.015 5.38458 17.2027 5.98119 16.5208L12.7114 8.85166C13.2739 8.21244 12.9208 7.37344 12.0685 7.37344H9.72253C9.44639 7.37344 9.22253 7.14958 9.22253 6.87344V0.944757C9.22253 0.0413187 8.26539 -0.137051 7.66878 0.54479L0.822737 8.11234C0.268741 8.75156 0.673493 9.55533 1.51727 9.55533Z"
        fill={props.color ?? 'white'}
      />
    </Svg>
  )
}
