import { BaseIcon, View } from '@my/ui'
import { Image } from 'expo-image'

export function ImageWithBaseLogo({
  uri,
  size,
  baseLogoSize,
}: {
  uri?: string
  size: number
  baseLogoSize: number
}) {
  return (
    <View>
      <View className="bg-neutral-700 rounded-full overflow-hidden">
        <Image
          source={{
            uri,
            width: size * 2,
            height: size * 2,
          }}
          style={{ height: size, width: size }}
          contentFit="cover"
          transition={200}
        />
      </View>
      <View className="absolute bottom-[-4px] right-[-4px]">
        <BaseIcon width={baseLogoSize - 1} height={baseLogoSize} />
      </View>
    </View>
  )
}
