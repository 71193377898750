import { forwardRef, useImperativeHandle } from 'react'

import { Button } from '../button'
import { Text as RNText } from '../text'

import type { SlideButtonProps } from './types'

export type SlideButtonRef = {
  reset: () => void
}

export const SlideButton = forwardRef<
  SlideButtonRef,
  Omit<
    SlideButtonProps,
    | 'internalPaddingHorizontal'
    | 'internalPaddingVertical'
    | 'completedCta'
    | 'canvasWidth'
    | 'canvasHeight'
  >
>(({ onCompleted, disabled, slideCta, iconAfter }, ref) => {
  useImperativeHandle(ref, () => ({
    reset: () => {},
  }))

  return (
    <Button
      disabled={disabled}
      className={`h-[48px] rounded-full bg-secondary transition-opacity duration-200 ${disabled ? 'opacity-50' : 'opacity-100'}`}
      onPress={onCompleted}
      iconAfter={iconAfter}>
      <RNText className="text-black font-semibold">{slideCta}</RNText>
    </Button>
  )
})
