import { useStateSync } from 'app/hooks/batching/use-batched-state'
import { useEventCallback } from 'app/hooks/use-event-callback'

export function useRefreshByUser(refetch: () => Promise<unknown>) {
  const [isRefetchingByUser, setIsRefetchingByUser] = useStateSync(false)

  const refetchByUser = useEventCallback(async () => {
    setIsRefetchingByUser(true)

    try {
      await refetch()
    } finally {
      setIsRefetchingByUser(false)
    }
  })

  return {
    isRefetchingByUser,
    refetchByUser,
  }
}
