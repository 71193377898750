import type { ReactQueryOptions } from '@my/api'
import { skipToken } from '@tanstack/react-query'
import { trpc } from 'app/utils/api'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'

type UserMeOptions = ReactQueryOptions['user']['me']

let hasInitialised = false

export const useMe = (options?: UserMeOptions) => {
  const authStatus = useAuthenticationStatus()

  const queryState = trpc.user.me.useQuery(authStatus === 'authenticated' ? undefined : skipToken, {
    refetchOnMount: true,
    staleTime: hasInitialised
      ? (query) => {
          const isNotLoggedIn = query.state.error?.data?.code === 'UNAUTHORIZED'
          const isNotOnboarded = query.state.error?.data?.code === 'FORBIDDEN'

          if (isNotLoggedIn || isNotOnboarded) {
            return 0
          }

          if (query.state.data) {
            return 60_000
          }

          return 0
        }
      : undefined,
    ...options,
  })

  if (queryState.data) {
    hasInitialised = true
  }

  return queryState
}
