import type { RouterOutputs } from '@my/api'
import { Pressable, Text } from '@my/ui'
import { Image } from 'expo-image'
import { useRouter } from 'expo-router'

export const TokenVerifiedBadge = ({
  token,
  size = 16,
  disabled,
}: {
  token?: Pick<RouterOutputs['tokenContract']['byAddress'], 'verified' | 'isScam'> | null
  size?: number
  disabled?: boolean
}) => {
  const router = useRouter()
  if (!token) return null

  if (token?.verified && !token?.isScam) {
    return (
      <Pressable
        disabled={disabled}
        onPress={() => {
          router.push(`/explanation?topic=token-verified`)
        }}>
        <Image
          source={require('@my/ui/src/icons/token-verified.svg')}
          style={{ width: size, height: size }}
        />
      </Pressable>
    )
  }

  if (token.isScam) {
    return (
      <Pressable
        disabled={disabled}
        onPress={() => {
          router.push(`/explanation?topic=token-scam`)
        }}
        className="bg-red-500 rounded-md px-2 py-1">
        <Text className="font-semibold text-xs">SCAM</Text>
      </Pressable>
    )
  }

  return (
    <Pressable
      disabled={disabled}
      onPress={() => {
        router.push(`/explanation?topic=token-unverified`)
      }}>
      <Image
        source={require('@my/ui/src/icons/token-unverified.svg')}
        style={{ width: size, height: size }}
      />
    </Pressable>
  )
}
