import { skipToken, useQuery } from '@tanstack/react-query'
import { useEmbeddedSmartWallet } from 'app/provider/embedded-smart-wallet-provider/context'
import { getFormattedEther } from 'app/utils/common'
import { trackBalanceChange } from 'app/utils/tracking/events'
import { publicClient } from 'app/utils/viem-public-client'

export const useUserEthBalance = () => {
  const embeddedSmartWallet = useEmbeddedSmartWallet()
  const address = embeddedSmartWallet.address
  const query = useQuery({
    queryKey: [embeddedSmartWallet.address, 'eth-balance'],
    enabled: !!embeddedSmartWallet.address,
    refetchInterval: 10_000,
    persister: undefined,
    queryFn: address
      ? async () => {
          const res = (await publicClient.getBalance({
            address,
          })) as bigint
          if (res) {
            trackBalanceChange(res.toString(), 'ETH', address)
            return {
              balance: res,
              displayBalance: getFormattedEther(res),
            }
          }
          return {
            balance: 0n,
            displayBalance: '0',
          }
        }
      : skipToken,
  })
  return query
}
