import type { RouterOutputs } from '@my/api'
import { Button, LeanText, LeanView, Text } from '@my/ui'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { formatNumberForPrice, formatShortNumber } from 'app/utils/common'
import { useRouter } from 'expo-router'
import { useCallback, useMemo } from 'react'
import { formatUnits } from 'viem'
import { useEventCallback } from '../../hooks/use-event-callback'
import { usdcToken } from '../buy-token/constants'
import { useWowTokenUsdPrice } from '../buy-token/hooks/use-wow-token-usd-price'
import { useWalletAction } from '../coinbase-smart-wallet/use-wallet-action'
import { PercentageChange } from '../common/percentage-change'
import { useNavigateToBuyToken } from '../token/hooks/use-navigate-to-buy-token'
import { TokenVerifiedBadge } from '../token/token-verified-badge'
import { useIsItemInVisibilityWindow } from './use-is-item-in-visibility-window'

type VideoFeedTokenDetailsProps = {
  username: string
  postId: string
  token: NonNullable<
    RouterOutputs['tradePage']['topVideocoins']['data'][number]['post']['tokenDetails']
  >
}

export const VideoFeedTokenDetails = ({ username, postId, token }: VideoFeedTokenDetailsProps) => {
  const router = useRouter()
  const navigateToBuyToken = useNavigateToBuyToken()
  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()
  const itemInVisibilityWindow = useIsItemInVisibilityWindow()

  const tokenPriceInUsd = useWowTokenUsdPrice(
    // Only request a quote if the token is a wow token. Hardcode decimals to 18 for launchpad tokens
    itemInVisibilityWindow && token.priceUSD === null
      ? {
          amount: 10n ** 18n,
          address: token.address as `0x${string}`,
        }
      : undefined
  )

  const handleBuyPress = useCallback(() => {
    if (token.address) {
      navigateToBuyToken({
        tokenAddress: token.address as `0x${string}`,
        amount: '5',
        username: username,
        location: 'post',
        referral: postId,
        isWowToken: token.graduatedAt !== undefined,
      })
    }
  }, [username, navigateToBuyToken, postId, token])

  const formattedPrice = useMemo(() => {
    if (!token.priceUSD && !tokenPriceInUsd.data) return null
    if (token.priceUSD) return formatNumberForPrice(Number(token.priceUSD))
    const formattedUsdQuote = formatUnits(
      BigInt(tokenPriceInUsd?.data?.quote.toAmount ?? 0),
      usdcToken.decimals
    )
    return formatNumberForPrice(Number(formattedUsdQuote))
  }, [token.priceUSD, tokenPriceInUsd])

  const formmattedChange24 = useMemo(() => {
    if (token.change24 && !Number.isNaN(Number(token.change24))) return Number(token.change24)
  }, [token.change24])

  const formattedMarketCap = useMemo(
    () =>
      token?.marketCap && !Number.isNaN(Number(token.marketCap))
        ? formatShortNumber(Number(token.marketCap))
        : 0,
    [token?.marketCap]
  )

  const openTradeModal = useEventCallback(() => {
    router.setParams({ expand: 'trade' })
  })

  const handleBuyAction = useWalletAction(handleBuyPress)

  if (isWeb3ActionsDisabled) return null

  return (
    <LeanView className="bg-black/50 p-3 rounded-xl" style={{ borderCurve: 'continuous' }}>
      {/* if we don't have the price stored in backend and need to fetch it on the frontend (should not happen often) */}
      {token.priceUSD === null && tokenPriceInUsd?.isLoading ? (
        <LeanView className="flex-row items-center gap-3" key={1}>
          <LeanView className="flex-row gap-[2px] items-center">
            <LeanView className="flex-row">
              <LeanView className="w-[22px] h-[22px] bg-borderShade1 animate-pulse rounded-full" />
              <LeanView className="left-[-4px]">
                <LeanView className="w-[22px] h-[22px] bg-borderShade1 animate-pulse rounded-full" />
              </LeanView>
            </LeanView>
            <LeanView className="w-[40px] h-[14px] bg-borderShade1 animate-pulse rounded-md" />
          </LeanView>
          <LeanView className="bg-secondary rounded-full w-[48px] h-[27px] items-center justify-center animate-pulse" />
        </LeanView>
      ) : (
        <LeanView className="gap-2 w-full flex-1">
          <LeanView className="flex-row items-center gap-3">
            <LeanView className="flex-row gap-1.5 items-center">
              <LeanText className="text-white font-semibold">{token.symbol}</LeanText>
              <TokenVerifiedBadge
                token={{
                  isScam: token.isScam,
                  verified: token.verified,
                }}
                size={18}
              />
            </LeanView>
            {formattedPrice ? (
              <LeanText className="text-white font-semibold">${formattedPrice}</LeanText>
            ) : null}
            {formmattedChange24 ? <PercentageChange value={formmattedChange24} /> : null}
          </LeanView>
          <LeanView className="flex-row items-center gap-3">
            <LeanView className="gap-1">
              <LeanView className="flex-row items-center gap-1">
                <LeanText className="text-white font-semibold">${formattedMarketCap}</LeanText>
                <LeanText className="text-grayTextColor3 text-sm">MKT CAP</LeanText>
              </LeanView>
              <Text onPress={openTradeModal} className="text-grayTextColor3 underline">
                View Details
              </Text>
            </LeanView>
            <Button
              onPress={handleBuyAction}
              variant="secondary"
              className="rounded-full flex-1"
              classNameText="text-black">
              Buy
            </Button>
          </LeanView>
        </LeanView>
      )}
    </LeanView>
  )
}
