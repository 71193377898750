import { LeanView } from '@my/ui'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { DEXScreenerIconLink } from './dexscreener-icon-link'
import { PostCommentButton } from './post-comment-button'
import { PostLikeButton } from './post-like-button'
import { PostMoreButton } from './post-more-button'
import { PostShareButton } from './post-share-button'
import type { VideoFeedItemData } from './types'
import { ZoraIconLink } from './zora-icon-link'

export const EngagementButtons = ({ item }: { item: VideoFeedItemData }) => {
  const launchpadToken = item.launchpadToken
  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()
  return (
    <LeanView className="gap-2">
      {launchpadToken && !isWeb3ActionsDisabled ? (
        <LeanView className="h-16 items-center justify-center">
          <ZoraIconLink
            count={launchpadToken.mintCount}
            url={`https://zora.co/collect/base:${launchpadToken.collectionAddress}/${launchpadToken.tokenId}`}
          />
        </LeanView>
      ) : null}
      {item.post.tokenDetails?.address && !isWeb3ActionsDisabled ? (
        <LeanView className="h-16 items-center justify-center">
          <DEXScreenerIconLink
            url={`https://dexscreener.com/base/${item.post.tokenDetails.address}`}
          />
        </LeanView>
      ) : null}
      <LeanView className="h-16 items-center justify-center">
        <PostLikeButton postItem={item} />
      </LeanView>
      <LeanView className="h-16 items-center justify-center">
        <PostCommentButton postItem={item} />
      </LeanView>
      <LeanView className="h-12 items-center justify-center">
        <PostShareButton postItem={item} />
      </LeanView>
      <LeanView className="h-10 items-center justify-center">
        <PostMoreButton postId={item.post.id} />
      </LeanView>
    </LeanView>
  )
}
