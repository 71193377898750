import type { RouterOutputs } from '@my/api'
import { BottomSheetGorhom, Button, cn, LeanView, Text, type BottomSheetGorhomRef } from '@my/ui'
import { useEventCallback } from 'app/hooks/use-event-callback'
import { useIsWeb3ActionsDisabled } from 'app/hooks/use-is-web3-actions-disabled'
import { trpc } from 'app/utils/api'
import { browserParams } from 'app/utils/common'
import * as WebBrowser from 'expo-web-browser'
import { useMemo, useRef, useState } from 'react'
import { useMe } from '../profile/hooks/use-me'

export type TopLaunchpadToken = NonNullable<
  RouterOutputs['tradePage']['trending']['topLaunchpadTokens']['data'][0]
>

export const FooterDisclaimer = ({ className, text }: { className?: string; text?: string }) => {
  const openTerms = useEventCallback(async () => {
    await WebBrowser.openBrowserAsync(
      'https://showtime-xyz.notion.site/Terms-of-Service-bf0c49cbdf3e4db9aa291b8d6dc2c919?pvs=4',
      browserParams
    )
  })

  const openPrivacy = useEventCallback(async () => {
    await WebBrowser.openBrowserAsync(
      'https://showtime-xyz.notion.site/Privacy-Policy-71312edbe17341f1bd46051928587e93?pvs=74',
      browserParams
    )
  })

  const classNameStyle = useMemo(() => cn('items-center justify-center', className), [className])

  return (
    <LeanView className={classNameStyle}>
      <Text className="text-grayTextColor text-xs">
        {text}{' '}
        <Text className="underline text-xs" onPress={openTerms}>
          Terms
        </Text>{' '}
        &{' '}
        <Text className="underline text-xs" onPress={openPrivacy}>
          Privacy Policy
        </Text>
      </Text>
    </LeanView>
  )
}

export const TradeRiskDisclaimer = () => {
  const modalRef = useRef<BottomSheetGorhomRef>(null)
  const isWeb3ActionsDisabled = useIsWeb3ActionsDisabled()
  const [isDismissed, setIsDismissed] = useState(false)
  const utils = trpc.useUtils()
  const { mutate } = trpc.user.updateState.useMutation({
    onSettled: async () => {
      await modalRef.current?.dismiss()
      setIsDismissed(true)

      // we invalidate so the me query is refetched and cache is updated
      // otherwise it would flash the disclaimer when you relaunch the app
      utils.user.me.invalidate()
    },
  })

  const me = useMe()

  const onClose = useEventCallback(() => {})

  const updateState = useEventCallback(() => {
    mutate({ key: 'trade-risk-disclaimer', state: true })
  })

  const openTerms = useEventCallback(async () => {
    await WebBrowser.openBrowserAsync(
      'https://showtime-xyz.notion.site/Terms-of-Service-bf0c49cbdf3e4db9aa291b8d6dc2c919',
      browserParams
    )
  })

  if (isWeb3ActionsDisabled) return null

  if (me.isPending || me.error || me.data.states['trade-risk-disclaimer'] || isDismissed)
    return null

  return (
    <LeanView className="flex-grow absolute top-0 left-0 right-0 bottom-0 bg-transparent">
      <BottomSheetGorhom ref={modalRef} onClose={onClose} disableDrag hideHeader disableClose>
        <LeanView className="p-5 gap-5 md:p-8">
          <Text className="text-white text-lg">
            By using Drakula, you acknowledge the risks and volatility of cryptocurrency
            transactions and accept full responsibility for any losses. You confirm that you have
            read and agreed to{' '}
            <Text className="underline" onPress={openTerms}>
              our terms.
            </Text>
          </Text>
          <LeanView className="flex-1 justify-center items-left mt-12 web:mt-4">
            <Button
              haptics
              className="h-14 px-4 rounded-xl min-w-1/2"
              variant="white"
              onPress={updateState}>
              <Text className="font-bold text-black">Agree and continue</Text>
            </Button>
          </LeanView>
        </LeanView>
      </BottomSheetGorhom>
    </LeanView>
  )
}
