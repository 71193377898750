import Ionicons from '@expo/vector-icons/Ionicons'
import { useMuted } from 'app/provider/mute-provider'
import {
  ItemKeyContext,
  type ViewabilityItemsContextType,
} from 'app/utils/with-viewability-infinite-scroll-list'
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useEventCallback } from 'app/hooks/use-event-callback'
import { useTrackWatchEvent } from 'app/provider/track-watch-event-provider/hooks/use-track-watch-event'
import { useVideoVisibilityState } from '../hooks/use-video-visibility-state'
import type { VideoProps } from './type'

const calculateAspectRatio = (width: number, height: number) => width / height
const isCloseToAspectRatio = (aspectRatio: number, target: number, threshold = 0.1) =>
  Math.abs(aspectRatio - target) < threshold

const getVideoInstance = () => {
  const video = document.createElement('video')
  video.style.backgroundColor = 'black'
  video.style.overflow = 'hidden'
  video.playsInline = true
  video.controls = false
  video.loop = true
  video.preload = 'none'
  video.muted = true
  video.crossOrigin = 'anonymous'
  video.setAttribute('controlsList', 'nodownload')

  return video
}

const videoInstances = [getVideoInstance(), getVideoInstance(), getVideoInstance()]

const isVideoPlaying = (video: HTMLVideoElement) =>
  !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState >= 2)

export const FeedVideo = ({
  postId,
  uri,
  availableScreenWidth,
  availableScreenHeight,
  muteRef,
  togglePauseRef,
  videoWidth,
  videoHeight,
  videoVisibilityRef,
}: VideoProps) => {
  const { muted, setMuted } = useMuted()
  const id = useContext(ItemKeyContext)
  const trackWatchEvent = useTrackWatchEvent()
  const wasPausedDueToVisibilityChange = useRef(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const videoViewed = useRef(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isManuallyPaused, setIsManuallyPaused] = useState(false)
  const touchStartPosition = useRef({ x: 0, y: 0 })
  const isSwiping = useRef(false)

  const videoSetupConfig = useMemo(
    () => ({
      width: availableScreenWidth,
      contentFit: calculateContentFit({
        videoWidth,
        videoHeight,
        screenWidth: availableScreenWidth,
        screenHeight: availableScreenHeight,
      }),
    }),
    [availableScreenWidth, availableScreenHeight, videoWidth, videoHeight]
  )

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    if (e.touches[0]) {
      touchStartPosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    }
    isSwiping.current = false
  }, [])

  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    if (isSwiping.current) return
    if (e.touches[0]) {
      const deltaX = Math.abs(e.touches[0].clientX - touchStartPosition.current.x)
      const deltaY = Math.abs(e.touches[0].clientY - touchStartPosition.current.y)
      // If the user has moved their finger more than a certain threshold, consider it a swipe
      if (deltaX > 10 || deltaY > 10) {
        isSwiping.current = true
      }
    }
  }, [])

  const handlePause = useEventCallback(() => {
    videoRef.current?.pause()
  })

  const setMute = useCallback(
    (mute: boolean) => {
      for (const video of videoInstances) {
        video.muted = mute
      }
      setMuted(mute)
    },
    [setMuted]
  )

  useImperativeHandle(
    muteRef,
    () => {
      return {
        unmute: () => {
          setMute(false)
        },
        mute: () => {
          setMute(true)
        },
      }
    },
    [setMute]
  )

  useImperativeHandle(
    togglePauseRef,
    () => {
      return {
        togglePause: () => {
          if (isSwiping.current) return // Don't toggle if we're swiping
          if (isManuallyPaused) {
            setIsManuallyPaused(false)
            videoRef.current?.play().catch(() => {})
          } else {
            // if the video is muted, unmute it in addition to pausing
            if (muted) {
              setMute(false)
            }
            setIsManuallyPaused(true)
            handlePause()
          }
        },
      }
    },
    [handlePause, isManuallyPaused, muted, setMute]
  )

  useEffect(() => {
    const handleDocumentVisibility = () => {
      if (typeof document !== 'undefined') {
        if (document.hidden && videoRef.current && isVideoPlaying(videoRef.current)) {
          handlePause()
          wasPausedDueToVisibilityChange.current = true
        } else if (!document.hidden && wasPausedDueToVisibilityChange.current) {
          videoRef.current?.play().catch(() => {})
          wasPausedDueToVisibilityChange.current = false
        }
      }
    }

    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', handleDocumentVisibility)
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', handleDocumentVisibility)
      }
    }
  }, [handlePause])

  const playVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        console.log('play got interrupted, do not log error ', videoRef.current?.src)
      })
    }
  }, [])

  const setupVideo = useCallback(
    (video: HTMLVideoElement) => {
      video.className = 'h-[100svh] lg:-mt-[60px] !w-full'
      video.src = uri
      video.style.width = `${videoSetupConfig.width}px`
      video.style.objectFit = videoSetupConfig.contentFit
      video.ondurationchange = onDurationChange
      video.ontimeupdate = onTimeUpdate
      video.preload = 'auto'
      video.onplay = () => {
        trackWatchEvent?.startTracking(postId)
      }
      video.onpause = () => {
        trackWatchEvent?.endTracking(postId)
      }
    },
    [uri, postId, videoSetupConfig, trackWatchEvent?.startTracking, trackWatchEvent?.endTracking]
  )

  const handleItemInWindow = useCallback(
    (_: ViewabilityItemsContextType) => {
      if (!videoRef.current) {
        const video = videoInstances[id % 3]
        if (video) {
          videoRef.current = video
        }
      }

      if (videoRef.current) {
        if (videoRef.current.src !== uri) {
          setupVideo(videoRef.current)
        }

        parentRef.current?.appendChild(videoRef.current)
        if (!isManuallyPaused) {
          playVideo()
        }
      }
    },
    [id, uri, isManuallyPaused, setupVideo, playVideo]
  )

  const handleItemOutOfWindow = useCallback(() => {
    if (videoRef.current) {
      if (parentRef.current?.firstChild) {
        parentRef.current?.removeChild(videoRef.current)
      }
      videoRef.current.currentTime = 0
      handlePause()
      setIsManuallyPaused(false)
      videoRef.current = null
    }
  }, [handlePause])

  const { isItemTrulyVisible } = useVideoVisibilityState({
    uri,
    onItemInWindow: handleItemInWindow,
    onItemOutOfWindow: handleItemOutOfWindow,
  })

  useImperativeHandle(
    videoVisibilityRef,
    () => {
      return {
        isItemTrulyVisible,
      }
    },
    [isItemTrulyVisible]
  )

  const triggerViewCount = useEventCallback(() => {
    videoViewed.current = true
  })

  const onTimeUpdate = useEventCallback(() => {
    if (videoRef.current && videoRef.current.currentTime >= 1 && !videoViewed.current) {
      triggerViewCount()
    }
  })

  const onDurationChange = useEventCallback(() => {
    if (typeof videoRef.current?.duration === 'number' && videoRef.current.duration < 1) {
      triggerViewCount()
    }
  })

  return (
    <>
      <div
        ref={parentRef}
        className="min-w-[100vw] max-h-[100svh] md:min-w-0"
        style={{
          height: availableScreenHeight,
          width: availableScreenWidth,
          overflow: 'hidden',
          backgroundColor: 'black',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      />
      {isManuallyPaused ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Ionicons
            name="play"
            size={80}
            color={'white'}
            className="opacity-50 drop-shadow-[0px_0px_8px_rgba(0,0,0,0.6)]"
          />
        </div>
      ) : null}
    </>
  )
}

const calculateContentFit = ({
  videoWidth,
  videoHeight,
  screenWidth,
  screenHeight,
}: {
  videoWidth: number
  videoHeight: number
  screenWidth: number
  screenHeight: number
}) => {
  const videoAspectRatio = calculateAspectRatio(videoWidth, videoHeight)
  const deviceAspectRatio = calculateAspectRatio(screenWidth, screenHeight)

  let resizeMode = videoAspectRatio > deviceAspectRatio ? 'cover' : 'contain'

  if (
    isCloseToAspectRatio(videoAspectRatio, 16 / 9) ||
    isCloseToAspectRatio(videoAspectRatio, 4 / 3) ||
    isCloseToAspectRatio(videoAspectRatio, 1)
  ) {
    resizeMode = 'contain'
  }

  if (videoAspectRatio > 1.7777777777777777 && videoAspectRatio < 1.8 && videoWidth > videoHeight) {
    resizeMode = 'cover'
  }

  if (videoAspectRatio < 1 && videoHeight > videoWidth) {
    resizeMode = 'cover'
  }

  return resizeMode
}
