export const theme = {
  colors: {
    primary: '#E70101',
    secondary: '#00F436',
    appDarkBg: '#111111',
    headerTintColor: '#CBD5E1',
    borderShade1: '#2C2C2C',
    surfaceDarkShade1: '#181818',
    grayTextColor: '#8F8F8F',
    grayTextColor2: '#C3C3C3',
    grayTextColor3: '#D3D3D3',
    grayTextColor4: '#676767',
    modalDarkBg: '#111111',
    borderShade2: '#242424',
    surfaceDarkShade2: '#1B1B1B',
    modalBgOverlay: 'rgba(23, 23, 23, 0.9)',
    blueButtonBg: '#315CFF',
  },
}
