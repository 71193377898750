import { Avatar, Pressable, Text, View } from '@my/ui'
import { Link } from 'expo-router'
import { memo, useMemo } from 'react'

type Props = {
  index?: number
  user: {
    id: string
    username: string
    name: string | null
    profileImage: string | null
  }
  amount: string | number | null
}

type RealizedProfit = {
  amount: string
  sign: string
}

const RealizedProfitText = memo((props: RealizedProfit) => {
  if (props.sign === '+') {
    return <Text className="text-secondary">+{props.amount}</Text>
  }

  if (props.sign === '-') {
    return <Text className="text-primary">-{props.amount}</Text>
  }

  return <Text className="text-white">{props.amount}</Text>
})

export const Trader = memo((props: Props) => {
  const { user, index } = props

  const realizedProfit = useMemo(() => {
    if (!props.amount) {
      return {
        amount: '$0.00',
        sign: '',
      }
    }

    let amount = props.amount

    if (typeof amount === 'string') {
      amount = Number.parseFloat(amount)
    }

    amount = amount / 1_000_000

    const sign = amount > 0 ? '+' : amount < 0.01 && amount > -0.01 ? '' : '-'

    if (Math.abs(amount) < 0.01) {
      return {
        amount: '$0.00',
        sign,
      }
    }

    return {
      amount: amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      sign,
    }
  }, [props.amount])

  const displayName = useMemo(() => {
    if (user.name && user.name.trim().length > 0) {
      return user.name
    }

    return user.username
  }, [user.name, user.username])

  return (
    <Link href={`/user/${user.username}`} asChild>
      <Pressable className="px-2 py-1.5 flex-row items-center justify-between hover:bg-grayTextColor/20 rounded-lg active:bg-grayTextColor/20">
        <View className="flex-row items-center gap-2">
          {index === undefined ? null : (
            <Text className="text-grayTextColor pr-1">{index + 1}</Text>
          )}
          <Avatar source={user.profileImage} size={35} />
          <Text className="text-white font-semibold text-ellipsis max-w-[160px]" numberOfLines={1}>
            {displayName}
          </Text>
        </View>
        <RealizedProfitText amount={realizedProfit.amount} sign={realizedProfit.sign} />
      </Pressable>
    </Link>
  )
})

Trader.displayName = 'Trader'
