import { Button, Text, View } from '@my/ui'
import { useRouter } from 'expo-router'

export const RestrictedUserScene = () => {
  const router = useRouter()

  return (
    <View>
      <Text className="text-white text-lg">
        This profile is not available. The user may have restricted access to their profile.
      </Text>
      <View className="mt-4">
        <Button
          variant="outlineFilled"
          onPress={() => {
            if (router.canGoBack()) {
              router.back()
            } else {
              router.replace('/')
            }
          }}>
          Go back
        </Button>
      </View>
    </View>
  )
}
