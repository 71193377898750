import React from 'react'
import { Platform } from 'react-native'

import type { FlashList } from '@shopify/flash-list'

import {
  InfiniteScrollList,
  type InfiniteScrollListProps,
} from '../infinite-scroll-list/infinite-scroll-list'
import { useHeaderTabContext } from './context'

import { TabFlashListScrollView } from './tab-flash-list-scroll-view'

export type TabInfiniteScrollListProps<T> = Omit<
  InfiniteScrollListProps<T>,
  'renderScrollComponent'
> & {
  index: number
}

function TabInfiniteScrollListComponent<T>(
  props: TabInfiniteScrollListProps<T>,
  ref: React.Ref<FlashList<T>>
) {
  const { scrollViewPaddingTop } = useHeaderTabContext()
  return (
    //@ts-expect-error - type mismatch
    <InfiniteScrollList
      {...props}
      {...Platform.select({
        web: {},
        default: { renderScrollComponent: TabFlashListScrollView },
      })}
      contentContainerStyle={{ paddingTop: scrollViewPaddingTop }}
      ref={ref}
    />
  )
}

export const TabInfiniteScrollList = React.forwardRef(TabInfiniteScrollListComponent) as <T>(
  props: TabInfiniteScrollListProps<T> & {
    ref?: React.Ref<FlashList<T>>
  }
) => React.ReactElement
