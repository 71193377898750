import {
  CellContainer,
  FlashList as NativeFlashList,
  type FlashListProps,
} from '@shopify/flash-list'
import { forwardRef, memo, useMemo, type LegacyRef } from 'react'
import { type ScrollViewProps } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Animated from 'react-native-reanimated'
import type { AnimatedScrollView as AnimatedScrollViewType } from 'react-native-reanimated/lib/typescript/component/ScrollView'

export { CellContainer, type FlashListProps }

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView)

const FlashListImpl = <T,>(props: FlashListProps<T>, ref: LegacyRef<NativeFlashList<T>>) => {
  const renderScrollComponent = useMemo(
    () =>
      forwardRef<AnimatedScrollViewType, ScrollViewProps>(({ ...scrollProps }, ref) => {
        return (
          <AnimatedScrollView
            {...scrollProps}
            onScroll={props.onScroll}
            onMomentumScrollEnd={() => {}} // Fix momentum scroll end not firing for Android
            ref={ref}
          />
        )
      }),
    [props.onScroll]
  )

  return <NativeFlashList renderScrollComponent={renderScrollComponent} {...props} ref={ref} />
}

const FlashListComponentWithRef = forwardRef(FlashListImpl)
const MemoizedFlashListComponentWithRef = memo(FlashListComponentWithRef)

export const FlashList = MemoizedFlashListComponentWithRef as <T>(
  props: FlashListProps<T> & {
    ref?: React.Ref<NativeFlashList<T>>
  }
) => React.ReactElement
