import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  focused?: boolean
}

export function WatchTabIcon(props: Props) {
  if (props.focused) {
    return (
      <Svg width={props.width ?? 26} height={props.height ?? 26} viewBox="0 0 26 26" fill="none">
        <Path
          d="M1.1875 9.18555C1.1875 6.97641 2.97836 5.18555 5.1875 5.18555H20.7687C22.9779 5.18555 24.7687 6.97641 24.7687 9.18555V20.1855C24.7687 22.3947 22.9779 24.1855 20.7687 24.1855H18.2254H17.1839H9.18569H8.19104H5.1875C2.97836 24.1855 1.1875 22.3947 1.1875 20.1855V9.18555Z"
          stroke="white"
          strokeWidth="1.89"
        />
        <Path
          d="M4.14355 13.77832C4.14355 9.55832 7.56355 7.77832 10.14355 7.77832H15.81155C18.39155 7.77832 21.81155 9.55832 21.81155 13.77832C21.81155 18.00032 18.39155 21.14062 15.81155 21.14062H10.14355C7.56355 21.14062 4.14355 18.00032 4.14355 13.77832Z"
          fill="white"
        />
        <Path d="M14.0459 5.40591L18.1309 1.32031" stroke="white" strokeWidth="2" />
        <Path d="M11.9092 5.40591L7.82422 1.32031" stroke="white" strokeWidth="2" />
      </Svg>
    )
  }

  return (
    <Svg width={props.width ?? 26} height={props.height ?? 26} viewBox="0 0 26 26" fill="none">
      <Path
        d="M1.1875 9.18555C1.1875 6.97641 2.97836 5.18555 5.1875 5.18555H20.7687C22.9779 5.18555 24.7687 6.97641 24.7687 9.18555V20.1855C24.7687 22.3947 22.9779 24.1855 20.7687 24.1855H18.2254H17.1839H9.18569H8.19104H5.1875C2.97836 24.1855 1.1875 22.3947 1.1875 20.1855V9.18555Z"
        stroke="white"
        strokeWidth="1.89"
      />
      <Path
        d="M5.08855 13.77832C5.08855 10.464 7.77427 7.77832 11.0886 7.77832H14.8666C18.1809 7.77832 20.8666 10.464 20.8666 13.77832C20.8666 17.0926 18.1809 19.77832 14.8666 19.77832H11.0886C7.77427 19.77832 5.08855 17.0926 5.08855 13.77832Z"
        stroke="white"
        strokeWidth="1.89"
      />
      <Path d="M14.0459 5.40591L18.1309 1.32031" stroke="white" strokeWidth="2" />
      <Path d="M11.9092 5.40591L7.82422 1.32031" stroke="white" strokeWidth="2" />
    </Svg>
  )
}
