import { useEventCallback } from 'app/hooks/use-event-callback'
import { useRedirectToSignIn } from 'app/hooks/use-redirect-to-sign-in'
import { useEmbeddedSmartWallet } from 'app/provider/embedded-smart-wallet-provider/context'
import { useAuthenticationStatus } from 'app/utils/auth/use-authentication-status'

export function useWalletAction<T extends (...args: Parameters<T>) => void>(onPress: T) {
  const embeddedSmartWallet = useEmbeddedSmartWallet()
  const authStatus = useAuthenticationStatus()
  const redirectToSignIn = useRedirectToSignIn()
  const handlePress = useEventCallback(async (...params: Parameters<T>) => {
    if (authStatus !== 'authenticated') {
      return redirectToSignIn()
    }

    if (embeddedSmartWallet.address) {
      onPress(...params)
    }
  })

  return handlePress
}
