import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export function HeartIcon(props: Props) {
  return (
    <Svg height={props.height} width={props.width} viewBox="0 0 31 27" fill="none">
      <Path
        d="M2.307 13.712A8.068 8.068 0 1114.762 3.548c.229.334.761.334.99-.001a8.068 8.068 0 1112.455 10.164v.001l-12.274 12.35a1 1 0 01-1.42-.002L2.307 13.713v-.001z"
        fill={props.color}
      />
    </Svg>
  )
}
