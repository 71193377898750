import type { ReactQueryOptions, RouterOutputs } from '@my/api'
import { skipToken } from '@tanstack/react-query'
import { trpc } from 'app/utils/api'
import { useCallback } from 'react'
import { useMe } from './use-me'

type Options = ReactQueryOptions['user']['byUsername']

export type UserByUsername = RouterOutputs['user']['byUsername']

export const useUserByUsername = ({ username }: { username?: string }, options?: Options) => {
  const me = useMe()
  const isCurrentUser = me.data?.username === username

  const queryState = trpc.user.byUsername.useQuery(username ? { username } : skipToken, {
    refetchOnMount: true,
    staleTime: 60000,
    retry: (failureCount, error) => {
      if (error.data?.httpStatus === 404 || failureCount >= 3) {
        return false
      }

      return true
    },
    trpc: {
      context: {
        skipBatch: true,
      },
    },
    select: useCallback(
      (data: RouterOutputs['user']['byUsername']) => {
        if (isCurrentUser) {
          return {
            ...data,
            creatorToken: me.data?.creatorToken ?? null,
            hasPowerBadge: me.data?.hasPowerBadge ?? false,
            userDetails: {
              ...data.userDetails,
              // TODO: Ensure name/type consistency between the two APIs
              farcaster: me.data?.fcUsername ?? null,
              fid: me.data?.fid ?? null,
              creatorTokenImageIpfsUri: data.userDetails.creatorTokenImageIpfsUri,
              isBlockedByMe: false,
              hasBlockedMe: false,
            },
            userPinnedToken: me.data?.userPinnedToken ?? null,
          }
        }
        return data
      },
      [me.data, isCurrentUser]
    ),
    enabled: !!username,
    ...options,
  })

  return queryState
}
