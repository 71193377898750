import { Image } from 'expo-image'
import { type Href, Link } from 'expo-router'

type DEXScreenerIconLinkProps = {
  url: Href
}

export const DEXScreenerIconLink = (props: DEXScreenerIconLinkProps) => {
  return (
    <Link className="flex" target="_blank" rel="noreferrer" href={props.url}>
      <Image
        source={require('@my/ui/src/icons/dexscreener-logo.png')}
        style={{ width: 36, height: 36 }}
        contentFit="contain"
      />
    </Link>
  )
}
