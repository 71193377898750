import type { RouterOutputs } from '@my/api'
import { Avatar, Pressable, Text, View } from '@my/ui'
import { formatShortNumber } from 'app/utils/common'
import { Link } from 'expo-router'
import { memo, useMemo } from 'react'

type TokenHolder = RouterOutputs['tokenPage']['token']['topTokenHolders'][number]
type TokenDetails = RouterOutputs['tokenPage']['token']['token']

type Props = {
  index?: number
  user: TokenHolder
  token: Pick<TokenDetails, 'decimals'>
}

export const TokenHolder = memo((props: Props) => {
  const { user, index, token } = props

  const formattedAmount = useMemo(() => {
    if (!user.amountOwned) {
      return '0'
    }

    const amount = BigInt(user.amountOwned) / BigInt(10) ** BigInt(token.decimals)

    return formatShortNumber(Number(amount))
  }, [user.amountOwned, token.decimals])

  const displayName = useMemo(() => {
    if (user.name && user.name.trim().length > 0) {
      return user.name
    }

    return user.username
  }, [user.name, user.username])

  return (
    <Link href={`/user/${user.username}`} asChild>
      <Pressable className="px-2 py-1.5 flex-row items-center justify-between hover:bg-grayTextColor/20 rounded-lg active:bg-grayTextColor/20">
        <View className="flex-row items-center gap-2">
          {index === undefined ? null : (
            <Text className="text-grayTextColor pr-1">{index + 1}</Text>
          )}
          <Avatar source={user.profileImage} size={35} />
          <Text className="text-white font-semibold text-ellipsis max-w-[160px]" numberOfLines={1}>
            {displayName}
          </Text>
        </View>
        <Text className="text-white">{formattedAmount}</Text>
      </Pressable>
    </Link>
  )
})

TokenHolder.displayName = 'TokenHolder'
