import { usePrivy } from '@privy-io/react-auth'
import type { AuthStatus } from './use-authentication-status'

export const useAuthenticationStatus = () => {
  const { ready, authenticated } = usePrivy()
  let status: AuthStatus = 'loading'

  if (ready) {
    if (authenticated) {
      status = 'authenticated'
    } else {
      status = 'unauthenticated'
    }
  }

  return status
}
