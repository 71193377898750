import { Path, Svg } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const SpeechBubbleIcon = (props: Props) => (
  <Svg width={props.width ?? 60} height={props.height ?? 60} viewBox="0 0 27 29" fill={props.color}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6944 28.2032C20.9438 26.3686 26.9188 20.1763 26.9188 14.0448C26.9188 6.71228 20.9746 0.768066 13.642 0.768066C6.30945 0.768066 0.365234 6.71228 0.365234 14.0448C0.365234 19.0942 3.79173 22.8903 7.9394 24.5997C10.7473 25.9436 9.81625 26.8293 9.02337 27.5835C8.58349 28.0019 8.18614 28.3799 8.49342 28.7732C8.90543 29.3005 11.8266 28.6759 13.6944 28.2032ZM9.29278 14.7442C9.95625 14.7442 10.4941 14.2064 10.4941 13.5429C10.4941 12.8794 9.95625 12.3416 9.29278 12.3416C8.6293 12.3416 8.09144 12.8794 8.09144 13.5429C8.09144 14.2064 8.6293 14.7442 9.29278 14.7442ZM14.8431 13.5429C14.8431 14.2064 14.3053 14.7443 13.6418 14.7443C12.9783 14.7443 12.4405 14.2064 12.4405 13.5429C12.4405 12.8795 12.9783 12.3416 13.6418 12.3416C14.3053 12.3416 14.8431 12.8795 14.8431 13.5429ZM17.9909 14.7443C18.6544 14.7443 19.1923 14.2064 19.1923 13.5429C19.1923 12.8795 18.6544 12.3416 17.9909 12.3416C17.3274 12.3416 16.7896 12.8795 16.7896 13.5429C16.7896 14.2064 17.3274 14.7443 17.9909 14.7443Z"
      fill={props.color}
    />
  </Svg>
)
