import { skipToken, useQuery } from '@tanstack/react-query'
import { trpc } from 'app/utils/api'
import { chain } from 'app/utils/chain'
import { type Address } from 'viem'
import { usdcToken } from '../constants'

export const useIsTokenVerified = ({ tokenAddress }: { tokenAddress?: Address }) => {
  const trpcUtils = trpc.useUtils()

  return useQuery({
    queryKey: ['isTokenVerified', tokenAddress],
    queryFn: tokenAddress
      ? async () => {
          if (tokenAddress.toLowerCase() === '0x') {
            return { isScam: false, verified: true }
          }
          if (tokenAddress.toLowerCase() === usdcToken.address.toLowerCase()) {
            return { isScam: false, verified: true }
          }
          const tokenDetails = await trpcUtils.tokenContract.byAddress.fetch({
            address: tokenAddress,
            networkId: chain.id.toString() as '8453',
          })
          return { isScam: tokenDetails.isScam, verified: tokenDetails.verified }
        }
      : skipToken,
  })
}
