import Ionicons from '@expo/vector-icons/Ionicons'
import { useSelector } from '@legendapp/state/react'
import type { RouterOutputs } from '@my/api'
import { CreateTabIcon, InfiniteScrollList, Pressable, Text, View } from '@my/ui'
import { useScrollToTop } from '@react-navigation/native'
import { trpc } from 'app/utils/api'
import { formatShortNumber } from 'app/utils/common'
import { persister } from 'app/utils/mmkv/storage'
import { videoUploadState$ } from 'app/utils/upload/store/video-upload-state'
import { Image } from 'expo-image'
import { LinearGradient } from 'expo-linear-gradient'
import { Link } from 'expo-router'
import { useCallback, useMemo, useRef } from 'react'
import { ActivityIndicator, Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { useEventCallback } from '../../hooks/use-event-callback'
import { UploadVideoButton } from '../create/upload-video-button'
import { USER_VIDEO_PAGE_SIZE } from './constants'
import { useMe } from './hooks/use-me'

const postItemAspectRatio = 0.75

type VideoFeedItem = RouterOutputs['post']['byUser']['data'][0]

export const Posts = ({
  username,
  refreshing,
  onRefresh,
  HeaderComponent,
}: {
  username: string
  refreshing: boolean
  onRefresh: () => void
  HeaderComponent: () => React.ReactNode
}) => {
  const listRef = useRef(null)
  useScrollToTop(listRef)

  const windowWidth = useWindowDimensions().width
  const isMobile = windowWidth < 768
  const numOfColumns = isMobile ? 3 : 4
  const gap = 2
  const uploadProgress = useSelector(videoUploadState$.uploadProgress)
  const isUploading = useSelector(videoUploadState$.isUploading)
  const me = useMe()
  const isMyProfile = me.data?.username === username

  const { data, isFetchingNextPage, isPending, hasNextPage, fetchNextPage, isSuccess } =
    trpc.post.byUser.useInfiniteQuery(
      {
        username,
        pageSize: USER_VIDEO_PAGE_SIZE,
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        enabled: username.length > 0,
        // @ts-expect-error - TODO: fix persister type
        persister,
      }
    )

  const posts = useMemo(() => {
    return data?.pages.flatMap((cur) => cur.data) ?? []
  }, [data?.pages])

  const onEndReached = useCallback(async () => {
    if (!isFetchingNextPage && !isPending && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isPending])

  const itemDimensions = useMemo(() => {
    // percentage dimension not working well in native.
    // TODO: fix this use percentage dimension
    const nativeItemWidth = windowWidth / numOfColumns - gap / 2
    return Platform.select({
      web: {
        width: '100%',
        aspectRatio: postItemAspectRatio,
      },
      native: {
        width: nativeItemWidth,
        height: nativeItemWidth / postItemAspectRatio,
      },
    })
  }, [windowWidth, numOfColumns])

  const renderItem = useEventCallback(({ item }: { item: VideoFeedItem }) => {
    return (
      <View style={{ margin: gap }}>
        <Link
          href={`/swipe-list?type=profile&username=${item.user?.username}&postId=${item.post.id}`}
          className="flex web:hover:opacity-60 transition-opacity duration-200">
          <Image
            // @ts-expect-error - won't fix
            style={itemDimensions}
            source={
              item.media?.thumbnailUrl
                ? {
                    uri: item.media?.thumbnailUrl,
                  }
                : undefined
            }
            transition={100}
          />
        </Link>
        <View className="absolute bottom-0 left-0 w-full h-1/3" pointerEvents="none">
          <LinearGradient
            pointerEvents="none"
            style={StyleSheet.absoluteFill}
            start={[1, 0]}
            end={[1, 1]}
            locations={[0.05, 0.8]}
            colors={['rgba(12,12,12,0)', 'rgba(12,12,12,.6)']}
          />
          <View className="flex-row absolute bottom-2 left-1 items-center">
            <Ionicons color="white" size={22} name="play-outline" />
            <Text className="text-white font-bold">{formatShortNumber(item.viewCount ?? 0)}</Text>
          </View>
        </View>
      </View>
    )
  })

  const ListEmptyComponent = useCallback(() => {
    return isPending ? (
      <View className="items-center mt-8">
        <ActivityIndicator size="large" color="white" />
      </View>
    ) : isSuccess && posts?.length === 0 ? (
      <View className="items-center mt-16">
        {isMyProfile ? (
          <UploadVideoButton
            trigger={({ onPress }) => (
              <Pressable
                className="bg-primary rounded-full py-2.5 px-5 md:px-4 items-center flex-row gap-2 justify-center"
                onPress={onPress}>
                <CreateTabIcon variant="secondary" height={15} width={28} />
                <Text className="color-white font-semibold">
                  {isUploading ? `${uploadProgress}%` : 'Create your first video'}
                </Text>
              </Pressable>
            )}
          />
        ) : (
          <Text className="text-white">No posts yet</Text>
        )}
      </View>
    ) : null
  }, [isPending, isSuccess, posts?.length, isUploading, uploadProgress, isMyProfile])

  const ListFooterComponent = useCallback(() => {
    return (
      <>
        {isFetchingNextPage && hasNextPage ? (
          <View className="w-full items-center py-4">
            <ActivityIndicator color="white" />
          </View>
        ) : null}
        <View className="web:h-16 lg:hidden" />
      </>
    )
  }, [hasNextPage, isFetchingNextPage])

  return (
    <View className="mx-auto w-full max-w-2xl flex-grow">
      <InfiniteScrollList
        ref={listRef}
        data={posts}
        style={{
          margin: -gap,
        }}
        refreshing={refreshing}
        onRefresh={onRefresh}
        numColumns={numOfColumns}
        onEndReached={onEndReached}
        useWindowScroll
        preserveScrollPosition
        renderItem={renderItem}
        ListHeaderComponent={HeaderComponent}
        ListFooterComponent={ListFooterComponent}
        estimatedItemSize={itemDimensions?.height ?? 195}
        ListEmptyComponent={ListEmptyComponent}
      />
    </View>
  )
}
